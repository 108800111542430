import React, { FC } from 'react';
import style from './style.module.less';
import TickIcon from '../../assets/icons/tick.svg?react';

export interface CheckboxInputProps {
  checked?: boolean;
  label: string;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
  className?: string;
}

export const CheckboxInput: FC<CheckboxInputProps> = (props) => {
  const { label, onChange, checked, className, disabled } = props;

  const onClick = () => {
    if (!disabled) {
      onChange(!checked);
    }
  };

  return (
    <label
      className={`${style.checkboxInputLabel} ${
        disabled ? style.disabled : ''
      } ${className ?? ''}`}
      onClick={onClick}
    >
      {checked ? (
        <div className={style.checkboxChecked}>
          <TickIcon className={style.tickIcon} />
        </div>
      ) : (
        <div className={style.checkbox} />
      )}
      {label}
    </label>
  );
};
