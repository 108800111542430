import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import style from './anchor.module.less';
import { useTranslation } from 'react-i18next';
import { MENU_HEADER_ID } from '../../../components/header';
import { useScreenSize, WidthType } from '../../../hooks/use-is-mobile';

export const overviewAnchor = 'overview';
export const answersAnchor = 'answers';
export const conclusionAnchor = 'conclusion';
export const nextStepsAnchor = 'nextSteps';

const MENU_GAP = 0;

interface Props {
  showingNextSteps: boolean;
}

export const AnchorComponent: FC<Props> = (props) => {
  const { showingNextSteps } = props;
  const { t } = useTranslation();
  const floatingAnchorRef = useRef<HTMLDivElement>();
  const scrollingAnchorRef = useRef<HTMLDivElement>();
  const [showSticky, setShowSticky] = React.useState(true);
  const [activeAnchor, setActiveAnchor] = React.useState(overviewAnchor);
  const screenType = useScreenSize();

  const stopScrollingTimer = useRef<number | null>(null);

  const handleScrollPosition = useCallback(() => {
    const menuRef = document.getElementById(MENU_HEADER_ID);
    const { top } = scrollingAnchorRef?.current?.getBoundingClientRect() ?? {};
    if (screenType === WidthType.Mobile) {
      floatingAnchorRef.current.style.top = `${MENU_GAP}px`;
      if (top < MENU_GAP) {
        setShowSticky(true);
      } else {
        setShowSticky(false);
      }
    } else {
      floatingAnchorRef.current.style.top = `${
        menuRef.getBoundingClientRect().height + MENU_GAP
      }px`;
      if (top < menuRef?.getBoundingClientRect().height + MENU_GAP) {
        setShowSticky(true);
      } else {
        setShowSticky(false);
      }
    }
  }, [screenType]);

  const handleActiveAnchor = useCallback(() => {
    const menuRef = document.getElementById(MENU_HEADER_ID);
    const overviewRef = document.getElementById(overviewAnchor);
    const answersRef = document.getElementById(answersAnchor);
    const conclusionRef = document.getElementById(conclusionAnchor);
    const nextStepsRef = showingNextSteps
      ? document.getElementById(nextStepsAnchor)
      : null;

    // it is possible for nextStep to be null
    if (!menuRef || !overviewRef || !answersRef || !conclusionRef) {
      return;
    }

    const { top: overviewTop } = overviewRef.getBoundingClientRect();
    const { top: answersTop } = answersRef.getBoundingClientRect();
    const { top: conclusionTop } = conclusionRef.getBoundingClientRect();
    const { top: nextStepsTop } = nextStepsRef?.getBoundingClientRect() ?? {};

    const menuHeight = menuRef.getBoundingClientRect().height + MENU_GAP;
    const OFFSET = 240; // we have an offset of 240px of if in the next section

    if (overviewTop < menuHeight && answersTop - OFFSET > menuHeight) {
      setActiveAnchor(overviewAnchor);
    } else if (
      answersTop - OFFSET <= menuHeight &&
      conclusionTop - OFFSET > menuHeight
    ) {
      setActiveAnchor(answersAnchor);
    } else if (
      conclusionTop - OFFSET <= menuHeight &&
      (!showingNextSteps || nextStepsTop - OFFSET > menuHeight)
    ) {
      setActiveAnchor(conclusionAnchor);
    } else if (showingNextSteps && nextStepsTop - OFFSET <= menuHeight) {
      setActiveAnchor(nextStepsAnchor);
    }
  }, [showingNextSteps]);

  const onScrollHandler = useCallback(() => {
    if (scrollingAnchorRef.current) {
      const menuRef = document.getElementById(MENU_HEADER_ID);
      if (menuRef) {
        stopScrollingTimer.current && clearTimeout(stopScrollingTimer.current);
        stopScrollingTimer.current = window.setTimeout(() => {
          handleScrollPosition();
          handleActiveAnchor();
        }, 10);
      }
    }
  }, [handleScrollPosition, handleActiveAnchor]);

  // we want to check the position of the element and if we have scrolled past it we want to make the element sticky
  useEffect(() => {
    onScrollHandler();
    // we want to wait till document for next-steps is loaded
    window.addEventListener('scroll', onScrollHandler);
    return () => {
      window.removeEventListener('scroll', onScrollHandler);
    };
  }, [onScrollHandler]);

  // on resize we want to check if the screen is smaller than the menu width and if so we want to set the justify content to unset
  useEffect(() => {
    const handleResize = () => {
      if (
        scrollingAnchorRef.current.scrollWidth >
        scrollingAnchorRef.current.clientWidth
      ) {
        scrollingAnchorRef.current.classList.add(style.noJustify);
        floatingAnchorRef.current.classList.add(style.noJustify);
      } else {
        scrollingAnchorRef.current.classList.remove(style.noJustify);
        floatingAnchorRef.current.classList.remove(style.noJustify);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const scrollToAnchor = useCallback((anchor: string) => {
    const element = document.getElementById(anchor);
    if (element) {
      window.scrollBy({
        top: element.getBoundingClientRect().top - 100,
        behavior: 'smooth',
      });
    }
  }, []);

  const Body = (
    <>
      <button
        className={`${style.anchorButton} ${
          activeAnchor === overviewAnchor ? style.dark : ''
        }`}
        onClick={() => scrollToAnchor(overviewAnchor)}
      >
        {t('prematter.overview')}
      </button>
      <button
        className={`${style.anchorButton} ${
          activeAnchor === answersAnchor ? style.dark : ''
        }`}
        onClick={() => scrollToAnchor(answersAnchor)}
      >
        {t('prematter.answers')}
      </button>
      <button
        className={`${style.anchorButton} ${
          activeAnchor === conclusionAnchor ? style.dark : ''
        }`}
        onClick={() => scrollToAnchor(conclusionAnchor)}
      >
        {t('prematter.conclusion')}
      </button>
      {showingNextSteps ? (
        <button
          className={`${style.anchorButton} ${
            activeAnchor === nextStepsAnchor ? style.dark : ''
          }`}
          onClick={() => scrollToAnchor(nextStepsAnchor)}
        >
          {t('prematter.next-steps')}
        </button>
      ) : null}
    </>
  );

  return (
    <>
      <div
        className={`${style.anchorContainer} ${style.sticky} ${
          showSticky ? style.showSticky : ''
        }`}
        ref={floatingAnchorRef}
      >
        {Body}
      </div>
      <div
        ref={scrollingAnchorRef}
        className={`${style.anchorContainer} ${
          showSticky ? style.hideNonSticky : ''
        }`}
      >
        {Body}
      </div>
    </>
  );
};
