import React, { FC, useEffect, useState, useRef } from 'react';
import Markdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { LawyerConnection, Lawyer } from '@law-connect/types';
import style from './modal.module.less';
import placeholderImage from '../../../assets/profile-no-img.png';
import CrossCircleIcon from '../../../assets/icons/cross-circle.svg?react';
import UserIcon from '../../../assets/icons/user.svg?react';
import BriefcaseIcon from '../../../assets/icons/briefcase.svg';
import BriefcaseSvgIcon from '../../../assets/icons/briefcase.svg?react';
import ArrowRightIcon from '../../../assets/icons/arrow-right.svg?react';
import { id } from 'date-fns/locale';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  connection: LawyerConnection;
  lawyers?: Lawyer[];
}

export const BioIntroModal: FC<Props> = ({
  isOpen,
  closeModal,
  connection,
  lawyers,
}) => {
  const [selectedBio, setSelectedBio] = useState(connection.lawyer.firm.id);
  const lawyerContentRef = useRef([]);
  const firmContentRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState(0);

  useEffect(() => {
    // stop scrolling when modal is open
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  // Calculate the maximum height on initial render and when contents change
  useEffect(() => {
    if(!isOpen) {
      return;
    }

    const heights = lawyerContentRef.current.map(
      (ref) => ref?.offsetHeight || 0);
    if (firmContentRef.current) {
      heights.push(firmContentRef.current.offsetHeight || 0);
    }
    setMaxHeight(Math.max(...heights));
  }, [lawyers, isOpen]);

  return (
    <>
      {isOpen && (
        <div className={style.fullScreenModal}>
          <div className={style.wrapper}>
            <div className={style.closeIconContainer} onClick={closeModal}>
              <CrossCircleIcon className={style.closeIcon} />
            </div>
            <div className={style.bodyContainer}>
              <div className={style.bioContainer}>
                <div className={`${style.bioButton} 
                    ${selectedBio === connection.lawyer.firm.id ? 
          style.active : ''}`}
                onClick={() => {
                  setSelectedBio(connection.lawyer.firm.id);
                }}
                >
                  {connection.lawyer.firm.image ?
                    (
                      <img
                        src={connection.lawyer.firm.image}
                        alt='firm avatar'
                        className={style.bioButtonAvatar}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = BriefcaseIcon;
                          currentTarget.className= style.bioFirmPlaceholder;
                        }}
                      />
                    ) : 
                    <div className={style.bioFirmPlaceholderWrapper}>
                      <BriefcaseSvgIcon 
                        className={style.bioFirmPlaceholder} />
                    </div>
                  }
                  <span className={style.bioButtonLabel}>
                    {connection.lawyer.firm.name}
                  </span>
                  <div className={style.iconContainer}>
                    <div className={style.bioIconContainerHover}>
                      <ArrowRightIcon className={style.bioIcon} />
                    </div>
                    <div className={style.bioIconContainer}>
                      <ArrowRightIcon className={style.bioIcon} />
                    </div>
                  </div>
                </div>
                {lawyers?.map((lawyer) => (
                  <div className={`${style.bioButton} 
                    ${selectedBio === lawyer.id ? 
                    style.active : ''}`}
                  onClick={() => {
                    setSelectedBio(lawyer.id);
                  }}
                  key={lawyer.id}
                  >
                    <img
                      src={lawyer.image}
                      alt='lawyer avatar'
                      className={style.bioButtonAvatar}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = placeholderImage;
                      }}
                    />
                    <span className={style.bioButtonLabel}>
                      {lawyer.firstName}
                      {lawyer.firstName && lawyer.lastName ? ' ' : ''}
                      {lawyer.lastName}
                    </span>
                    <div className={style.iconContainer}>
                      <div className={style.bioIconContainerHover}>
                        <ArrowRightIcon className={style.bioIcon} />
                      </div>
                      <div className={style.bioIconContainer}>
                        <ArrowRightIcon className={style.bioIcon} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className={style.bioInfoCard}
              >
                <div className={style.firmInfoCardContainer}
                  ref={firmContentRef}
                  style={{
                    display: connection.lawyer.firm.id === selectedBio ? 
                      'block' : 'none', //show only if active
                    height: `${maxHeight}px`,
                  }}
                >
                  <div className={style.bioImageWrapper}>
                    {
                      connection.lawyer.firm.image ?
                        (
                          <img
                            src={connection.lawyer.firm.image}
                            alt='firm avatar'
                            className={style.bioImage}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = BriefcaseIcon;
                              currentTarget.className= style.bioFirmPlaceholder;
                            }}
                          />
                        ) : 
                        <div className={style.bioFirmPlaceholderWrapper}>
                          <BriefcaseSvgIcon 
                            className={style.bioFirmPlaceholder} />
                        </div>
                    }
                  </div>
                  <div className={style.bioDetails}>
                    <div className={style.bioHeaderName}>
                      {connection.lawyer.firm.name}
                    </div>
                    {connection.lawyer.firm.website ? (
                      <a
                        href={connection.lawyer.firm.website}
                        className={style.bioWebsite}
                      >
                        {connection.lawyer.firm.website}
                      </a>
                    ) : null}
                    {connection.lawyer.firm.phoneNumber ? (
                      <div className={style.phoneContainer}>
                        <span>Phone:&nbsp;</span>
                        <a
                          href={`tel:${connection.lawyer.firm.phoneNumber}`}
                          className={style.phoneNumber}
                        >
                          {connection.lawyer.firm.phoneNumber}
                        </a>
                      </div>
                    ) : null}
                  </div>
                  <div className={style.markdownWrapper}>
                    <Markdown className={`${style.message}`}>
                      {connection.lawyer.firm.description}
                    </Markdown>
                  </div>
                </div>
                {/* Render only the active lawyer bio */}
                {lawyers.map((lawyer, index) => (
                  <div
                    key={index}
                    style={{
                      display: lawyer.id === selectedBio ? 
                        'block' : 'none', // Show only active content
                      height: `${maxHeight}px`,
                    }}
                  >
                    <div className={style.bioImageWrapper}>
                      <img
                        src={lawyer?.image}
                        alt='lawyer avatar'
                        className={style.bioImage}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = placeholderImage;
                        }}
                      />
                    </div>
                    <div className={style.bioDetails}>
                      <div className={style.bioHeaderName}>
                        {lawyer.firstName}
                        {lawyer.firstName && 
                        lawyer.lastName
                          ? ' '
                          : ''}
                        {lawyer.lastName}
                      </div>
                      {lawyer.email ? (
                        <a
                          href={`mailto:${lawyer.email}`}
                          className={style.lawyerEmail}
                        >
                          {lawyer.email}
                        </a>
                      ) : null}
                      {lawyer.phoneNumber ? (
                        <div className={style.phoneContainer}>
                          <span>Phone:&nbsp;</span>
                          <a
                            href={`tel:${lawyer.phoneNumber}`}
                            className={style.lawyerEmail}
                          >
                            {lawyer.phoneNumber}
                          </a>
                        </div>
                      ) : null}
                    </div>
                    <div className={style.markdownWrapper}>
                      <Markdown className={`${style.message}`}>
                        {lawyer.presentation}
                      </Markdown>
                    </div>
                  </div>
                ))}
                
                {/* Hidden content for max height measurement */}
                {lawyers.map((lawyer, index) => (
                  <div
                    key={index}
                    ref={(el) => (lawyerContentRef.current[index] = el)} // Attach ref to each content block
                    style={{
                      position: 'absolute',
                      visibility: 'hidden',
                      top: '-9999px',
                    }}
                  >
                    <div className={style.bioImageWrapper}>
                      <img
                        src={lawyer?.image}
                        alt='lawyer avatar'
                        className={style.bioImage}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = placeholderImage;
                        }}
                      />
                    </div>
                    <div className={style.bioDetails}>
                      <div className={style.bioHeaderName}>
                        {lawyer.firstName}
                        {lawyer.firstName && 
                        lawyer.lastName
                          ? ' '
                          : ''}
                        {lawyer.lastName}
                      </div>
                      {lawyer.email ? (
                        <a
                          href={`mailto:${lawyer.email}`}
                          className={style.lawyerEmail}
                        >
                          {lawyer.email}
                        </a>
                      ) : null}
                      {lawyer.phoneNumber ? (
                        <div className={style.phoneContainer}>
                          <span>Phone:&nbsp;</span>
                          <a
                            href={`tel:${lawyer.phoneNumber}`}
                            className={style.lawyerEmail}
                          >
                            {lawyer.phoneNumber}
                          </a>
                        </div>
                      ) : null}
                    </div>
                    <div className={style.markdownWrapper}>
                      <Markdown className={`${style.message}`}>
                        {lawyer.presentation}
                      </Markdown>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
