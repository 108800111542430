import React, { ChangeEvent, forwardRef } from 'react';
import style from './style.module.less';

interface Props {
  onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  multiple?: boolean;
  disabled?: boolean;
}

export const FileInputWrapper = forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const { onChangeHandler, multiple, disabled } = props;

    return (
      <input
        ref={ref}
        type='file'
        multiple={multiple ?? true}
        className={style.input}
        onChange={onChangeHandler}
        // eslint-disable-next-line @stylistic/js/max-len
        accept='image/jpeg, image/png, image/gif, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, application/rtf, application/epub+zip, application/vnd.oasis.opendocument.text, application/zip, message/rfc822'
        disabled={disabled}
      />
    );
  }
);
