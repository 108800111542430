import React, { ReactNode } from 'react';
import style from './style.module.less';

interface Props {
  children: ReactNode;
}

export const Table: React.FC<Props> = (props) => {
  const { children } = props;
  return (
    <div className={style.table}>
      {children}
    </div>
  );
};
