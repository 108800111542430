import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import style from './style.module.less';
import RightArrowIcon from '../../../assets/icons/arrow-right.svg?react';
import theAustralianLogoOff from './media-aus-off.jpg';
import theAustralianLogoOn from './media-aus-on.jpg';
import smartCompanyLogoOff from './media-smart-off.jpg';
import smartCompanyLogoOn from './media-smart-on.jpg';
import mediaweekLogoOff from './media-mediaweek-off.jpg';
import mediaweekLogoOn from './media-mediaweek-on.jpg';
import { Carousel, CarouselRef } from '@law-connect/react-components';
import {
  isTouchDevice,
  useScreenSize,
  WidthType,
} from '../../../hooks/use-is-mobile';
import { useTranslation } from 'react-i18next';

const MEDIA_DATA = [
  {
    link: 'https://www.theaustralian.com.au/business/technology/christian-becks-lawconnect-to-provide-free-legal-advice-to-australians/news-story/1e1f8b0f4ed59557ea2352656d8c9a81',
    name: 'The Australian',
    imageOnHover: theAustralianLogoOn,
    image: theAustralianLogoOff,
  },
  {
    link: 'https://www.smartcompany.com.au/technology/artificial-intelligence/lawconnect-ai-revolutionise-how-aussies-access-legal-help/',
    name: 'Smart Company',
    imageOnHover: smartCompanyLogoOn,
    image: smartCompanyLogoOff,
  },
  {
    link: 'https://www.mediaweek.com.au/thrive-house-onboard-lawconnect-superyacht-announced-at-sxsw-sydney/',
    name: 'Mediaweek',
    imageOnHover: mediaweekLogoOn,
    image: mediaweekLogoOff,
  },
];

const MOBILE_FIXED_WIDTH = 295;
const GAP = 40;

export const MediaComponent: FC = () => {
  const cardsScrollRef = useRef<CarouselRef>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const screenType = useScreenSize();
  const { t } = useTranslation();
  const isTabletOrMobile = isTouchDevice();

  const [cardWidth, setCardWidth] = React.useState(0);
  const [isInDiv, setIsInDiv] = useState(false);
  const onMouseEnter = useCallback(() => setIsInDiv(true), []);
  const onMouseLeave = useCallback(() => setIsInDiv(false), []);

  const updateCardWidth = useCallback(() => {
    if (screenType === WidthType.Tablet) {
      // expect 2 cards
      setCardWidth((containerRef.current.clientWidth - GAP) / 2);
    } else if (screenType === WidthType.Mobile) {
      // expect 1 card
      setCardWidth(MOBILE_FIXED_WIDTH);
    } else {
      // expect 3 cards
      setCardWidth((containerRef.current.clientWidth - GAP * 2) / 3);
    }
  }, [screenType]);

  useEffect(() => {
    updateCardWidth();
    window.addEventListener('resize', updateCardWidth);
    return () => window.removeEventListener('resize', updateCardWidth);
  }, [updateCardWidth]);

  // we need to manually update the cardWidth as it is grid based

  const cardBody = MEDIA_DATA.map((media) => (
    <a
      href={media.link}
      className={style.mediaCard}
      key={media.name}
      target='_blank'
      rel='noreferrer'
    >
      <div className={style.mediaImageWrapper}>
        <img
          src={media.imageOnHover}
          alt={media.name}
          className={style.mediaImageOnHover}
        />
        <img src={media.image} alt={media.name} className={style.mediaImage} />
      </div>
      <div className={style.viewArticleContainer}>
        <div className={style.viewArticle}>{t('home.view-article')}</div>
        <div className={style.arrowContainer}>
          <RightArrowIcon className={style.arrow} />
        </div>
      </div>
    </a>
  ));

  return (
    <div className={style.mediaContainer}>
      <div className={style.mediaHeader}>
        <div className={style.mediaTitle}>{t('home.media')}</div>
        <div className={style.mediaSubTitle}>{t('home.media-description')}</div>
      </div>
      <div
        className={style.mediaContent}
        ref={containerRef}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Carousel
          ref={cardsScrollRef}
          cardWidth={cardWidth}
          showProgressBar={
            screenType === WidthType.Tablet || screenType === WidthType.Mobile
          }
          items={cardBody}
          offsetWidth={GAP}
          classNames={{
            container: style.carouselContainer,
            cardsContainer: style.cardsContainer,
            actionBar: `${style.actionBar} ${
              isTabletOrMobile ? '' : style.noTouch
            } ${isInDiv ? '' : style.outsideDiv}`,
            arrowButtonContainer: `${style.arrowButtonContainer} ${
              isTabletOrMobile ? style.noControl : ''
            }`,
          }}
        />
      </div>
    </div>
  );
};
