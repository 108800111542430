import {
  ListenerEffectAPI,
  PayloadAction,
  createListenerMiddleware,
} from '@reduxjs/toolkit';
import { actions } from '../slices';
import api from '../api';
import { AppDispatch, RootState } from '../store';
import { MessageType } from '@law-connect/types';
const FAILURE_RETRY = 5;
const DELAY_COUNT = 2000;

// long poll to server to fetch updated messages based off connectionId
let activeId: string | null = null;
const lawyerMessageMiddleware = createListenerMiddleware();

lawyerMessageMiddleware.startListening({
  actionCreator: actions.lawyerConnection.pollMessages,
  effect: async (
    action: PayloadAction<{
      connectionId: string;
      init?: boolean;
      errorCount?: number;
    }>,
    listenerApi: ListenerEffectAPI<RootState, AppDispatch>
  ) => {
    const { connectionId, init } = action.payload;
    // if it is the first call we want to set the activeId
    if (init) {
      activeId = connectionId;
    }
    if (connectionId !== activeId) {
      console.error(
        `Connection id does not match active id ${connectionId} !== ${activeId}`
      );
      // we have either moved onto a new connection or the connection has been closed
      return;
    }
    // we want to continuously call server to get new messages for the connection that is set up
    try {
      const newMessage = await api.lawyerConnection.longPoll({ connectionId });

      if (newMessage) {
        if (newMessage.type === MessageType.Refresh) {
          // Reload the connection and files
          listenerApi.dispatch(
            actions.lawyerConnection.fetchById({ id: connectionId })
          );
          listenerApi.dispatch(
            actions.lawyerConnection.fetchFiles({ id: connectionId })
          );
        } else {
          listenerApi.dispatch(
            actions.lawyerConnection.addMessage({
              connectionId,
              message: newMessage,
            })
          );
          // if the new message is type closed we want to fetch the new connection because the connection is closed
          if (newMessage.type === MessageType.Closed) {
            listenerApi.dispatch(
              actions.lawyerConnection.fetchById({ id: connectionId })
            );
            return;
          }
          // when we poll messages, we also want to update the read at if message comes from lawyer
          if (newMessage.from === 'lawyer' && !newMessage.readAt) {
            listenerApi.dispatch(
              actions.lawyerConnection.updateReadAt({
                id: connectionId,
                messageId: newMessage.id,
              })
            );
          }
          // if new message has file ids, we want to fetch the new file ids
          if (newMessage.fileIds && newMessage.fileIds.length > 0) {
            listenerApi.dispatch(
              actions.lawyerConnection.fetchFiles({ id: connectionId })
            );
          }
        }
      }

      // we want to cycle through the messages as long as the connection is active
      if (activeId === connectionId) {
        listenerApi.dispatch(
          actions.lawyerConnection.pollMessages({ connectionId })
        );
      } else {
        console.error(
          `Connection id does not match active id ${
            connectionId
          } !== ${activeId}`
        );
      }
    } catch (error) {
      // if for some reason the connection is closed we want to fetch all the messages and retry polling up
      await new Promise((r) => setTimeout(r, 5000));
      await listenerApi.dispatch(
        actions.lawyerConnection.fetchMessages({ id: connectionId })
      );
      listenerApi.dispatch(
        actions.lawyerConnection.pollMessages({
          connectionId,
          errorCount: action.payload.errorCount
            ? action.payload.errorCount + 1
            : 1,
        })
      );
    }
  },
});

lawyerMessageMiddleware.startListening({
  actionCreator: actions.lawyerConnection.stopPollMessages,
  effect: async (
    action: PayloadAction<{
      connectionId: string;
    }>,
    listenerApi: ListenerEffectAPI<RootState, AppDispatch>
  ) => {
    const { connectionId } = action.payload;
    // if it is the first call we want to set the activeId
    if (connectionId === activeId) {
      activeId = null;
    }
  },
});

export default lawyerMessageMiddleware;
