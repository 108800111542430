import MaleLawyer1 from './man-1.png';
import MaleLawyer2 from './man-2.png';
import MaleLawyer3 from './man-3.png';
import FemaleLawyer1 from './woman-1.png';
import FemaleLawyer2 from './woman-2.png';
import FemaleLawyer3 from './woman-3.png';
import { AiLawyer } from '@law-connect/types';

export interface AiLawyerImage {
    image: string;
    aiLawyer: AiLawyer;
}

export const aiLawyerImages: AiLawyerImage[] = [
  { image: '/lawyer/bruce-round.png', aiLawyer: AiLawyer.Bruce },
  { image: '/lawyer/maxwell-round.png', aiLawyer: AiLawyer.Maxwell },
  { image: '/lawyer/watson-round.png', aiLawyer: AiLawyer.Watson },
  { image: '/lawyer/amy-round.png', aiLawyer: AiLawyer.Amy },
  { image: '/lawyer/claire-round.png', aiLawyer: AiLawyer.Claire },
  { image: '/lawyer/emma-round.png', aiLawyer: AiLawyer.Emma },
];
