import React, { FC } from 'react';
import style from './style.module.less';
import HeroIcon from '../assets/images/hero-icon.svg?react';
import { Link } from 'react-router-dom';

interface Props {
  breadcrumbs: { name: string; url?: string }[];
  description: string;
}

export const LibraryHero: FC<Props> = ({ breadcrumbs, description }) => {
  return (
    <div className={style.heroCard}>
      <div className={style.heroBG} />
      <div className={style.titleWrapper}>
        <div className={style.breadcrumb}>
          {breadcrumbs.map((b, index) => {
            return (
              <span key={index}>
                {b.url ? (
                  <Link to={b.url} className={style.url}>
                    {b.name}
                  </Link>
                ) : (
                  b.name
                )}
                {index + 1 < breadcrumbs.length && ' / '}
              </span>
            );
          })}
        </div>
        <div className={style.title}>{description}</div>
      </div>
      <div className={style.heroImageWrapper}>
        <HeroIcon className={style.heroImage} />
      </div>
    </div>
  );
};
