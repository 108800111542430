import React, { MouseEvent, useCallback } from 'react';
import style from './style.module.less';
import MoreVerticalIcon from '../../assets/icons/more-vertical.svg?react';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';

export interface ContextItem {
  label: string;
  icon?: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
}

interface Props {
  items: ContextItem[];
  className?: string;
}

export const ContextMenu: React.FC<Props> = (props) => {
  const { items, className } = props;

  const [open, setOpen] = React.useState(false);
  const ref = React.useRef(null);

  useOnClickOutside(ref, () => setOpen(false));

  const onClickMenu = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen((o) => !o);
  }, []);

  return (
    <div
      className={`${style.contextMenuWrapper} ${className ?? ''} ${
        open ? style.open : ''
      }`}
      onClick={onClickMenu}
      ref={ref}
    >
      <MoreVerticalIcon className={style.menuIcon} />
      {open && (
        <div className={style.contextMenu}>
          {items.map((item, index) => (
            <div
              key={index}
              // eslint-disable-next-line @stylistic/js/max-len
              className={`${style.contextMenuItem} ${item.disabled ? style.disabled : ''}`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpen(false);
                item.onClick();
              }}
            >
              <div className={style.label}>{item.label}</div>
              {item.icon && <div className={style.icon}>{item.icon}</div>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
