import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { actions } from '../../redux/slices';
import selectors from '../../redux/selectors';
import { Header } from '../../components/header';
import { AccountHeader } from '../../components/account-header';
import { Footer } from '../../components/footer';
// import { LawyerImages } from '../../assets/lawyers';
import { Button } from '../../components/button';
import style from './style.module.less';
import ArrowLeftIcon from '../../assets/icons/arrow-left.svg?react';
import SignOutIcon from '../../assets/icons/sign-out.svg?react';
import BinIcon from '../../assets/icons/bin.svg?react';
import RandomIcon from '../../assets/icons/random-circle.svg?react';
import DeleteAccountModal, {
  DeleteAccountModalRef,
} from '../../components/delete-account';
import { cleanKindePicture } from '../../utils/kinde';
import { useCookieHandler } from '../../utils/cookie';
import { SHOW_DISCLAIMER_COOKIE } from '../../constants';
import { toast } from 'react-toastify';

import PhoneInput, {
  isPossiblePhoneNumber,
  Country,
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { aiLawyerImages } from '../../assets/lawyers/ai-lawyer-images';
import { OnOffInput } from '@law-connect/react-components';
import { AiLawyer } from '@law-connect/types';
import { ContentWrapper } from '../../components/content-wrapper';

export const EditAccount: React.FC = () => {
  const { logout } = useKindeAuth();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const deleteAccountRef = createRef<DeleteAccountModalRef>();
  const user = useAppSelector(selectors.user.getUser());
  const isUpdatingUser = useAppSelector(selectors.user.isUpdating());
  const [firstName, setFirstName] = useState(user?.firstName ?? '');
  const [lastName, setLastName] = useState(user?.lastName ?? '');
  const [phone, setPhone] = useState(user?.phone ?? '');
  const cookieHandler = useCookieHandler();
  const { t } = useTranslation();
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
  const [isFunctionalEmails, setIsFunctionalEmails] = useState(
    user?.functionalEmails ?? true
  );
  const [isMarketingEmails, setIsMarketingEmails] = useState(
    user?.marketingEmails ?? true
  );
  const [aiLawyer, setAiLawyer] = useState<AiLawyer | null>(null);

  // get first name and last name from user

  const onBack = useCallback(() => {
    navigate('/account', { replace: true });
  }, [navigate]);

  const signOut = useCallback(() => {
    cookieHandler.deleteCookie(SHOW_DISCLAIMER_COOKIE);
    logout();
    dispatch(actions.session.delete());
  }, [logout, dispatch]);

  const onDeleteClick = useCallback(() => {
    deleteAccountRef.current?.openModal();
  }, [deleteAccountRef]);

  const resetPassword = useCallback(() => {
    // TODO: implement password reset
  }, []);
  const updateDisabled = useMemo(
    () =>
      isUpdatingUser ||
      ((!firstName || firstName === user?.firstName) &&
        (!lastName || lastName === user?.lastName) &&
        phone === user?.phone),
    [
      firstName,
      isUpdatingUser,
      lastName,
      user?.firstName,
      user?.lastName,
      phone,
      user?.phone,
    ]
  );
  const save = useCallback(() => {
    // check if phone number is valid. If not, show error toast message and highlight the phone input field
    if (!isPossiblePhoneNumber(phone)) {
      setInvalidPhoneNumber(true);
      toast.error(t('toast.invalid-phone-number'));
      return;
    }

    // TODO: implement update user details
    if (!updateDisabled) {
      dispatch(actions.user.update({ firstName, lastName, phone }));
    }
  }, [dispatch, firstName, updateDisabled, lastName, phone]);

  useEffect(() => {
    if (user?.firstName) {
      setFirstName(user.firstName);
    }
    if (user?.lastName) {
      setLastName(user.lastName);
    }
    if (user?.phone) {
      setPhone(user.phone);
    }
    if (user?.aiLawyer) {
      setAiLawyer(user.aiLawyer);
    }
    if (user?.functionalEmails) {
      setIsFunctionalEmails(user.functionalEmails);
    }
    if (user?.marketingEmails) {
      setIsMarketingEmails(user.marketingEmails);
    }
  }, [user]);

  const setPhoneWrapper = useCallback(
    (value: string) => {
      setPhone(value ?? '');
      setInvalidPhoneNumber(false);
    },
    [setPhone]
  );

  const onFunctionalEmailsChangeHandler = (value: boolean) => {
    setIsFunctionalEmails(value);
    dispatch(actions.user.update({ functionalEmails: value }));
  };

  const onMarketingEmailsChangeHandler = (value: boolean) => {
    setIsMarketingEmails(value);
    dispatch(actions.user.update({ marketingEmails: value }));
  };

  const handleAvatarClick = (aiLawyer: AiLawyer) => {
    setAiLawyer(aiLawyer);
    dispatch(actions.user.update({ aiLawyer }));
  };

  const handleRandomAvatarClick = () => {
    setAiLawyer(null);
    dispatch(actions.user.update({ aiLawyer: null }));
  };

  return (
    <>
      <Header />
      <AccountHeader
        profilePicture={cleanKindePicture(user?.picture)}
        account={firstName}
        headerClassName={style.accountHeader}
      >
        <div className={style.headerContainer}>
          <div onClick={onBack} className={style.backContainer}>
            <ArrowLeftIcon className={style.backIcon} />
          </div>
        </div>
      </AccountHeader>
      <ContentWrapper
        className={style.container}
        contentClassName={style.content}
      >
        <div className={style.innerContainer}>
          <div className={style.section}>
            <div className={style.innerSection}>
              <div className={style.title}>
                {t('account.edit-profile.details')}
              </div>
              <div className={style.inputGrid}>
                <label className={style.inputContainer}>
                  <div className={style.inputLabel}>
                    {t('account.edit-profile.first-name')}
                  </div>
                  <input
                    type='text'
                    className={style.input}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    disabled={isUpdatingUser}
                  />
                </label>
                <label className={style.inputContainer}>
                  <div className={style.inputLabel}>
                    {t('account.edit-profile.last-name')}
                  </div>
                  <input
                    type='text'
                    className={style.input}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    disabled={isUpdatingUser}
                  />
                </label>
                <label className={style.inputContainer}>
                  <div className={style.inputLabel}>
                    {t('account.edit-profile.email')}
                  </div>
                  <input
                    type='text'
                    className={style.input}
                    readOnly
                    value={user?.email}
                  />
                </label>

                <label className={style.inputContainer}>
                  <div className={style.inputLabel}>
                    {t('account.edit-profile.phone')}
                  </div>
                  <div
                    className={`${style.phoneInputContainer}  
                    ${invalidPhoneNumber ? style.error : ''}`}
                  >
                    <PhoneInput
                      onChange={setPhoneWrapper}
                      value={phone}
                      defaultCountry={(user.locale ?? 'AU') as Country}
                    />
                  </div>
                </label>

                {/* <div className={style.inputContainer}>
                  <div className={style.label}>Phone</div>
                  <div className={style.phoneInputContainer}>
                    <PhoneInput
                      onChange={setPhoneWrapper}
                      value={phone}
                      defaultCountry='AU'
                    />
                  </div>
                </div> */}
              </div>

              <div className={style.actionButtonContainer}>
                <button
                  className={style.resetButton}
                  onClick={save}
                  disabled={updateDisabled}
                >
                  {t('confirm.save')}
                </button>
              </div>
            </div>
            {/* <div className={style.innerSection}>
              <div className={style.title}>Password reset</div>
              <div className={style.passwordText}>
                A secure password reset process is available. Select the button
                below and an email will be sent to you. Follow the steps in the
                email to safely reset your password.
              </div>
              <div className={style.actionButtonContainer}>
                <button className={style.resetButton} onClick={resetPassword}>
                  {t('reset-password')}
                </button>
              </div>
            </div> */}
          </div>
          <div className={style.section}>
            <div className={style.title}>
              {t('account.edit-profile.avatar.title')}
            </div>
            <div className={style.avatarContainer}>
              <div className={style.avatarDescription}>
                {t('account.edit-profile.avatar.description')}
              </div>
              <div className={style.avatarPickerWrapper}>
                {aiLawyerImages.map((lawyer) => (
                  <div
                    key={lawyer.aiLawyer}
                    className={`${style.avatarPickerItem}
                      ${aiLawyer === lawyer.aiLawyer ? style['selected'] : ''}`}
                    onClick={() => handleAvatarClick(lawyer.aiLawyer)}
                  >
                    <img
                      className={`${style.avatarPickerImage}`}
                      src={lawyer.image}
                    />
                  </div>
                ))}
                <div
                  className={`${style.avatarPickerItem} 
                    ${!aiLawyer ? style['selected'] : ''}`}
                  onClick={() => handleRandomAvatarClick()}
                >
                  <RandomIcon />
                </div>
              </div>
            </div>
          </div>
          <div className={style.section}>
            <div className={style.title}>
              {t('account.edit-profile.email-preferences.title')}
            </div>
            <div className={style.emailPreferencesContainer}>
              <div className={style.emailPreferencesDescription}>
                {t('account.edit-profile.email-preferences.description')}
              </div>
              <div className={style.emailPreferencesWrapper}>
                <OnOffInput
                  className={style.emailPreferencesInput}
                  title={t('account.edit-profile.email-preferences.functional')}
                  value={isFunctionalEmails}
                  onChange={onFunctionalEmailsChangeHandler}
                  disabled={false}
                />
                <OnOffInput
                  className={style.emailPreferencesInput}
                  title={t('account.edit-profile.email-preferences.marketing')}
                  value={isMarketingEmails}
                  onChange={onMarketingEmailsChangeHandler}
                  disabled={false}
                />
              </div>
            </div>
          </div>
          <div className={style.section}>
            <div className={style.title}>
              {t('account.edit-profile.delete-account')}
            </div>
            <div className={style.deleteContainer}>
              <div>
                {t('account.edit-profile.delete-account-description.0')}
                <br />
                {t('account.edit-profile.delete-account-description.1')}
              </div>
              <div className={style.actionButtonContainer}>
                <Button
                  label={t('confirm.delete')}
                  onClick={onDeleteClick}
                  className={style.deleteButton}
                  gap={20}
                  icon={<BinIcon className={style.deleteIcon} />}
                />
              </div>
            </div>
          </div>

          <div className={style.footer}>
            <div className={style.actionButtonContainer}>
              <Button
                label={t('account.sign-out')}
                icon={<SignOutIcon />}
                className={style.actionButton}
                gap={20}
                onClick={signOut}
              />
            </div>
          </div>
        </div>
      </ContentWrapper>
      <DeleteAccountModal ref={deleteAccountRef} />
      <Footer />
    </>
  );
};
