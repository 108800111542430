import React, {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import CloseIcon from '../../assets/icons/cross-circle.svg?react';
import { Root, createRoot } from 'react-dom/client';

export interface ConfirmModalModalRef {
  closeModal: () => void;
  openModal: () => void;
}
interface Props {
  title: string;
  description: JSX.Element;
  action: {
    label: string;
    onClick: () => void;
  };
  cancel?: {
    label: string;
    onClick: () => void;
  };
  defaultOpen?: boolean;
  small?: boolean;
  noClose?: boolean;
}

const ConfirmModal = forwardRef<ConfirmModalModalRef | null, Props>(
  (props, ref) => {
    const { title, description, action, cancel, defaultOpen, small, noClose } =
      props;
    const { t } = useTranslation();
    const [open, setOpen] = useState(defaultOpen || false);
    const modalRef = createRef<HTMLDivElement>();
    const closeModal = useCallback(() => setOpen(false), []);
    const openModal = useCallback(() => setOpen(true), []);
    useOnClickOutside(modalRef, closeModal);

    useImperativeHandle(ref, () => ({
      closeModal,
      openModal,
    }));

    useEffect(() => {
      // stop scrolling when modal is open
      if (open) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }, [open]);

    const cancelWrapper = useCallback(() => {
      cancel?.onClick();
      closeModal();
    }, [cancel, closeModal]);

    const actionWrapper = useCallback(() => {
      action.onClick();
      closeModal();
    }, [action, closeModal]);

    const body = useMemo(
      () => (
        <div className={style.modalWrapper}>
          <div
            ref={modalRef}
            className={`${style.modalBody} ${small ? style.small : ''}`}
          >
            <div className={style.headerWrapper}>
              {noClose && (
                <div className={style.closeIconContainer} onClick={closeModal}>
                  <CloseIcon className={style.closeIcon}/>
                </div>
              )}
              <div className={style.header}>
                <div className={style.title}>{title}</div>
                <div className={style.bodyText}>{description}</div>
              </div>
            </div>
            <div className={style.buttonWrapper}>
              <button className={style.button} onClick={cancelWrapper}>
                {cancel?.label ?? t('confirm.cancel')}
              </button>
              <button className={style.button} onClick={actionWrapper}>
                {action?.label ?? t('confirm.yes')}
              </button>
            </div>
          </div>
        </div>
      ),
      [
        modalRef,
        small,
        noClose,
        closeModal,
        title,
        description,
        cancelWrapper,
        cancel?.label,
        t,
        actionWrapper,
        action?.label,
      ]
    );

    return createPortal(open ? body : <></>, document.body);
  }
);

export const confirmModal = (props: Props) => {
  const targetId = 'confirm-modal';
  let divTarget = document.getElementById(targetId);
  let root: Root | null = null;
  const modalRef = createRef<ConfirmModalModalRef>();

  const closeModal = () => {
    if (divTarget) {
      root.unmount();
      divTarget.remove();
    }
  };

  if (!divTarget) {
    divTarget = document.createElement('div');
    divTarget.id = targetId;
    document.body.appendChild(divTarget);
  }

  if (!root) {
    root = createRoot(divTarget);
  }

  root.render(
    <ConfirmModal
      ref={modalRef}
      {...props}
      cancel={{ label: props.cancel?.label || 'Cancel', onClick: closeModal }}
      defaultOpen={true}
    />
  );
};

export default ConfirmModal;
