import { useState, useEffect } from 'react';

function useEnhancedPathname() {
  const [pathname, setPathname] = useState(window.location.pathname);

  useEffect(() => {
    const handlePathChange = () => {
      setPathname(window.location.pathname);
    };

    // Handle browser navigation events
    window.addEventListener('popstate', handlePathChange);

    // Handle programmatic navigation (pushState, replaceState)
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function (...args) {
      originalPushState.apply(window.history, args);
      handlePathChange();
    };

    window.history.replaceState = function (...args) {
      originalReplaceState.apply(window.history, args);
      handlePathChange();
    };

    return () => {
      window.removeEventListener('popstate', handlePathChange);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, []);

  return pathname;
}

export default useEnhancedPathname;
