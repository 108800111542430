/* eslint-disable @stylistic/js/indent */
import React, { FC, useCallback, useMemo, useRef } from 'react';
import nl2br from 'react-br';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';
import {
  AiLawyer,
  LegalCaseState,
  Message,
  MessageType,
  Prematter,
  WebSocketMessage,
} from '@law-connect/types';
import LocationMessageComponent from './location';
import FAQComponent from '../faq';
import { formatMediumDateTime } from '../../utils/format-date';
import { ChatFilesComponent } from '../chat-files';
import { AiLawyerSelector } from '@law-connect/react-components';
import { aiLawyerImages } 
  from '../../assets/lawyers/ai-lawyer-images';

interface Props {
  chatState: LegalCaseState;
  chat: Message;
  prematter: Prematter;

  openLocation?: () => void;
  checkWsConnection?: () => Promise<void>;
  lastMessage?: boolean;
  sendConfirmation?: (args: { result: boolean }) => void;

  showMobileTimestamp?: boolean;
  setShowMobileTimestamp?: (state: boolean) => void;
  readOnly?: boolean;
  addQuestionError?: string | null;
  addQuestion?: (args: { question: string }) => void;
  deleteQuestion?: (args: { questionId: string }) => void;
  onConfirm?: () => void;

  addQuestionPending?: boolean;
  submitFormPending?: boolean;

  aiLawyer?: AiLawyer;
}

const ChatMessage: FC<Props> = (props) => {
  const {
    chatState,
    chat,
    openLocation,
    checkWsConnection,
    lastMessage,
    showMobileTimestamp,
    setShowMobileTimestamp,
    prematter,
    readOnly,
    addQuestionError,
    addQuestion,
    deleteQuestion,
    onConfirm,
    addQuestionPending,
    submitFormPending,
    aiLawyer,
  } = props;

  const isFromLawyer =
    chat.from === 'lawyer' ||
    (chat as unknown as WebSocketMessage).from === 'system';

  const isFromSystem = chat.from === 'lawyer';

  const { t } = useTranslation();

  const mobileTimestampTimeout = useRef<NodeJS.Timeout | null>(null);
  const chatClassName = useMemo(
    () =>
      `${isFromLawyer ? style.chatMessageSystem : style.chatMessageUser} ${
        showMobileTimestamp ? style.showTimestamp : ''
      }`,
    [isFromLawyer, showMobileTimestamp]
  );
  // timer to remove the pending state after a certain time -> 2 seconds

  const sendConfirmation = useCallback(async () => {
    await checkWsConnection();
    props.sendConfirmation({ result: true });
  }, [checkWsConnection, props]);
  const sendRejection = useCallback(async () => {
    await checkWsConnection();
    props.sendConfirmation({ result: false });
  }, [checkWsConnection, props]);

  const isPreConfirmationPending = addQuestionPending || submitFormPending;

  const DateTime = useMemo(
    () => (
      <div className={style.timestampContainer}>
        {formatMediumDateTime(chat.createdAt)}
      </div>
    ),
    [chat.createdAt]
  );
  const toggleMobileTimestamp = useCallback(() => {
    if (setShowMobileTimestamp) {
      if (showMobileTimestamp) {
        setShowMobileTimestamp(false);
      } else {
        if (mobileTimestampTimeout.current) {
          clearTimeout(mobileTimestampTimeout.current);
        }
        mobileTimestampTimeout.current = setTimeout(() => {
          setShowMobileTimestamp(false);
          mobileTimestampTimeout.current = null;
        }, 2000);
        setShowMobileTimestamp(true);
      }
    }
  }, [showMobileTimestamp, setShowMobileTimestamp]);

  if (chat.type === MessageType.Location) {
    if (isFromLawyer) {
      return (
        <LocationMessageComponent
          {...{
            chat,
            chatClassName,
            openLocation,
            showAllow:
              chatState === LegalCaseState.Location &&
              lastMessage &&
              !isPreConfirmationPending,
            toggleMobileTimestamp,
            DateTime,
          }}
        />
      );
    }
  } else if (chat.type === MessageType.Confirmation) {
    if (isPreConfirmationPending) {
      return null;
    }
    return (
      <>
        <div className={chatClassName} onClick={toggleMobileTimestamp}>
          <div className={style.outerWrapper}>
            <div className={style.chatMessage}>{nl2br(chat.content)}</div>
          </div>
          {DateTime}
        </div>
        {chatState === LegalCaseState.Location && isFromLawyer ? (
          <div className={`${style.outerWrapper} ${style.smallMargin}`}>
            <div className={style.confirmationWrapper}>
              <button
                onClick={sendConfirmation}
                className={style.confirmationButton}
              >
                {t('yes')}
              </button>
              <button
                onClick={sendRejection}
                className={style.confirmationButton}
              >
                {t('no')}
              </button>
            </div>
          </div>
        ) : null}
      </>
    );
  } else {
    const body = chat?.content?.toString().length ? (
      <>
        {isFromSystem ? (
          <div className={style.chatMessageSystemWrapper}>
            <div className={style.assistantImage}>
                <AiLawyerSelector 
                  isSelectorEnabled={false}
                  selectedAiLawyer={aiLawyer}
                  aiLawyers={aiLawyerImages}
                />
              </div>
            <div className={chatClassName} onClick={toggleMobileTimestamp}>
              <div className={style.chatMessage}>
                {nl2br(chat.content.toString())}
              </div>
              {DateTime}
            </div>
          </div>
          ) : 
        <div className={chatClassName} onClick={toggleMobileTimestamp}>
          <div className={style.chatMessage}>
            {nl2br(chat.content.toString())}
          </div>
          {DateTime}
        </div>}
      </>
    ) : null;

    return chat.questions && chat.questions.length > 0 ? (
      <FAQComponent
        prematter={prematter}
        addQuestion={addQuestion}
        deleteQuestion={deleteQuestion}
        readOnly={prematter.state !== LegalCaseState.Questions || readOnly}
        error={addQuestionError}
        onConfirm={onConfirm}
        addQuestionPending={addQuestionPending}
        submitFormPending={submitFormPending}
      >
        {body}
      </FAQComponent>
    ) : (
      <>
        {body}
        {chat.fileIds ? <ChatFilesComponent fileIds={chat.fileIds} /> : null}
      </>
    );
  }
};

export default ChatMessage;
