import React, { FC } from 'react';
import style from './style.module.less';
import FileIcon from '../../../../assets/icons/file.svg?react';
import CrossCircleIcon from '../../../../assets/icons/cross-circle.svg?react';

interface Props {
  file: File;
  removeFile: () => void;
  disabled: boolean;
}

export const MobileFileInput: FC<Props> = (props) => {
  const { disabled, file, removeFile } = props;
  return (
    <div
      className={`${style.fileRow} ${disabled ? style.disabled : ''}`}
      onClick={removeFile}
    >
      <div className={style.fileIconContainer}>
        <FileIcon className={style.fileIcon} />
      </div>
      <div className={style.fileName}>{file.name}</div>
      <div>
        <CrossCircleIcon className={style.crossCircleIcon} />
      </div>
    </div>
  );
};
