import React from 'react';
import styles from './style.module.less';
import LoadingSvg from '../../assets/icons/loading.svg?react';

interface Props {}

export const LoadingSpinner: React.FC<Props> = (props) => {
  return (
    <div>
      <LoadingSvg className={styles.loadingIcon} />
    </div>
  );
};
