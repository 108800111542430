import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from '../../components/wrapper';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import style from './style.module.less';
import { Helmet } from 'react-helmet';
import { useScreenSize, WidthType } from '../../hooks/use-is-mobile';
import { StackedCardComponent } from '../../components/stacked-card';
import { NeedHelpCard } from '../../components/need-help';
import { TriHighlightedCardStack } from './highlight-card';
import { AboutStatsContainer } from './stats';

import HomeBgDesktop from '../../assets/home-bg-desktop.jpg';
import HomeBgMobile from '../../assets/home-bg-mobile.jpg';
import aboutPhoneImage from '../../assets/about-phone.png';
import logoIcon from '../../assets/logo-icon.png';
import DoubleQuotes from '../../assets/icons/double-quotes.svg?react';
import DoubleQuotesDown from '../../assets/icons/double-quotes-down.svg?react';
import ChristianImage from '../../assets/about-cb.jpg';
// eslint-disable-next-line @stylistic/js/max-len
import AboutPrematterImageDesktop from '../../assets/about-prematter-example-image.png';
// eslint-disable-next-line @stylistic/js/max-len
import AboutPrematterImageMobile from '../../assets/about-prematter-example-image-mobile.png';
import AboutUsageImage from '../../assets/about-usage-image.jpg';
import AboutUsageImageMobile from '../../assets/about-usage-image-mobile.jpg';
import LightningIcon from '../../assets/icons/lightning.svg?react';
import StairIcon from '../../assets/icons/stair.svg?react';
import PhoneIcon from '../../assets/icons/phone.svg?react';
import SparkIcon from '../../assets/icons/spark.svg?react';
import ShieldTickIcon from '../../assets/icons/shield-tick.svg?react';
import UsersIcon from '../../assets/icons/users.svg?react';

interface Props {}
export const About: React.FC<Props> = () => {
  const { t } = useTranslation();
  const screenType = useScreenSize();

  const Image = screenType === WidthType.Mobile ? HomeBgMobile : HomeBgDesktop;
  return (
    <>
      <Helmet>
        <title>{t('pages.about')}</title>
      </Helmet>
      <Header />
      <Wrapper className={style.container} contentClassName={style.content}>
        <div className={style.heroWrapper}>
          <div className={style.heroCard}>
            <div className={style.titleWrapper}>
              <div className={style.title}>{t('about.title')}</div>
            </div>
            <div className={style.heroImageWrapper}>
              <img src={Image} alt='scale' className={style.heroImage} />
            </div>
            <div className={style.aboutPhoneImageWrapper}>
              <img
                src={aboutPhoneImage}
                alt='phone'
                className={style.aboutPhoneImage}
              />
              <div className={style.aboutHeroLogoWrapper}>
                <img
                  src={logoIcon}
                  alt='logo'
                  className={style.aboutHeroLogo}
                />
              </div>
            </div>
          </div>
          <div className={style.missionStatementContainer}>
            <div className={style.missionStatement}>
              <DoubleQuotes className={style.doubleQuotesIconLeft} />
              {t('about.mission-statement')}
              <DoubleQuotesDown className={style.doubleQuotesIconRight} />
            </div>
          </div>
          <div className={style.aboutCardsContainer}>
            <StackedCardComponent
              cardImage={ChristianImage}
              RightCard={
                <div className={style.commitmentCard}>
                  {t('about.commitment')}
                </div>
              }
              classNames={{
                textContainer: style.textContainer,
              }}
              animationOnScroll={true}
            />
          </div>
        </div>
        <div className={style.purposeContainer}>
          <div
            className={style.purposeText}
            dangerouslySetInnerHTML={{ __html: t('about.purpose') }}
          />
        </div>
        <AboutStatsContainer />
        <div className={style.aboutExampleImageContainer}>
          <img
            src={AboutPrematterImageDesktop}
            alt='prematter example'
            className={style.aboutExampleImage}
          />
          <img
            src={AboutPrematterImageMobile}
            alt='prematter example'
            className={style.aboutExampleImageMobile}
          />
        </div>

        <div className={style.legalInsightsContainer}>
          <div className={style.legalInsightsTitle}>{t('about.how-title')}</div>
          <TriHighlightedCardStack
            items={[
              {
                Icon: PhoneIcon,
                name: t('about.accessibility'),
                description: t('about.accessibility-description'),
              },
              {
                Icon: LightningIcon,
                name: t('about.empowering'),
                description: t('about.empowering-description'),
                iconClassName: style.noFillIcon,
              },
              {
                Icon: StairIcon,
                name: t('about.transformative'),
                description: t('about.transformative-description'),
                iconClassName: style.noFillIcon,
              },
            ]}
          />
        </div>

        <div className={style.usageContainer}>
          <img src={AboutUsageImage} alt='usage' className={style.usageImage} />
          <img
            src={AboutUsageImageMobile}
            alt='usage'
            className={style.usageImageMobile}
          />
        </div>
        <div className={style.foundationContainer}>
          <div className={style.foundationTitle}>
            {t('about.foundation-principles')}
          </div>
          <TriHighlightedCardStack
            items={[
              {
                Icon: SparkIcon,
                name: t('about.innovation'),
                description: t('about.innovation-description'),
              },
              {
                Icon: ShieldTickIcon,
                name: t('about.quality'),
                description: t('about.quality-description'),
              },
              {
                Icon: UsersIcon,
                name: t('about.inclusivity'),
                description: t('about.inclusivity-description'),
              },
            ]}
          />
        </div>

        <NeedHelpCard />
      </Wrapper>
      <Footer />
    </>
  );
};
