import {
  ClientIntakeForm,
  ExtendedForm,
  Form,
  IntroductoryForm,
  LawyerConnection,
  LawyerConnectionPermissions,
  LegalCaseQuestion,
  Message,
  MessageType,
  Prematter,
  File as PrematterFile,
  User,
  AiLawyer,
  Lawyer,
} from '@law-connect/types';
import request, { ServerResponseStatus } from './request';
import { AboutStats } from '../../types/about';

interface ServerResponse {
  message: string;
  status: string;
  timestamp: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

const api = {
  session: {
    fetch: async (): Promise<{ sessionId: string; prematter?: Prematter }> => {
      // send details to server
      const response = (await request.get(
        `/public/session?locale=${navigator.language}`
      )) as ServerResponse;
      if (response.data && response.status === 'success') {
        return response.data;
      } else {
        if (
          response.status === ServerResponseStatus.Failure &&
          response.message?.includes('abort')
        ) {
          // user aborted the request
          return { sessionId: '' };
        }
        // This should never error... but it does in dev and server
        throw new Error(response.message);
      }
    },
    setPrematter: async (args: {
      prematterId: string;
    }): Promise<{ prematter: Prematter }> => {
      // send details to server
      const response = (await request.post('/public/session', {
        prematterId: args.prematterId,
      })) as ServerResponse;

      return {
        prematter: response.data.prematter as Prematter,
      };
    },
    delete: async (): Promise<void> => {
      // send details to server
      (await request.delete('/public/session')) as ServerResponse;
    },
    sendMessage: async (args: {
      prematterId?: string;
      type: MessageType;
      text: string;
      files?: File[];
    }): Promise<Prematter> => {
      if (args.files && args.files.length > 0) {
        const formData = new FormData();
        args.files.forEach((file) => {
          formData.append('files', file);
        });
        formData.append('type', args.type);
        formData.append('prematterId', args.prematterId);
        formData.append('message', args.text);
        const response = (await request.multipart.post(
          '/public/message',
          formData
        )) as ServerResponse;
        if (response.status === 'failure') {
          throw new Error(response.message);
        }
        return response.data?.prematter as Prematter;
      }

      const response = (await request.post('/public/message', {
        type: args.type,
        prematterId: args.prematterId,
        message: args.text,
      })) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data?.prematter as Prematter;
    },
    recaptcha: async (args: { token: string }): Promise<{ isBot: boolean }> => {
      const response = (await request.post('/public/recaptcha', {
        token: args.token,
      })) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data as { isBot: boolean };
    },
  },
  prematter: {
    // Authenticated routes
    fetch: async (): Promise<Prematter[]> => {
      // send details to server
      const response = (await request.get('/prematter')) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      const prematters = response.data.prematters as Prematter[];
      // sort by updated at
      return prematters.sort((a, b) => b.updatedAt - a.updatedAt);
    },
    fetchById: async (args: { id: string }): Promise<Prematter> => {
      const response = (await request.get(
        `/prematter/${args.id}`
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data.prematter as Prematter;
    },
    updateName: async (args: {
      id: string;
      name: string;
    }): Promise<Prematter> => {
      const response = (await request.put(`/prematter/${args.id}/name`, {
        name: args.name,
      })) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data.prematter as Prematter;
    },
    delete: async (args: { id: string }): Promise<void> => {
      const response = (await request.delete(
        `/prematter/${args.id}`
      )) as ServerResponse;
      if (response.data) {
        return;
      } else {
        throw new Error(response.message);
      }
    },
    process: async (args: { id: string }): Promise<void> => {
      const response = (await request.post(
        `/prematter/${args.id}/process`
      )) as ServerResponse;
      if (response.data) {
        return;
      } else {
        throw new Error(response.message);
      }
    },

    regenerate: async (args: {
      id: string;
      form?: IntroductoryForm;
    }): Promise<Prematter> => {
      const response = (await request.post(`/prematter/${args.id}/regenerate`, {
        form: args.form,
      })) as ServerResponse;
      if (response.data) {
        return response.data.prematter as Prematter;
      } else {
        throw new Error(response.message);
      }
    },

    // Optional auth routes
    addQuestion: async (args: {
      id: string;
      question: string;
    }): Promise<Prematter> => {
      const response = (await request.post(`/prematter/${args.id}/question`, {
        question: args.question,
      })) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data.prematter as Prematter;
    },
    fetchQuestionById: async (args: {
      id: string;
      questionId: string;
    }): Promise<LegalCaseQuestion> => {
      const response = (await request.get(
        `/prematter/${args.id}/question/${args.questionId}`
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data.question as LegalCaseQuestion;
    },
    deleteQuestion: async (args: {
      id: string;
      questionId: string;
    }): Promise<void> => {
      const response = (await request.delete(
        `/prematter/${args.id}/question/${args.questionId}`
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
    },
    getForm: async (args: { id: string }): Promise<Prematter> => {
      const response = (await request.get(
        `/prematter/${args.id}/form`
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data.prematter as Prematter;
    },
    submitForm: async (args: {
      id: string;
      form: IntroductoryForm;
      generate?: boolean;
    }): Promise<Prematter> => {
      const response = (await request.post(`/prematter/${args.id}/form`, {
        form: args.form,
        generate: args.generate,
      })) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data.prematter as Prematter;
    },
    submitFormQuestion: async (args: {
      id: string;
      questionId: string;
      answer: string;
    }): Promise<Prematter> => {
      const response = (await request.post(
        `/prematter/${args.id}/form/${args.questionId}`,
        { answer: args.answer }
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data.prematter as Prematter;
    },
    updateExtendedForm: async (args: {
      id: string;
      extendedForm: ExtendedForm;
      generate?: boolean;
    }): Promise<Prematter> => {
      const response = (await request.post(
        `/prematter/${args.id}/extended-form`,
        {
          data: args.extendedForm,
          generate: args.generate,
        }
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data.prematter as Prematter;
    },
    canVerify: async (args: { id: string }): Promise<boolean> => {
      const response = (await request.get(
        `/prematter/${args.id}/can-verify`
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data.canVerify as boolean;
    },
    sendVerifyAnswers: async (args: { id: string }): Promise<string> => {
      const response = (await request.post(
        `/prematter/${args.id}/verify-answers`
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data.jobId as string;
    },
    updateAiLawyer: async (args: {
      id: string;
      aiLawyer: AiLawyer;
      setDefault?: boolean;
    }): Promise<Prematter> => {
      const response = (await request.post(`/prematter/${args.id}/ai-lawyer`, {
        aiLawyer: args.aiLawyer,
        setDefault: args.setDefault || false,
      })) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data.prematter as Prematter;
    },
  },
  file: {
    fetchByPrematterId: async (args: {
      prematterId: string;
    }): Promise<PrematterFile[]> => {
      const response = (await request.get(
        `/file/prematter/${args.prematterId}?includeUrl=true`
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data.files as PrematterFile[];
    },
    upload: async (args: {
      prematterId: string;
      file: File;
      attachedToMessage?: boolean;
    }): Promise<PrematterFile> => {
      const formData = new FormData();
      if (args.prematterId) {
        formData.append('prematterIds', JSON.stringify([args.prematterId]));
      }
      formData.append('file', args.file);
      let basePath = '/file/upload';
      if (args.attachedToMessage) {
        basePath += '?attachedToMessage=true';
      }

      // Perform synchronous request
      const headers = {
        'x-async-upload': 'true',
      };

      const response = (await request.multipart.post(
        basePath,
        formData,
        headers
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      } else if (response.data.jobId) {
        // Poll for job completion every 2 seconds
        let jobResponse;
        do {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          jobResponse = (await request.get(
            `/file/job/${response.data.jobId}`
          )) as ServerResponse;
        } while (jobResponse.data.status === 'pending');

        if (jobResponse.data.status === 'failed') {
          throw new Error(jobResponse.data.error);
        }
        // must have succeeded
        const fileId = jobResponse.data.fileId;
        // fetch the file
        const fileResponse = (await request.get(
          `/file/${fileId}?includeUrl=true`
        )) as ServerResponse;
        if (fileResponse.status === 'failure') {
          throw new Error(fileResponse.message);
        }
        return fileResponse.data.file as PrematterFile;
      }
      return response.data.file as PrematterFile;
    },
    download: async (args: { id: string }): Promise<void> => {
      const response = (await request.get(
        `/file/${args.id}?includeUrl=true`
      )) as ServerResponse;
      const file = response.data.file as PrematterFile;
      // file.path.url is the download url
      const link = document.createElement('a');
      link.href = file.url.path;
      link.setAttribute('download', file.name);
      link.click();
    },
    delete: async (args: {
      id: string;
      prematterId: string;
    }): Promise<void> => {
      const response = (await request.delete(
        `/file/prematter/${args.prematterId}/${args.id}`
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
    },
  },
  user: {
    fetch: async (): Promise<User> => {
      const response = (await request.get('/user')) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data.user as User;
    },
    update: async (data: {
      firstName?: string;
      lastName?: string;
      phone?: string;
      aiLawyer?: AiLawyer;
      functionalEmails?: boolean;
      marketingEmails?: boolean;
    }): Promise<User> => {
      const response = (await request.put('/user', data)) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data.user as User;
    },
    delete: async (): Promise<void> => {
      const response = (await request.delete('/user')) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
    },
  },
  lawyerConnection: {
    fetchAll: async (): Promise<LawyerConnection[]> => {
      const response = (await request.get(
        '/lawyer-connection'
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data.connections as LawyerConnection[];
    },
    fetchByPrematter: async (args: {
      id: string;
    }): Promise<LawyerConnection[]> => {
      const response = (await request.get(
        `/lawyer-connection/prematter/${args.id}`
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data.connections as LawyerConnection[];
    },
    fetchById: async (args: { id: string }): Promise<LawyerConnection> => {
      const response = (await request.get(
        `/lawyer-connection/${args.id}`
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data.connectionDetails as LawyerConnection;
    },
    fetchMessages: async (args: { id: string }): Promise<Message[]> => {
      const response = (await request.get(
        `/lawyer-connection/${args.id}/messages`
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data.messages as Message[];
    },
    postMessage: async (args: {
      id: string;
      message: string;
      files?: File[];
    }): Promise<Message> => {
      if (args.files) {
        const formData = new FormData();
        args.files.forEach((file) => {
          formData.append('files', file);
          formData.append('message', args.message);
        });
        const response = (await request.multipart.post(
          `/lawyer-connection/${args.id}/messages`,
          formData
        )) as ServerResponse;
        if (response.status === 'failure') {
          throw new Error(response.message);
        }
        return response.data?.message as Message;
      }

      const response = (await request.post(
        `/lawyer-connection/${args.id}/messages`,
        { message: args.message }
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data?.message as Message;
    },
    postFiles: async (args: {
      id: string;
      files: File[];
    }): Promise<Message> => {
      const formData = new FormData();
      args.files.forEach((file) => {
        formData.append('files', file);
      });
      const response = (await request.multipart.post(
        `/lawyer-connection/${args.id}/messages`,
        formData
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data?.message as Message;
    },
    fetchFiles: async (args: { id: string }): Promise<PrematterFile[]> => {
      const response = (await request.get(
        `/lawyer-connection/${args.id}/files?includeUrl=true`
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data.chat as PrematterFile[];
    },
    postPermissionMessage: async (args: {
      id: string;
      permissions: LawyerConnectionPermissions;
      requestMessageId?: string;
    }): Promise<Message> => {
      const response = (await request.post(
        `/lawyer-connection/${args.id}/messages`,
        {
          type: MessageType.Permission,
          message: {
            permissions: args.permissions,
            requestMessageId: args.requestMessageId,
          },
        }
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data?.message as Message;
    },
    postDeleteMessage: async (args: { id: string }): Promise<Message> => {
      const response = (await request.post(
        `/lawyer-connection/${args.id}/messages`,
        {
          type: MessageType.Closed,
          message: null,
        }
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data?.message as Message;
    },
    updateReadAt: async (args: {
      id: string;
      messageId?: string;
    }): Promise<number> => {
      const response = (await request.post(
        `/lawyer-connection/${args.id}/read-at`,
        { messageId: args.messageId }
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data as number;
    },
    longPoll: async (args: {
      connectionId: string;
    }): Promise<Message | null> => {
      const response = (await request.get(
        `/lawyer-connection/${args.connectionId}/long-poll`
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      if (response.data?.value) {
        return response.data?.value as Message;
      } else {
        return null;
      }
    },
    fetchClientIntakeForm: async (args: {
      connectionId: string;
    }): Promise<ClientIntakeForm> => {
      const response = (await request.get(
        `/lawyer-connection/${args.connectionId}/client-intake-form`
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data.form as ClientIntakeForm;
    },
    updateClientIntakeForm: async (args: {
      connectionId: string;
      form: ClientIntakeForm;
    }): Promise<ClientIntakeForm> => {
      const response = (await request.post(
        `/lawyer-connection/${args.connectionId}/client-intake-form`,
        { form: args.form }
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data.form as ClientIntakeForm;
    },
    markClientIntakeFormAsCompleted: async (args: {
      connectionId: string;
    }): Promise<{ form: ClientIntakeForm; message: Message }> => {
      const response = (await request.post(
        // eslint-disable-next-line @stylistic/js/max-len
        `/lawyer-connection/${args.connectionId}/client-intake-form/mark-as-completed`
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      const { form, message } = response.data as {
        form: ClientIntakeForm;
        message: Message;
      };
      return { form, message };
    },
    markEnded: async (args: { id: string }): Promise<void> => {
      const response = (await request.post(
        `/lawyer-connection/${args.id}/mark-ended`
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
    },
    markDeleted: async (args: { id: string }): Promise<void> => {
      const response = (await request.post(
        `/lawyer-connection/${args.id}/mark-deleted`
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
    },
    fetchLawyersByIds: async (args: { 
      connectionId: string; 
      lawyerIds: string[] 
    }): Promise<Lawyer[] | null> => {
      const response = (await request.post(
        `/lawyer-connection/${args.connectionId}/lawyers/by-ids`,
        { connectionId: args.connectionId, lawyerIds: args.lawyerIds }
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data.lawyers as Lawyer[];
    }
  },
  about: {
    fetchStats: async (): Promise<AboutStats> => {
      const response = (await request.get(
        '/public/about-stats'
      )) as ServerResponse;
      if (response.status === 'failure') {
        throw new Error(response.message);
      }
      return response.data as AboutStats;
    },
  },
};

export default api;
