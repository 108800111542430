import { useCookies } from 'react-cookie';
import { COOKIE_ID } from '../../../components/cookie-consent';
import { useEffect, useLayoutEffect, useMemo } from 'react';
import { useScreenSize } from '../../../hooks/use-is-mobile';
import { usePrevious } from '../../../hooks/use-previous';

interface Props {
  calcHeightResize: (noChat?:boolean) => void;
  files?: File[];
  message?: string;
  height?: number;
}

export const useHandleChatResize = (props: Props) => {
  const { calcHeightResize, files, message, height } = props;
  const [cookies] = useCookies([COOKIE_ID]);
  const consentCookie = useMemo(() => cookies[COOKIE_ID], [cookies]);
  const prevConsentCookie = usePrevious(consentCookie);
  const screenType = useScreenSize();

  // when message changes we want to recalculate the height of the textarea
  // so that it grows with the content
  useLayoutEffect(() => {
    calcHeightResize();
  }, [message, screenType, files, calcHeightResize, height]);

  // this is so that when the cookie is closed we trigger a resize of the chat
  useEffect(() => {
    if(consentCookie !== prevConsentCookie) {
      calcHeightResize(true);
    }
  }, [calcHeightResize, consentCookie, prevConsentCookie]);

  useEffect(() => {
    // on resize
    const resize = () => calcHeightResize();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [calcHeightResize]);

};
