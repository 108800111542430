import { Message, WebSocketMessage } from '@law-connect/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface WebsocketState {
  isSetUp: boolean;
  pending: {
    sendMessage?: boolean;
    connect?: boolean;
    deleteSession?: boolean;
  };
  errors: {
    sendMessage?: string | null;
    connect?: string | null;
    deleteSession?: string | null;
  };
}

const websocketState: WebsocketState = {
  isSetUp: false,
  pending: {},
  errors: {},
};

interface MessageWithError extends WebSocketMessage {
  errorCount?: number;
}

const websocketSlice = createSlice({
  name: 'websocket',
  initialState: websocketState,
  // you cant have a reducer name match the thunk action
  reducers: {
    clearErrors: (state, _action: PayloadAction<void>) => { 
      state.errors = {};
    },
    sendMessage: (
      state,
      _action: PayloadAction<MessageWithError>
    ) => {
      state.pending.sendMessage = true;
      state.errors.sendMessage = null;
    },
    sendMessageSuccess: (state, _action: PayloadAction<void>) => {
      state.pending.sendMessage = false;
      state.errors.sendMessage = null;
    },
    sendMessageError: (state, action: PayloadAction<string>) => {
      state.pending.sendMessage = false;
      state.errors.sendMessage = action.payload;
    },
    connect: (
      state,
      _action: PayloadAction<{
        prematterId?: string;
        captcha?: string;
        initialMessage?: string;
        reconnect?: boolean;
      }>
    ) => {
      state.pending.connect = true;
      state.errors.connect = null;
    },
    connectSuccess: (state, _action: PayloadAction<void>) => {
      state.pending.connect = false;
      state.errors.connect = null;
      state.isSetUp = true;
    },
    connectError: (state, action: PayloadAction<string>) => {
      state.pending.connect = false;
      state.errors.connect = action.payload;
    },
    deleteSession: (state, _action: PayloadAction<void>) => {
      state.pending.deleteSession = true;
      state.errors.deleteSession = null;
    },
    deleteSessionSuccess: (state, _action: PayloadAction<void>) => {
      state.pending.deleteSession = false;
      state.pending.sendMessage = false;
      state.isSetUp = false;
      // on delete session we want to reset the errors
      state.errors = {};
    },
    deleteSessionError: (state, action: PayloadAction<string>) => {
      state.pending.deleteSession = false;
      state.errors.deleteSession = action.payload;
    },
  },
});

// im not sure if this is good practice but it mimics what we used to do with dispatching actions
export const websocketActions = websocketSlice.actions;
export const websocketReducer = websocketSlice.reducer;
