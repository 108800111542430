import { IntroductoryFormType, Prematter } from '@law-connect/types';

export const canUseSettify = (prematter: Prematter) => {
  return false;
  if (!prematter) return false;

  let timezone = null;
  try {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    console.error('Error getting timezone', e);
  }

  return (
    prematter.context?.form?.source === IntroductoryFormType.Divorce &&
    (prematter.language === 'en' || prematter.language === 'en-GB') &&
    timezone.toLowerCase().includes('australia')
  );
};
