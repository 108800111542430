import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import style from './style.module.less';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/button';

interface Props {
  question: string;
  onChangeQuestion: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  submitQuestion: () => void;
  handleEnter: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

export const AskInputComponent: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { question, onChangeQuestion, submitQuestion, handleEnter } = props;
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const placeholderRef = useRef<HTMLDivElement>(null);

  const resize = useCallback(() => {
    if (textareaRef.current) {
      // this duplication of height being set is to trick the css into recalcuation the height
      // otherwise the scrollHeight will not shrink when the text is deleted
      textareaRef.current.style.height = '0px';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height =
        Math.max(scrollHeight, placeholderRef.current.clientHeight) + 'px';
      // placeholderRef.current.style.height = scrollHeight + 'px';
    }
  }, [textareaRef]);

  useLayoutEffect(() => {
    resize();
    // use initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, textareaRef]);

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  return (
    <div className={style.askInputContainer}>
      <div
        ref={placeholderRef}
        className={style.placeholder}
        style={{ opacity: question ? 0 : 1 }}
      >
        <div dangerouslySetInnerHTML={{ __html: t('home.ask-placeholder') }} />
      </div>
      <textarea
        placeholder={''}
        className={style.askInput}
        ref={textareaRef}
        value={question}
        onChange={onChangeQuestion}
        onKeyDown={handleEnter}
        rows={1}
      />
      <Button
        label={null}
        className={style.askButton}
        labelClassName={style.buttonLabel}
        iconClassName={style.buttonIcon}
        onClick={submitQuestion}
      />
    </div>
  );
};
