import React, { FC, useState } from 'react';
import style from './style.module.less';
import DeleteQuestionComponent from './delete';
import { LegalCaseQuestion } from '@law-connect/types';
import Markdown from 'react-markdown';

interface Props {
  question: LegalCaseQuestion;
  readOnly: boolean;
  answered: boolean;
  deleteQuestionHandler: () => void;
}

const QuestionComponent: FC<Props> = (props) => {
  const { question, answered, readOnly, deleteQuestionHandler } = props;
  const [isPending, setIsPending] = useState(false);

  return (
    <div className={style.QA}>
      <div
        className={`${style.question} ${isPending ? style.deleteShowing : ''} ${
          readOnly ? style.readOnly : ''
        }`}
      >
        <div className={style.questionText}>{question.question}</div>
        {!readOnly && !answered && (
          <DeleteQuestionComponent
            isPending={isPending}
            setIsPending={setIsPending}
            deleteQuestion={deleteQuestionHandler}
          />
        )}
      </div>
      {answered && question.answer ? (
        <div className={style.answer}>
          <Markdown>{question.answer}</Markdown>
        </div>
      ) : null}
    </div>
  );
};

export default QuestionComponent;
