import React, { useCallback } from 'react';
import { File } from '@law-connect/types';
import style from './style.module.less';
import { FileIcon } from '../file-icon';
import { useTranslation } from 'react-i18next';
import { ContextMenu } from '@law-connect/react-components';
import DownloadIcon from '../../assets/icons/download.svg?react';

interface Props {
  file: File;
  showDownload?: boolean;
  isSender?: boolean;
}

export const ChatFile = (props: Props) => {
  const { file, showDownload, isSender } = props;

  return (
    <div className={style.fileContainer}>
      <FileName file={file} />
      <FilePreview
        file={file}
        showDownload={showDownload}
        isSender={isSender}
      />
    </div>
  );
};

export const FileName = (props: Props) => {
  const { file } = props;
  const { t } = useTranslation();
  return (
    <div className={`${style.fileTextContainer} ${!file ? style.full : ''}`}>
      <FileIcon file={file} className={style.icon} />
      <div className={style.fileName}>
        {!file ? null : !file.id ? t('chat.uploading-files') : file.name}
      </div>
    </div>
  );
};

export const FilePreview = (props: Props) => {
  const { file, showDownload, isSender } = props;

  const massageText = useCallback((text: string) => {
    // we want to remove new line
    return text.replace(/(?:\r\n|\r|\n)/g, ' ');
  }, []);

  const [imageError, setImageError] = React.useState<boolean | null>(null);
  if (!file || imageError) {
    return null;
  }

  let Body = null;
  if (file.mime?.startsWith('image/') && file.url) {
    Body = (
      <div
        className={`${style.fileImageContainer} 
          ${isSender ? style.isSender : ''}`}
      >
        <img
          src={file.url.path}
          alt={file.name}
          className={style.fileImage}
          onError={() => setImageError(true)}
        />
      </div>
    );
  } else if (file.textContent) {
    Body = (
      <div className={style.textContent}>{massageText(file.textContent)}</div>
    );
  } else {
    return null;
  }

  return (
    <div className={style.filePreviewContainer}>
      {Body}
      {showDownload && file.url && imageError === false && (
        <div className={style.contextMenuContainer}>
          <ContextMenu
            items={[
              {
                label: 'Download',
                onClick: () => window.open(file.url.path, '_blank'),
                icon: <DownloadIcon className={style.downloadIcon} />,
              },
            ]}
            className={style.contextMenu}
          />
        </div>
      )}
    </div>
  );
};
