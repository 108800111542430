import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { security } from '../redux/api/security';
import DownloadIcon from '../assets/icons/download.svg?react';

export const useDownloadReport = () => {
  const { t } = useTranslation();
  const [token, setToken] = useState(null);

  const downloadReport = useCallback((id:string) => {
    // href={`/api/prematter/${prematter.id}/report/download?jwt=${token}`}
    // open the link in a new tab
    window.open(`/api/prematter/${id}/report/download?jwt=${token}`, '_blank');
  }, [token]);
  const getJWT = useCallback(async () => {
    const jwt = await security.getAccessToken();
    setToken(jwt);
  }, []);

  useEffect(() => {
    getJWT();
  }, [getJWT]);

  return (id:string) => ({
    label: t('prematter.download'),
    onClick: () => downloadReport(id),
    icon: <DownloadIcon />,
  });
};
