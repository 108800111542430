import React, { FC } from 'react';
import { LegalCaseState } from '@law-connect/types';
import style from './style.module.less';
import { useTranslation } from 'react-i18next';

interface Props {
  prematterState: LegalCaseState;
  className?: string;
  skipPending?: boolean;
}

export const VerifyLockedMessage: FC<Props> = (props) => {
  const { prematterState, className, skipPending } = props;
  const { t } = useTranslation();

  // if (prematterState === LegalCaseState.VerificationPending && !skipPending) {
  //   return (
  //     <div className={`${style.container} ${className ?? ''}`}>
  //       <div className={style.text}>{t('verify.locked-verifying')}</div>
  //     </div>
  //   );
  // } else
  if (prematterState === LegalCaseState.Verified) {
    return (
      <div className={`${style.container} ${className ?? ''}`}>
        <div className={style.text}>{t('verify.locked-verified')}</div>
      </div>
    );
  } else {
    return null;
  }
};
