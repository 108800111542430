import React, { FC, useEffect, useState } from 'react';
import DefaultUserImage from '../../assets/profile-no-img.png';
import style from './style.module.less';

interface Props {
  imageUrl: string;
}

export const UserAvatar: FC<Props> = (props) => {
  const { imageUrl } = props;
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // we want to load the image to check if it's valid and also to avoid the flash of the default image
    if (imageUrl) {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => setImageLoaded(true);
    }
  }, [imageUrl]);

  return imageLoaded ? (
    <img
      src={imageUrl ?? DefaultUserImage}
      alt='Menu Pic'
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = DefaultUserImage;
      }}
    />
  ) : (
    // <div className={style.placeholderImage}/>
    <img
      src={DefaultUserImage}
      alt='Menu Pic'
    />
  );
};
