import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Markdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { LegalCaseQuestion, LegalCaseState } from '@law-connect/types';
import style from './style.module.less';
import { PercentageSpinner } from '../percentage-spinner';
import ChevronUpIcon from '../../assets/icons/chevron-up.svg?react';
import ChevronDownIcon from '../../assets/icons/chevron-down.svg?react';
import ShieldPlusIcon from '../../assets/icons/shield-plus.svg?react';
import ShieldTickIcon from '../../assets/icons/shield-tick.svg?react';
import WarningIcon from '../../assets/icons/warning-circle.svg?react';
import SparkIcon from '../../assets/icons/spark.svg?react';
import rehypeRaw from 'rehype-raw';
import { NoVerifyCard } from './no-verify-card';

interface Props {
  prematterState: LegalCaseState;
  question: LegalCaseQuestion;
  show?: boolean;
}

const LinkRenderer = (props: { href: string; children: React.ReactNode }) => {
  return (
    <a href={props.href} target='_blank' rel='noreferrer'>
      {props.children}
    </a>
  );
};

export const QuestionCard: React.FC<Props> = (props) => {
  const { question, prematterState } = props;
  const { t } = useTranslation();
  const [visible, setVisible] = useState(props.show ?? false);
  const [showOriginalAnswer, setShowOriginialAnswer] = useState<boolean>(false);

  useEffect(() => {
    setVisible(props.show ?? false);
  }, [props.show]);

  const setVerified = useCallback(() => setShowOriginialAnswer(false), []);
  const setOriginal = useCallback(() => setShowOriginialAnswer(true), []);

  const hideToggle = useMemo(() => {
    return (
      !question.verifiedAnswer ||
      (question.verifiedAnswer && question.answer === question.verifiedAnswer)
    );
  }, [question.answer, question.verifiedAnswer]);

  const markdownBody = useMemo(() => {
    // if there is no change, we show the verified otherwise we base it off the showOriginalAnswer
    if (!question.answer) {
      return t('prematter.no-answer');
    } else if (!question.verifiedAnswer) {
      return question.answer;
    } else if (question.isAnswerCorrect) {
      return question.verifiedAnswer;
    } else if (showOriginalAnswer) {
      return question.answer;
    } else {
      return question.verifiedAnswer;
    }
  }, [question, showOriginalAnswer, t]);

  const verifiedIcon = useMemo(() => {
    if (prematterState === LegalCaseState.Verified) {
      // this means there was no verified answer returned
      if (question.isAnswerCorrect) {
        return (
          <div
            className={style.iconWrapper}
            title={t('verify.verified-tooltip')}
          >
            <ShieldTickIcon className={style.shieldIcon} />
          </div>
        );
      } else if (!question.verifiedAnswer?.length) {
        return (
          <div
            className={style.iconWrapper}
            title={t('verify.not-verified-tooltip')}
          >
            <WarningIcon className={style.warningIcon} />
          </div>
        );
      } else {
        return (
          <div
            className={style.iconWrapper}
            title={t('verify.corrected-tooltip')}
          >
            <ShieldPlusIcon className={style.shieldIcon} />
          </div>
        );
      }
    }
  }, [
    prematterState,
    question.isAnswerCorrect,
    question.verifiedAnswer?.length,
  ]);

  return (
    <div className={`${style.questionCard} ${visible ? style.visible : ''}`}>
      <div className={style.question} onClick={() => setVisible(!visible)}>
        <div className={style.text}>{question.question}</div>
        {verifiedIcon}
        <div className={style.toggle}>
          {visible ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </div>
      </div>
      <div className={`${style.answer} ${visible ? '' : style.collapsed}`}>
        {question.processing ? (
          <PercentageSpinner percentage={75} size={48} />
        ) : (
          <>
            {!hideToggle ? (
              <div className={style.toggleBar}>
                <div
                  className={`${style.toggleButton} ${
                    showOriginalAnswer ? '' : style.active
                  }`}
                  onClick={setVerified}
                >
                  <ShieldPlusIcon className={style.toggleIcon} />
                  <div className={style.toggleText}>
                    {t('verify.verified-answer')}
                  </div>
                </div>
                <div
                  className={`${style.toggleButton} ${
                    showOriginalAnswer ? style.active : ''
                  }`}
                  onClick={setOriginal}
                >
                  <SparkIcon className={style.toggleIcon} />
                  <div className={style.toggleText}>
                    {t('verify.original-answer')}
                  </div>
                </div>
              </div>
            ) : prematterState === LegalCaseState.Verified &&
              !question.verifiedAnswer &&
              !question.isAnswerCorrect ? (
                <NoVerifyCard />
              ) : null}
            <Markdown
              rehypePlugins={[rehypeRaw]}
              components={{ a: LinkRenderer }}
              className={style.markdown}
            >
              {/* {question.answer || 'No answer found'} */}
              {markdownBody}
            </Markdown>
          </>
        )}
      </div>
    </div>
  );
};
