import React from 'react';
import style from './style.module.less';

interface Props {
  title: string;
  titleClassName?: string;
}

export const PageTitleHero: React.FC<Props> = (props) => {
  return (
    <div className={style.pageTitleHero}>
      <div className={`${style.pageTitle} ${props.titleClassName ?? ''}`}>
        {props.title}
      </div>
      <div className={style.background} />
    </div>
  );
};
