import React, { FC, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { LibraryCase } from './case';
import { LibraryHome } from './home';
import { LibraryCategory } from './category';
import { useTranslation } from 'react-i18next';

// we want to import a list of folders to get the categories

export const Library: FC = () => {
  // if language is changed here, we want to redirect to home page

  const { i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleLanguageChange = (lan: string) => {
      if (!lan.includes('end')) {
        navigate('/');
      }
    };
    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n, navigate]);

  return (
    <Routes>
      <Route path='/' element={<LibraryHome />} />
      <Route path='/:category' element={<LibraryCategory />} />
      <Route path='/:category/:caseName' element={<LibraryCase />} />

      {/* redirect to library */}
      <Route path='*' element={<LibraryHome />} />
    </Routes>
  );
};
