import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import { AiLawyer } from '@law-connect/types';

const STORE = 'user';
const prematterThunk = {
  fetch: createAsyncThunk(`${STORE}/fetch`, async (_, { rejectWithValue }) => {
    try {
      const response = await api.user.fetch();

      // semi hard coding to remove this https://gravatar.com/avatar/265fe375ab04e674100eb080166ae06409ffdf153c9bc668ad9ea4876a41ac2a?d=blank&size=200
      return {
        ...response,
        picture: /gravatar.*d=blank/.test(response?.picture ?? '')
          ? null
          : response.picture,
      };
    } catch (error) {
      console.error('fetch user', error);
      return rejectWithValue(error.message as string);
    }
  }),
  delete: createAsyncThunk(
    `${STORE}/delete`,
    async (_, { rejectWithValue }) => {
      try {
        const response = await api.user.delete();
        toast.success(i18n.t('toast.delete-user-success'));
        return response;
      } catch (error) {
        toast.error(i18n.t('toast.delete-error'));
        return rejectWithValue(error.message as string);
      }
    }
  ),
  update: createAsyncThunk(
    `${STORE}/update`,
    async (
      user: { firstName?: string; lastName?: string; 
        phone?: string, aiLawyer?: AiLawyer, 
        functionalEmails?: boolean, marketingEmails?: boolean },
      { rejectWithValue }
    ) => {
      try {
        const response = await api.user.update(user);
        toast.success(i18n.t('toast.update-user-success'));
        return response;
      } catch (error) {
        toast.error(i18n.t('toast.update-error'));
        return rejectWithValue(error.message as string);
      }
    }
  ),
};

export default prematterThunk;
