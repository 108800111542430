import { AiLawyer } from '@law-connect/types';

export const getRandomAiLawyer = (): AiLawyer => {
  const options = Object.values(AiLawyer);
  return options[Math.floor(Math.random() * options.length)];
};

export const getAiLawyerImage = (args: {
  aiLawyer: AiLawyer,
  angled?: boolean,
}): string => {
  const { aiLawyer, angled } = args;
  if (angled) {
    return `/lawyer/${aiLawyer.toLowerCase()}-angled.png`;
  }
  return `/lawyer/${aiLawyer.toLowerCase()}-round.png`;
};
