import about from './about.json';
import account from './account.json';
import auth from './auth.json';
import chat from './chat.json';
import connect from './connect.json';
import confirm from './confirm.json';
import email from './email.json';
import language from './language.json';
import library from './library.json';
import home from './home.json';
import pages from './pages.json';
import privacy from './privacy.json';
import prematter from './prematter.json';
import report from './report.json';
import sailing from './sailing.json';
import terms from './terms.json';
import toast from './toast.json';
import verify from './verify.json';

const translations = {
  translation: {
    about,
    auth,
    account,
    chat,
    confirm,
    connect,
    email,
    language,
    library,
    home,
    pages,
    privacy,
    prematter,
    report,
    sailing,
    terms,
    toast,
    verify
  },
};

export default translations;