import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import style from './style.module.less';
import env from '../../../constants/env';
import CopyIcon from '../../../assets/icons/copy-link.svg?react';

interface Props {
  id: string;
}

export const EmailLinkComponent: FC<Props> = (props) => {
  const { id } = props;
  const { t } = useTranslation();
  const mobileFileRef = useRef<HTMLDivElement>(null);
  
  const emailUrl = useMemo(() => {
    return `A2MTR${id}@${env.EMAIL_DOMAIN}`;
  }, [id]);

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(emailUrl);
    toast.success(t('chat.copied-link'));
  }, [emailUrl, t]);

  useEffect(() => {
    const button = mobileFileRef?.current?.querySelector('button');
    if (button) {
      button.addEventListener('click', copyToClipboard);
      return () => {
        button.removeEventListener('click', copyToClipboard);
      };
    }
  }, [mobileFileRef, copyToClipboard]);

  return (
    <div className={style.text}>
      <div className={style.desktop}>
        {t('chat.questionnaire-upload-desktop')}&nbsp;
        <div onClick={copyToClipboard} className={style.url}>
          <span>{emailUrl}</span>
          <CopyIcon className={style.copyIcon} />
        </div>
      </div>
      <div className={style.mobile}>
        <span
          ref={mobileFileRef}
          dangerouslySetInnerHTML={{
            __html: t('chat.questionnaire-upload-mobile'),
          }}
          className={style.uploadFileText}
        />
        <CopyIcon className={style.copyIcon} onClick={copyToClipboard} />
      </div>
    </div>
  );
};
