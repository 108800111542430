import React from 'react';
import { useAppSelector } from '../../redux/hooks';
import selectors from '../../redux/selectors';
import { ChatFile } from './file';

interface Props {
  fileIds: string[];
}

export const ChatFilesComponent = (props: Props) => {
  const { fileIds } = props;
  const files = useAppSelector(selectors.file.getAll());

  if (!files) {
    return null;
  }

  return (
    <>
      {fileIds.map((fileId) => {
        const file = files?.find((f) => f.id === fileId);
        if (!file) {
          return null;
        }
        return <ChatFile key={file.id} file={file} />;
      })}
    </>
  );
};
