import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { FALLBACK_LANGUAGE } from './constants';

import enJSON from '../translations/locales/en';
import arJSON from '../translations/locales/ar';
import bnJSON from '../translations/locales/bn';
import deJSON from '../translations/locales/de';
import esJSON from '../translations/locales/es';
import frJSON from '../translations/locales/fr';
import hiJSON from '../translations/locales/hi';
import jaJSON from '../translations/locales/ja';
import ptJSON from '../translations/locales/pt';
import zhJSON from '../translations/locales/zh';
import nlJSON from '../translations/locales/nl';

type JSONObject = { [key: string]: unknown };

export const languages: {
  [lng: string]: { name: string; nativeName: string; json: JSONObject };
} = {
  en: {
    name: 'English',
    nativeName: 'English',
    json: enJSON,
  },
  fr: {
    name: 'French',
    nativeName: 'Français',
    json: frJSON,
  },
  ar: {
    name: 'Arabic',
    nativeName: 'Al-’arabīyah (العربية)',
    json: arJSON,
  },
  bn: {
    name: 'Bangla',
    nativeName: 'Bangla (বাংলা)',
    json: bnJSON,
  },
  de: {
    name: 'German',
    nativeName: 'Deutsch',
    json: deJSON,
  },
  es: {
    name: 'Spanish',
    nativeName: 'Español',
    json: esJSON,
  },
  hi: {
    name: 'Hindi',
    nativeName: 'Hindī (हिन्दी)',
    json: hiJSON,
  },
  ja: {
    name: 'Japanese',
    nativeName: 'Nihongo (日本語)',
    json: jaJSON,
  },  
  nl: {
    name: 'Dutch',
    nativeName: 'Nederlands',
    json: nlJSON,
  },
  pt: {
    name: 'Portuguese',
    nativeName: 'Português',
    json: ptJSON,
  },
  zh: {
    name: 'Chinese',
    nativeName: 'Zhōngwén (中文)',
    json: zhJSON,
  },
};

const resources = Object.keys(languages).reduce(
  (acc: { [lng: string]: JSONObject }, lang: string) => {
    acc[lang] = languages[lang].json;
    return acc;
  },
  {}
);

i18n.use(initReactI18next).use(LanguageDetector).init({
  debug: true,
  resources,
  fallbackLng: FALLBACK_LANGUAGE,
});

export const getLanguage = () => {
  return (
    i18n.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
    FALLBACK_LANGUAGE
  );
};

export const setLanguage = (lng: string) => {
  i18n.changeLanguage(lng);
};
