import React, { MouseEvent } from 'react';
import style from './style.module.less';
import ArrowRight from '../../assets/icons/arrow-right.svg?react';

interface Props {
  label: string;
  icon?: React.ReactNode;
  onClick: (e?: MouseEvent) => void;
  disabled?: boolean;
  gap?: number;
  className?: string;
  labelClassName?: string;
  iconClassName?: string;
  small?: boolean;
}

export const Button: React.FC<Props> = (props) => {
  const {
    label,
    icon,
    onClick,
    disabled,
    gap,
    className,
    labelClassName,
    iconClassName,
    small,
  } = props;
  return (
    <div
      className={`${style.button} ${small ? style.small : ''} ${
        disabled ? style.disabled : ''
      } ${className ? className : ''}`}
      onClick={disabled ? null : onClick}
      style={{ gap }}
    >
      <div className={`${style.label} ${labelClassName ? labelClassName : ''}`}>
        {label}
      </div>
      <div className={`${style.icon} ${iconClassName ? iconClassName : ''}`}>
        {icon ? icon : <ArrowRight className={style.arrowRight} />}
      </div>
    </div>
  );
};

export const StandardButton: React.FC<Props> = (props) => {
  return (
    <Button
      {...props}
      className={`${style.standardButton} ${
        props.className ? props.className : ''
      }`}
    />
  );
};
