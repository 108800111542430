import React, { FC, useState } from 'react';
import style from './style.module.less';
import InfoCircleIcon from '../../../assets/icons/info-circle.svg?react';
import { BioIntroModal } from './modal';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../redux/hooks';
import selectors from '../../../redux/selectors';
import placeholderImage from '../../../assets/profile-no-img.png';
import { Lawyer } from '@law-connect/types';
import BriefcaseIcon from '../../../assets/icons/briefcase.svg';
import BriefcaseSvgIcon from '../../../assets/icons/briefcase.svg?react';

interface BioIntroProps {
  lawyers?: Lawyer[];
}

export const BioIntro: FC<BioIntroProps> = (props) => {
  const { lawyers } = props;
  const { id } = useParams<{ id: string }>();

  const [isOpen, setIsOpen] = useState(false);
  const openBioModal = () => setIsOpen(true);
  const closeBioModal = () => setIsOpen(false);

  const connection = useAppSelector(
    selectors.lawyerConnection.getConnectionById(id)
  );

  if (!connection?.lawyer) {
    return null;
  }

  return (
    <>
      <div className={style.container}>
        <div className={style.firmImageWrapper}>
          {
            connection.lawyer.firm.image ?
              (
                <img
                  src={connection.lawyer.firm.image}
                  alt='firm avatar'
                  className={style.firmImage}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = BriefcaseIcon;
                    currentTarget.className= style.bioFirmPlaceholder;
                  }}
                />
              ) : 
              <div className={style.firmPlaceholderWrapper}>
                <BriefcaseSvgIcon 
                  className={style.firmPlaceholder} />
              </div>
          }
        </div>
        <div className={style.firmNameContainer} onClick={openBioModal}>
          <div className={style.firmName}>
            {connection.lawyer.firm.name}
          </div>
          <InfoCircleIcon className={style.warningIcon} />
        </div>
      </div>
      <BioIntroModal
        closeModal={closeBioModal}
        isOpen={isOpen}
        connection={connection}
        lawyers={lawyers}
      />
    </>
  );
};
