import React, { FC, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { libraryAssets } from '../assets';
import { convertToKebabCase } from '../../../utils/case-conversion';
import { Header } from '../../../components/header';
import { LibraryHero } from '../hero';
import { NeedHelpCard } from '../../../components/need-help';
import { LibraryCarousel } from '../carousel';
import { Footer } from '../../../components/footer';
import style from './style.module.less';
import { CategoryCards } from '../category-card';
import {
  getLibraryPathWithCountry,
} from '../../../utils/get-library-path-with-country';

export const LibraryCategory: FC = () => {
  const { category } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const libraryCategory = libraryAssets.find(
    (c) => convertToKebabCase(c?.name) === category
  );
  const extraCategories = libraryAssets.filter(
    (c) => convertToKebabCase(c?.name) !== category
  );
  useEffect(() => {
    // if no category or case found, redirect to library
    if (!libraryCategory) {
      navigate(getLibraryPathWithCountry());
    }
  }, [libraryCategory, navigate]);

  if (!libraryCategory) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{t('pages.library')}</title>
      </Helmet>
      <Header />
      <div className={style.menuDivider} />
      <LibraryHero
        breadcrumbs={[
          { name: 'Library', url: getLibraryPathWithCountry() },
          {
            name: libraryCategory.name,
          }
        ]}
        description={libraryCategory.title}
      />
      <div className={style.descriptionContainer}>
        <div
          className={style.description}
          dangerouslySetInnerHTML={{ __html: libraryCategory.description }}
        />
      </div>

      <div className={style.bodyContainer}>
        <div className={style.bodyImageContainer}>
          <img
            src={libraryCategory.bubbleImage}
            alt={libraryCategory.name}
            className={style.bodyImage}
          />
        </div>
        <div className={style.bodyTextContainer}>
          {libraryCategory.bodyText.map((text, index) => (
            <div key={index} className={style.bodyText}>
              <div className={style.bodyTextTitle}>{text.header}</div>
              <div
                className={style.bodyTextDescription}
                dangerouslySetInnerHTML={{ __html: text.body }}
              />
            </div>
          ))}
        </div>
      </div>

      <LibraryCarousel
        title={`Explore issues relating to ${libraryCategory.name}`}
        cases={libraryCategory.cases}
        titleClassName={style.carouselTitle}
      />
      <div className={style.needHelpContainer}>
        <NeedHelpCard />
      </div>

      <div className={style.moreCategories}>
        <div className={style.moreCategoriesTitle}>
          {t('library.category.explore-more')}
        </div>
        <CategoryCards
          categories={extraCategories}
          classNames={{
            containerClassName: style.categoryCardsContainer,
            titleClassName: style.categoryCardTitle,
            iconContainerClassName: style.categoryCardIconContainer,
            iconClassName: style.categoryCardIcon,
          }}
        />
      </div>

      <Footer className={style.noFooterMargin} />
    </>
  );
};
