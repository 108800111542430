import React, { forwardRef, MouseEvent } from 'react';
import style from './style.module.less';
import { Button } from '../button';
import { formatDate } from '../../utils/format-date';

interface Props {
  id?: number;
  icon: React.ReactNode;
  title: string;
  description?: string;
  subtitle?: string;
  timestamp?: number;
  buttonLabel: string;
  buttonIcon?: React.ReactNode;
  onClick: () => void;
  fullClick?: boolean; // on card click we trigger the button click
}

export const Card = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    id,
    icon,
    title,
    description,
    subtitle,
    timestamp,
    buttonLabel,
    buttonIcon,
    fullClick,
    onClick,
  } = props;

  const onClickWrapper = (e: MouseEvent) => {
    e.stopPropagation();
    onClick();
  };
  return (
    <div
      className={style.card}
      ref={ref ?? undefined}
      id={id ? `${id}` : undefined}
      onClick={fullClick ? onClickWrapper : undefined}
    >
      <div className={style.cardContent}>
        <div className={style.icon}>{icon}</div>
        {description && <div className={style.description}>{description}</div>}
        <div className={style.title}>{title}</div>
        {subtitle ? <div className={style.subtitle}>{subtitle}</div> : null}
        {timestamp && (
          <div className={style.timestamp}>{formatDate(timestamp)}</div>
        )}
      </div>
      <Button label={buttonLabel} icon={buttonIcon} onClick={onClickWrapper} />
    </div>
  );
});

interface PlaceholderProps {
  onClick?: () => void;
  children?: React.ReactNode;
  className?: string;
  placeholder?: boolean;
}

export const CardPlaceholder = forwardRef<HTMLDivElement, PlaceholderProps>(
  (props, ref) => {
    const { children, className, onClick, placeholder } = props;
    return (
      <div
        className={`${style.card} ${style.placeholder} ${
          placeholder ? style.noBody : ''
        } ${className ?? ''}`}
        onClick={onClick}
        ref={ref ?? undefined}
      >
        {children}
      </div>
    );
  }
);

interface CardProps {
  children: React.ReactNode;
  className?: string;
  onScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
}

export const Cards = forwardRef<HTMLDivElement, CardProps>((props, ref) => {
  return (
    <div
      ref={ref ?? undefined}
      className={`${style.cards} ${props.className ?? ''}`}
      onScroll={props.onScroll}
    >
      {props.children}
    </div>
  );
});
