import React, { FC } from 'react';
import LoadingSvg from '../../assets/icons/loading.svg?react';
import style from './uploading.module.less';
import { useTranslation } from 'react-i18next';

interface Props {
  filesLoading: boolean;
}

export const FileUploading: FC<Props> = (props) => {
  const { filesLoading } = props;
  const { t } = useTranslation();
  return filesLoading ? (
    <div className={style.container}>
      <div className={style.loadingIconContainer}>
        <LoadingSvg className={style.loadingIcon} />
      </div>
      <div className={style.text}>{t('chat.uploading-files')}</div>
    </div>
  ) : null;
};
