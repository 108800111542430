import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Steps } from './use-carousel-steps';
import style from './style.module.less';

interface Props {
  carouselSteps: Steps[];
  activeStep: number;
  isTransitioning: boolean;
  setIsTransitioning: (isTransitioning: boolean) => void;
  clearProgressRate: () => void;
  incrementProgressRate: (amount: number) => void;
  goNextStep: () => void;
}

const MAX_VIDEO_LOOP_COUNT = 2;
const PROGRESS_RATE_UPDATE_INTERVAL = 60;
export const TRANSITION_DURATION = 100;

// this handles the video preloading and also jumping to the next index once the video has finished playing twice
export const VideoWrapper: FC<Props> = (props) => {
  const {
    carouselSteps,
    activeStep,
    clearProgressRate,
    incrementProgressRate,
    isTransitioning,
    goNextStep,
  } = props;

  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoDurations, setVideoDurations] = useState<Record<string, number>>(
    {}
  );
  const [videoCache, setVideoCache] = useState<Record<string, string>>({});
  const [videoLoopCount, setVideoLoopCount] = useState(0);
  const [videoWidth, setVideoWidth] = useState(0);

  const currentStep = useMemo(
    () => carouselSteps[activeStep],
    [activeStep, carouselSteps]
  );
  const progressRateTimer = useRef<NodeJS.Timeout | null>(null);

  // after playing the vide, we want to loop it X times and then move to the next step
  const onVideoEnded = useCallback(() => {
    if (videoLoopCount < MAX_VIDEO_LOOP_COUNT - 1) {
      setVideoLoopCount(videoLoopCount + 1);
      videoRef.current?.play();
    } else {
      // we are doing the transitioning on video finished playing rather than the progress rate so we have smoother transitioning
      clearInterval(progressRateTimer.current);
      progressRateTimer.current = null;
      goNextStep();
    }
  }, [videoLoopCount, goNextStep]);

  const handleProgressRateTimer = useCallback(
    (duration: number) => {
      // given the duration we want to set the interval to update the progress rate until the video is finished loading
      const stepInterval = (100 * PROGRESS_RATE_UPDATE_INTERVAL) / duration;
      if (progressRateTimer.current) {
        clearInterval(progressRateTimer.current);
      }
      progressRateTimer.current = setInterval(() => {
        incrementProgressRate(stepInterval);
      }, PROGRESS_RATE_UPDATE_INTERVAL);
    },
    [incrementProgressRate]
  );

  useEffect(() => {
    return () => clearInterval(progressRateTimer.current);
  }, []);

  // we are preloading the video and getting their time durations to use in the timer
  useEffect(() => {
    carouselSteps.forEach(async (step) => {
      try {
        const response = await fetch(step.video);
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);
        setVideoCache((prev) => ({
          ...prev,
          [step.name]: objectUrl,
        }));
        const video = document.createElement('video');
        video.preload = 'auto'; // Force preloading

        // Listen for metadata to get duration
        video.onloadedmetadata = () => {
          const duration = video.duration;
          setVideoDurations((prev) => ({
            ...prev,
            [step.name]: duration * 1000,
          }));
        };
        video.src = objectUrl;
        video.load();
      } catch (error) {
        console.error('error loading video', error);
      }
    });
  }, [carouselSteps]);

  useEffect(() => {
    const setUpVideoHeight = () => {
      if (videoRef.current) {
        setVideoWidth(videoRef.current.clientWidth);
      }
    };
    setUpVideoHeight();
    window.addEventListener('resize', setUpVideoHeight);
    return () => window.removeEventListener('resize', setUpVideoHeight);
  }, []);

  useEffect(() => {
    // active index can change from this component timer running out or from user active click on the parent component
    // when the active index changes,this component is in charge of resetting the timer and the progress rate, then updating the video
    // we want there to be a smooth transition between the videos so a 500ms delay
    if (Object.keys(videoDurations).length === carouselSteps?.length) {
      setVideoLoopCount(0);
      clearProgressRate();
      handleProgressRateTimer(
        videoDurations[currentStep.name] * MAX_VIDEO_LOOP_COUNT +
          TRANSITION_DURATION
      );
      videoRef.current?.play();
    }
  }, [
    activeStep,
    carouselSteps?.length,
    clearProgressRate,
    currentStep.name,
    handleProgressRateTimer,
    videoDurations,
  ]);

  return (
    <div
      className={style.videoContainer}
      style={{
        // this is the aspect ratio of the video, we need the height set here so that the video doesnt flicker when transitioning
        height: videoWidth * (720 / 1760),
      }}
    >
      <video
        ref={videoRef}
        className={`${style.video} ${
          isTransitioning ? style.inTransition : ''
        }`}
        autoPlay={false}
        loop={false}
        muted
        playsInline
        // poster={step.image}
        controls={false}
        src={
          videoCache && videoCache[currentStep.name]
            ? videoCache[currentStep.name]
            : currentStep?.video
        }
        onEnded={onVideoEnded}
      />
    </div>
  );
};
