import React, { ChangeEvent, DragEvent, FC, useCallback } from 'react';
import style from './style.module.less';
import { useTranslation } from 'react-i18next';
import { FileInputWrapper } from '../../../file-input';
import { FileChatList } from '../../../file-chat-list';
import { MobileFileInput } from './mobile-file-input';

interface Props {
  files: globalThis.File[];
  addFiles: (value: globalThis.File[]) => void;
  removeFile: (index: number) => void;
  isFileUploading: (fileName: string) => boolean;
  disabled?: boolean;
}

export const FormFileInput: FC<Props> = (props) => {
  const { files, addFiles, removeFile, isFileUploading, disabled } = props;

  const [isDragging, setIsDragging] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newFiles = Array.from(e.target.files);
      if (newFiles?.length) {
        addFiles(newFiles);
      }
    },
    [addFiles]
  );

  const onDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const newFiles = Array.from(e.dataTransfer.files);
    if (newFiles?.length && !disabled) {
      addFiles(newFiles);
    }
  };

  const onDragStart = useCallback(() => {
    setIsDragging(true);
  }, []);
  const onDragEnd = useCallback(() => {
    setIsDragging(false);
  }, []);

  const onDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <>
      <div
        className={`${style.fileInputWrapper} ${
          isDragging ? style.dragging : ''
        }`}
        onDrop={onDrop}
        onDragOver={onDrag}
        onDragEnter={onDragStart}
        onDragLeave={onDragEnd}
      >
        <FileInputWrapper
          ref={fileInputRef}
          onChangeHandler={onChangeHandler}
          disabled={disabled}
        />

        {files?.length ? (
          <div className={style.desktopWrapper}>
            <FileChatList
              files={files}
              removeFile={removeFile}
              className={files.length ? style.fileList : ''}
              isFileUploading={isFileUploading}
            />
          </div>
        ) : null}
        <div
          className={`${style.filename} ${
            files?.length ? style.hideDesktop : ''
          } ${disabled ? style.disabled : ''}`}
        >
          {t('prematter.choose-file')}
        </div>
        <div
          className={`${style.browseButton} ${disabled ? style.disabled : ''}`}
          onClick={() => fileInputRef.current?.click()}
        >
          {t('prematter.browse')}
        </div>
      </div>
      {files?.length ? (
        <div className={style.mobileList}>
          <div className={style.listWrapper}>
            {files.map((file, index) => (
              <MobileFileInput
                key={`${file.name}-${index}`}
                file={file}
                removeFile={() => removeFile(index)}
                disabled={isFileUploading(file.name)}
              />
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};
