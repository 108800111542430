import React, { FC } from 'react';
import style from './style.module.less';
import CrossIcon from '../../assets/icons/cross.svg?react';

interface Props {
  isMenuOpen?: boolean;
  closeMenu: () => void;
}

export const CloseIconComponent: FC<Props> = (props) => {
  const { closeMenu, isMenuOpen } = props;

  return (
    <div
      className={`${style.close} ${isMenuOpen ? style.show : ''}`}
      onClick={closeMenu}
    >
      <CrossIcon className={style.cross} />
    </div>
  );
};
