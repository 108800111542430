import React from 'react';
import style from './style.module.less';

interface Props {
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
}

export const Wrapper: React.FC<Props> = (props) => {
  const { children, className, contentClassName } = props;
  return (
    <div className={`${style.wrapper} ${className ? className : ''}`}>
      <div className={`${style.content} ${contentClassName ?? ''}`}>
        {children}
      </div>
    </div>
  );
};
