/* eslint-disable @stylistic/js/max-len */
import React, { FC, createRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import style from './style.module.less';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import CloseIcon from '../../assets/icons/cross-circle.svg?react';
import WarningIcon from '../../assets/icons/warning-circle.svg?react';

export interface LoginModalRef {
  closeModal: () => void;
  openModal: () => void;
}

interface Props {
  isOpen?: boolean;
  close: () => void;
}

export const CantVerifyModalComponent: FC<Props> = (props) => {
  const { close, isOpen } = props;
  const { t } = useTranslation();

  const modalRef = createRef<HTMLDivElement>();

  useOnClickOutside(modalRef, close);

  useEffect(() => {
    // stop scrolling when modal is open
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  const body = useMemo(() => {
    return (
      <div className={style.modalWrapper}>
        <div className={style.modalBody} ref={modalRef}>
          <div className={style.headerWrapper}>
            <img src={'/standard-bg.jpg'} className={style.imageBg} />
            <div className={style.closeIconContainer} onClick={close}>
              <CloseIcon className={style.closeIcon} />
            </div>
            <div className={style.imageBodyContainer}>
              <div className={style.shieldBubbleContainer}>
                <WarningIcon className={style.shieldBubble} />
              </div>
              <div className={style.imageBodyText}>
                {t('verify.cant-verify')}
              </div>
              <div className={style.imageBodyDescription}>
                {t('verify.cant-verify-description')}
              </div>
            </div>
          </div>
          <div className={`${style.section} ${style.firstSection}`}>
            <div className={style.descriptionContainer}>
              <div className={style.description}>
                <div className={style.descriptionTitle}>
                  {t('verify.cant-verify-reason.relevance.title')}
                </div>
                <div className={style.descriptionBody}>
                  {t('verify.cant-verify-reason.relevance.description')}
                </div>
              </div>
              <div className={style.description}>
                <div className={style.descriptionTitle}>
                  {t('verify.cant-verify-reason.context.title')}
                </div>
                <div className={style.descriptionBody}>
                  {t('verify.cant-verify-reason.context.description')}
                </div>
              </div>
              <div className={style.description}>
                <div className={style.descriptionTitle}>
                  {t('verify.cant-verify-reason.privacy.title')}
                </div>
                <div className={style.descriptionBody}>
                  {t('verify.cant-verify-reason.privacy.description')}
                </div>
              </div>
            </div>
          </div>
          <div className={style.section}>
            <div className={style.buttonWrapper}>
              <button className={style.button} onClick={close}>
                {t('confirm.close')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }, [close, modalRef, t]);

  return createPortal(isOpen ? body : <></>, document.body);
};
