import React from 'react';
import style from './style.module.less';
import LoadingSvg from '../../assets/icons/loading.svg?react';

interface Props {
  size?: number;
}
export const ProcessingSpinner: React.FC<Props> = (props) => {
  const { size } = props;
  const width = size || 56;
  const height = size || 56;

  return (
    <div className={style.loadingSpinner} style={{ width, height }}>
      <LoadingSvg className={style.spinner} />
    </div>
  );
};
