import React, { CSSProperties, FC, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';
import ArrowRightIcon from '../../assets/icons/arrow-right.svg?react';

interface Props {
  backgroundColour?: string;
  buttonColour?: string; // this needs to have opacity of 0.5
  lightMode?: boolean;

  classNames?: {
    buttonWrapper?: string;
    buttonText?: string;
    buttonArrow?: string;
    buttonArrowIcon?: string;
  };
}

const DEFAULT_BACKGROUND_COLOUR = '#F0EFEC';
const DEFAULT_BUTTON_COLOUR = '#0E5FE3';

export const NeedHelpCard: FC<Props> = (props) => {
  const { backgroundColour, buttonColour, classNames, lightMode } = props;
  const { t } = useTranslation();

  return (
    <div className={style.container}>
      <div className={style.card}>
        <div className={style.textWrapper}>
          <div className={style.title}>{t('library.need-help')}</div>
          <div className={style.description}>
            {t('library.ask-legal-question')}
          </div>
        </div>
        <div
          className={style.imageWrapper}
          style={
            {
              '--need-help-full-colour': buttonColour ?? DEFAULT_BUTTON_COLOUR,
            } as CSSProperties
          }
        >
          <Link
            to={'/chat'}
            state={{ newCase: true, message: undefined }}
            className={`${style.buttonWrapper} 
            ${lightMode ? style.lightMode : ''}
            ${classNames?.buttonWrapper ?? ''}`}
          >
            <div className={`${style.buttonText} ${classNames?.buttonText}`}>
              {t('library.start-case')}
            </div>
            <div className={`${style.buttonArrow} ${classNames?.buttonArrow}`}>
              {/* the duplicate is used for hover animation */}
              <div className={style.arrowIconWrapperHover}>
                <ArrowRightIcon
                  className={`${style.arrowIcon} ${
                    classNames?.buttonArrowIcon
                  }`}
                />
              </div>
              <div className={style.arrowIconWrapper}>
                <ArrowRightIcon
                  className={`${style.arrowIcon} ${
                    classNames?.buttonArrowIcon
                  }`}
                />
              </div>
            </div>
          </Link>
          <div className={style.backgroundColour} />
          <div
            className={style.backgroundSquare}
            style={{
              backgroundColor: backgroundColour ?? DEFAULT_BACKGROUND_COLOUR,
            }}
          />
        </div>
      </div>
    </div>
  );
};
