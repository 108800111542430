import React from 'react';
import styles from './style.module.less';
import { Button } from '../button';

interface Props {
  image: {
    src: string;
    icon: React.ReactNode;
    label: string;
  };
  title: string;
  features: string[];
  button?: {
    label: string;
    onClick: () => void;
  };
  link?: {
    label: string;
    onClick: () => void;
  };
  complete?: boolean;
  withProgress?: boolean;
  last?: boolean;
  next?: boolean;
  className?: string;
  rounded?: boolean;
  style?: React.CSSProperties;
}

export const NextStepCard: React.FC<Props> = (props) => {
  const {
    image,
    title,
    features,
    button,
    link,
    complete,
    withProgress,
    last,
    next,
    className,
    rounded = false,
    style,
  } = props;

  return (
    <div
      className={`${styles.nextStepCardWrapper} ${
        withProgress ? styles.withProgress : ''
      }
      ${complete ? styles.complete : ''}
      ${next ? styles.next : ''}
      ${className || ''}
      `}
    >
      {withProgress && (
        <div
          className={`${styles.nextStepProgress} ${
            last ? styles.noBackground : ''
          }`}
        >
          <div className={styles.progressCircle} />
        </div>
      )}
      <div
        className={`${styles.nextStepCard} ${complete ? styles.complete : ''} ${
          rounded ? styles.rounded : ''
        }`}
      >
        <div className={styles.nextStepCardImage}>
          <img src={image.src} alt='Next Step' />
          <div className={styles.iconWrapper}>
            <div className={styles.icon}>{image.icon}</div>
            <div className={styles.title}>{image.label}</div>
          </div>
        </div>
        <div
          className={styles.nextStepCardContent}
          style={{ ...style, minHeight: link ? '188px' : undefined }}
        >
          <div className={styles.nextStepCardText}>
            <div className={styles.nextStepCardTitle}>{title}</div>
            <div className={styles.nextStepCardFeatures}>
              {features && features.join(' • ')}
            </div>
          </div>
          {button || link ? (
            <div className={styles.nextStepCardActions}>
              {button && (
                <Button
                  onClick={button.onClick}
                  label={button.label}
                  className={styles.button}
                />
              )}
              {link && (
                <div className={styles.link} onClick={link.onClick}>
                  {link.label}
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export const NextStepCards: React.FC<{ cards: Props[] }> = ({ cards }) => {
  const containsALink = cards?.some((card) => card.link);
  return (
    <div className={styles.nextStepCardsWrapper}>
      {cards.map((card, index) => (
        <NextStepCard
          key={index}
          {...card}
          withProgress={true}
          last={index === cards.length - 1}
          next={!card.complete && cards[index - 1].complete}
          style={{
            ...card.style,
            minHeight: containsALink ? '188px' : undefined,
          }}
        />
      ))}
    </div>
  );
};

export const EmptyNextStepCard: React.FC<{
  title?: string;
  progress?: number;
  children: React.ReactNode;
  style?: React.CSSProperties;
}> = ({ title, progress, children, style }) => {
  return (
    <div className={styles.emptyNextStepCard} style={style}>
      {title && <div className={styles.emptyNextStepCardTitle}>{title}</div>}
      {/* {typeof progress !== 'undefined' && (
        <div className={styles.progressBarWrapper}>
          <FormProgressBar value={progress} />
        </div>
      )} */}
      <div className={styles.emptyNextStepCardContent}>{children}</div>
    </div>
  );
};
