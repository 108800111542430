/* eslint-disable @stylistic/js/max-len */
import React, { useMemo, useCallback, useRef } from 'react';
import { AiLawyer, Message, MessageType, File } from '@law-connect/types';
import styles from './style.module.less';
import { FilePreview } from '../../../../components/chat-files/file';
import nl2br from 'react-br';
import { AiLawyerSelector } from '@law-connect/react-components';
import { aiLawyerImages } from '../../../../assets/lawyers/ai-lawyer-images';
import { formatMediumDateTime } from '../../../../utils/format-date';

// const FileRender = (file: File) => {
//   return (
//     <div className={styles.fileWrapper}>
//       <div className={styles.fileIcon}>
//         <img src={file.url} alt={file.name} />
//       </div>
//       <div className={styles.fileName}>{file.name}</div>
//     </div>
//   );
// }

interface Props {
  type: 'sender' | 'receiver';
  message: Message;
  children?: React.ReactNode;
  aiLawyer?: AiLawyer;
  onAiLawyerChange?: (aiLawyer: AiLawyer) => void;
  headerContainerRef: React.RefObject<HTMLDivElement>;
  chatContainerRef?: React.RefObject<HTMLDivElement>;
  showMobileTimestamp?: boolean;
  setShowMobileTimestamp?: (state: boolean) => void;
  style?: string;
  readOnly?: boolean;
}

export const MessageRenderer: React.FC<Props> = (props) => {
  const {
    message,
    children,
    aiLawyer,
    onAiLawyerChange,
    headerContainerRef,
    chatContainerRef,
    showMobileTimestamp,
    setShowMobileTimestamp,
    style,
    readOnly,
  } = props;

  let content: React.ReactNode = null;

  const isFromSystem = message.from === 'lawyer';
  const mobileTimestampTimeout = useRef<NodeJS.Timeout | null>(null);

  const chatClassName = useMemo(
    () => `${isFromSystem ? styles.chatMessageSystem : styles.chatMessageUser}
    ${message?.questions ? styles['showTimestamp'] : ''}`,
    [isFromSystem, message?.questions]
  );

  const DateTime = useMemo(
    () => (
      <div
        className={`${styles['timestampContainer']} 
        ${isFromSystem ? styles['systemMessage'] : ''} 
        ${showMobileTimestamp ? styles['showTimestamp'] : ''}
        ${message?.questions ? styles['confirmation'] : ''}
        ${message.id === 'complete' ? styles['complete'] : ''}
        `}
      >
        {formatMediumDateTime(message.createdAt || Date.now())}
      </div>
    ),
    [isFromSystem, showMobileTimestamp, message?.questions, message.id, message.createdAt]
  );

  const toggleMobileTimestamp = useCallback(() => {
    if (setShowMobileTimestamp) {
      if (showMobileTimestamp) {
        setShowMobileTimestamp(false);
      } else {
        if (mobileTimestampTimeout.current) {
          clearTimeout(mobileTimestampTimeout.current);
        }
        mobileTimestampTimeout.current = setTimeout(() => {
          setShowMobileTimestamp(false);
          mobileTimestampTimeout.current = null;
        }, 2000);
        setShowMobileTimestamp(true);
      }
    }
  }, [showMobileTimestamp, setShowMobileTimestamp]);

  // Chat = "chat",
  // Location = "location",
  // Confirmation = "confirmation",
  // Permission = "permission",
  // ClientIntakeForm = "client-intake-form"

  switch (message.type) {
    case MessageType.Chat:
    case MessageType.Location:
    case MessageType.Confirmation:
      content =
        message.content.length > 0 ? nl2br(message.content.toString()) : null;
      break;
    default: {
      content = null;
    }
  }

  return (
    <div
      className={`${styles.messageWrapper}
      ${styles[props.type]} ${style || ''}
      ${!isFromSystem ? styles.userMessage : ''}
      ${showMobileTimestamp ? styles.showTimestamp : ''}
      `}
      onClick={message.id == 'complete' ? toggleMobileTimestamp : undefined}
    >
      {content ? (
        <>
          {isFromSystem ? (
            <>
              <div className={styles.chatMessageSystemWrapper}>
                <div className={styles.assistantImage}>
                  <AiLawyerSelector
                    isSelectorEnabled={!readOnly}
                    selectedAiLawyer={aiLawyer}
                    aiLawyers={aiLawyerImages}
                    onAiLawyerChange={onAiLawyerChange}
                    headerContainerRef={headerContainerRef}
                    chatContainerRef={chatContainerRef}
                  />
                </div>
                <div className={chatClassName} onClick={toggleMobileTimestamp}>
                  <div className={styles.chatMessage}>{content}</div>
                  {message.id !== 'complete' ? DateTime : null}
                </div>
              </div>
              {children}
              {message.id === 'complete' ? DateTime : null}
            </>
          ) : (
            <>
              <div className={chatClassName} onClick={toggleMobileTimestamp}>
                <div className={styles.chatMessage}>{content}</div>
              </div>
              {children}
              {!message?.questions ? DateTime : null}
            </>
          )}
        </>
      ) : children ? (
        children
      ) : null}
    </div>
  );
};
