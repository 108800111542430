import React, { useCallback } from 'react';
import { BackHeader } from '../../components/back-header';
import { useTranslation } from 'react-i18next';
import { Prematter } from '@law-connect/types';
import { confirmModal } from '../../components/confirm-modal';
import { useAppDispatch } from '../../redux/hooks';
import { actions } from '../../redux/slices';
import BinIcon from '../../assets/icons/bin.svg?react';
import { useDownloadReport } from '../../components/use-download-report';
import { useNavigate } from 'react-router-dom';

interface Props {
  prematter: Prematter;
}

// IMPORTANT: the delete case redirect is handled by the prematter/index
export const PrematterHeaderBar: React.FC<Props> = (props) => {
  const { prematter } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const DownloadHeader = useDownloadReport()(prematter.id);

  const deleteChat = useCallback(() => {
    dispatch(actions.session.delete());
    dispatch(actions.prematter.delete({ id: prematter.id }));
  }, [dispatch, prematter.id]);

  const deleteConfirmationModal = useCallback(() => {
    confirmModal({
      title: t('confirm.delete'),
      description: <>{t('account.delete-case-confirmation')}</>,
      action: {
        label: t('confirm.yes'),
        onClick: deleteChat,
      },
      small: true,
    });
  }, [deleteChat, t]);

  const backOverride = useCallback(() => {
    navigate('/account');
  }, [navigate]);

  // TODO: bug not sure why but when changing screens the ref is not set on mount
  return (
    <>
      <BackHeader
        items={[
          DownloadHeader,
          {
            label: t('confirm.delete'),
            onClick: deleteConfirmationModal,
            icon: <BinIcon />,
          },
        ]}
        backOverride={backOverride}
      />
    </>
  );
};
