import React, {
  useRef,
  useLayoutEffect,
  forwardRef,
  useImperativeHandle,
  RefObject,
  useEffect,
  useState,
  CSSProperties,
} from 'react';
import style from './style.module.less';
import {
  File as AMFile,
  LawyerConnection,
  Message,
  Lawyer,
} from '@law-connect/types';
import useStayScrolled from 'react-stay-scrolled';
import { ChatInput, InputRef } from '@law-connect/react-components';
import useResizeObserver from 'use-resize-observer';
import { MessageWrapper } from './message-wrapper';
import { useTranslation } from 'react-i18next';
import { FileUploading } from '../chat-history/uploading-files';
import { useMobile } from '../../hooks/use-is-mobile';

export interface ChatElementProps extends React.HTMLAttributes<HTMLDivElement> {
  messages: Message[];
  files: AMFile[];
  onSendMessage: (args: { text?: string; files?: File[] }) => void;
  isLoading: boolean;
  inputClassName?: string;
  connection: LawyerConnection;
  isSendingFiles: boolean;
  bottomOffset?: number;
  lawyers?: Lawyer[];
}

export interface ChatElementRef {
  inputRef: RefObject<HTMLDivElement> | null;
  chatRef: RefObject<HTMLDivElement> | null;
}

// todo: translate all
export const ChatElement = forwardRef<ChatElementRef, ChatElementProps>(
  (props, ref) => {
    const {
      onSendMessage,
      messages,
      isLoading,
      children,
      inputClassName,
      connection,
      files,
      isSendingFiles,
      bottomOffset = 0,
      lawyers,
      ...elementProps
    } = props;

    const messagesRef = useRef(null);
    const { stayScrolled } = useStayScrolled(messagesRef);
    const inputRef = useRef<InputRef>(null);
    const chatRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    // Purely for next step card styling purposes
    const isMobile = useMobile();

    // sticky = fixed to bottom of screen
    const [isSticky, setIsSticky] = useState(false);
    const [inputHeight, setInputHeight] = useState(0);
    const stickyInputRef = useRef<HTMLDivElement>(null);

    const { ...stickyInputRefBounds } = useResizeObserver({
      ref: stickyInputRef,
      box: 'border-box', // we want to include padding
    });

    const { ...inputRefBounds } = useResizeObserver({
      ref: inputRef.current?.inputContainerRef ?? null,
      box: 'border-box', // we want to include padding
    });

    useLayoutEffect(() => {
      stayScrolled();
    }, [messages, stayScrolled]);

    useImperativeHandle(ref, () => ({
      inputRef: inputRef.current?.inputContainerRef ?? null,
      chatRef: chatRef,
    }));

    useEffect(() => {
      // we want to find the root component and add a on drag over event to it
      const root = document.getElementById('root');
      const onDragOver = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
      };

      const onDrop = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        const newFiles = e.dataTransfer.files;
        if (newFiles && inputRef.current) {
          inputRef.current.addFiles(Array.from(newFiles));
        }
      };
      if (root) {
        root?.addEventListener('dragover', onDragOver);
        root?.addEventListener('drop', onDrop);
        return () => {
          root?.removeEventListener('dragover', onDragOver);
          root?.removeEventListener('drop', onDrop);
        };
      }
    }, []);

    useEffect(() => {
      if (inputRefBounds.height) {
        setInputHeight(bottomOffset + inputRefBounds.height);
      }
      else {
        setInputHeight(bottomOffset);
      }
    }, [bottomOffset, inputRefBounds]);

    // if the stickyInputRef is < 20px from the bottom of the screen
    // position it absolute
    useLayoutEffect(() => {
      const handleScroll = () => {
        if (!chatRef.current || !stickyInputRef.current) {
          return;
        }

        const chatRefRect = chatRef?.current?.getBoundingClientRect();

        // Bottom of the chatRefRect relative to the viewport
        const chatBottom = chatRefRect?.bottom + window.scrollY;

        // Bottom of the viewport relative to the document
        const bottomOfScroll =
          window.scrollY + window.innerHeight - bottomOffset;

        // Check if the bottom of the chatRefRect has entered the viewport
        const bottomOfChatHasEnteredViewport = chatBottom <= bottomOfScroll;

        if (bottomOfChatHasEnteredViewport && isSticky) {
          // console.log('The bottom of the chat has entered the viewport.');
          setIsSticky(false);
        } else if (!bottomOfChatHasEnteredViewport && !isSticky) {
          // console.log('The bottom of the chat has not entered the viewport.');
          setIsSticky(true);
        }
      };
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleScroll);

      handleScroll();
      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleScroll);
      };
    }, [bottomOffset, isSticky]);

    return (
      <div
        {...elementProps}
        className={`${style.chat} ${props.className || ''}`}
        ref={chatRef}
        style={{
          // Custom CSS variables for the chat messages
          '--chat-border-radius': '20px',
          '--chat-file-context-menu-border-radius': '20px',
          '--file-preview-background-color': 'rgba(236, 235, 232, 0.7)',
          '--file-preview-padding': '10px',
        } as CSSProperties}
      >
        <div ref={messagesRef} className={style.messages}
          style={{
            paddingBottom:
                !isMobile
                  ? `${inputRefBounds.height}px`
                  : `${inputHeight}px`,
          }}>
          {children}
          {messages.map((message, index) => {
            return (
              <MessageWrapper
                key={index}
                message={message}
                hoverDelay={200}
                connection={connection}
                messages={messages}
                files={files}
                lawyers={lawyers}
              />
            );
          })}

          <FileUploading filesLoading={isSendingFiles} />
          {isLoading && (
            <div className={`${style.message} ${style.lawyer}`}>typing..</div>
          )}
          {connection.endedAt || connection.deletedAt ? (
            <div className={`${style.conversationEnded}`}>
              {t('chat.conversation-ended')}
            </div>
          ) : null}
          <div
            ref={stickyInputRef}
            className={style.stickyInputWrapper}
            style={{
              position: isSticky ? 'fixed' : 'absolute',
              marginBottom: isSticky ? `${bottomOffset}px` : '0',
            }}
          >
            <ChatInput
              onSendMessage={onSendMessage}
              ref={inputRef}
              className={`${style.input} ${inputClassName}`}
              disabled={connection.endedAt || 
                connection.deletedAt ? true : false}
            />
          </div>
        </div>
      </div>
    );
  }
);
