import { configureStore } from '@reduxjs/toolkit';
import { reducers } from './slices';
import { setupListeners } from '@reduxjs/toolkit/query';
import websocketMiddleware from './middleware/websocket';
import lawyerMessagesMiddleware from './middleware/lawyer-messages';
import lawyerConnectionMiddleware from './middleware/lawyer-connection';

const store = configureStore({
  reducer: {
    ...reducers,
    // [websocketApi.reducerPath]: websocketApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(websocketMiddleware.middleware)
      .prepend(lawyerMessagesMiddleware.middleware)
      .prepend(lawyerConnectionMiddleware.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
setupListeners(store.dispatch);
