import { PayloadAction, createListenerMiddleware } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { actions } from '../slices';
import selectors from '../selectors';
import {
  Message,
  WebSocketMessage,
  WebSocketMessageType,
} from '@law-connect/types';
import { getLanguage } from '../../i18n';
import { security } from '../api/security';
import { t } from 'i18next';

const MAX_ERROR_COUNT = 10;
const SERVER_DELAY_TIME = 15000;
let ws: WebSocket = null;
let listeningTimer: NodeJS.Timeout = null;
let serverResponseTimer: NodeJS.Timeout = null;
let pingTimer: NodeJS.Timeout = null;
let botDetected = false;

interface InitWebsocketArgs {
  url: string;
  token?: string;
  onError?: (event: ErrorEvent) => void;
  onOpen?: () => void;
  onClose?: () => void;
  keepAlive?: boolean;
  onMessage: (event: MessageEvent) => void;
}
const initWebsocket = (args: InitWebsocketArgs) => {
  const { url, token, keepAlive, onError, onOpen, onClose, onMessage } = args;
  if (ws) {
    ws.close();
  }

  ws = new WebSocket(url, token);

  ws.onerror = (event: ErrorEvent) => {
    pingTimer && clearInterval(pingTimer);

    onError?.(event);

    // Re-connect on an error
    initWebsocket(args);
  };

  ws.onclose = () => {
    pingTimer && clearInterval(pingTimer);
    onClose?.();
  };

  ws.onopen = () => {
    if (keepAlive) {
      const sendPing = () => {
        if (ws.readyState === ws.OPEN) {
          const ping: WebSocketMessage = {
            from: 'system',
            message: '',
            type: WebSocketMessageType.Ping,
          };
          ws.send(JSON.stringify(ping));
        }
      };
      pingTimer = setInterval(sendPing, 30000);
      sendPing();
    }

    onOpen?.();
  };

  ws.onmessage = onMessage;
};

const listenerMiddleware = createListenerMiddleware();

// listening to init websocket connection
listenerMiddleware.startListening({
  actionCreator: actions.websocket.connect,
  effect: async (
    action: PayloadAction<{
      prematterId?: string;
      captcha?: string;
      reconnect?: boolean;
      errorCount?: number;
    }>,
    listenerApi
  ) => {
    // Run whatever additional side-effect-y logic you want here
    const token = await security.getAccessToken();
    if (!ws || ws.readyState !== ws.OPEN) {
      let state = listenerApi.getState() as RootState;
      let sessionId: string = selectors.session.getSessionId()(state);

      let isDeletingSession = selectors.websocket.isDeletingSession()(state);
      while (isDeletingSession || !sessionId) {
        await new Promise((resolve) => setTimeout(resolve, 100));
        state = listenerApi.getState() as RootState;
        isDeletingSession = selectors.websocket.isDeletingSession()(state);
        sessionId = selectors.session.getSessionId()(state);
      }

      if (!sessionId) {
        if (
          action.payload.errorCount &&
          action.payload.errorCount > MAX_ERROR_COUNT
        ) {
          listenerApi.dispatch(
            actions.websocket.connectError('Failed to get a session id')
          );
          return;
        }
        // delay and resend the connect action
        setTimeout(() => {
          listenerApi.dispatch(
            actions.websocket.connect({
              ...action.payload,
              errorCount: action.payload.errorCount
                ? action.payload.errorCount + 1
                : 1,
            })
          );
        }, 1000);
        return;
      }
      const queryStringPrematter = action.payload.prematterId
        ? `&prematterId=${action.payload.prematterId}`
        : '';

      let url = `ws${
        !window.location.host ||
        window.location.host.includes('localhost') ||
        window.location.protocol === 'http:'
          ? ''
          : 's'
      }://${window.location.host || 'localhost'}/websocket?timezone=${
        Intl.DateTimeFormat().resolvedOptions().timeZone
        // eslint-disable-next-line @stylistic/js/max-len
      }&language=${getLanguage()}&sessionId=${sessionId}${queryStringPrematter}`;

      if (action.payload.captcha) {
        url += `&captcha=${action.payload.captcha}`;
      }

      // On websocket connection error
      const onError = (event: ErrorEvent) => {
        listenerApi.dispatch(actions.websocket.connectError(event.message));
      };

      const onOpen = () => {
        listenerApi.dispatch(actions.websocket.connectSuccess());
      };

      const onMessage = (event: MessageEvent) => {
        try {
          const data: WebSocketMessage = JSON.parse(event.data);
          if (
            data.from === 'system' &&
            (data.type === WebSocketMessageType.Chat ||
              data.type === WebSocketMessageType.Location ||
              data.type === WebSocketMessageType.Confirmation ||
              data.type === WebSocketMessageType.StateChange)
          ) {
            // clear serverResponseTimer
            if (serverResponseTimer) {
              clearTimeout(serverResponseTimer);
              serverResponseTimer = null;
            }
          }

          if (data.type === WebSocketMessageType.BotDetected) {
            botDetected = true;
          }
          if (data.type === WebSocketMessageType.Ready) {
            listenerApi.dispatch(actions.session.ready());
            botDetected = false;
            const prematter = (listenerApi.getState() as RootState).session
              .prematter;
            if (!prematter.id && prematter?.messages.length === 0) {
              listenerApi.dispatch(
                actions.websocket.sendMessage({
                  id: 'system',
                  type: WebSocketMessageType.Chat,
                  from: 'system',
                  message: t('chat.helloMessage'),
                  timestamp: Date.now(),
                })
              );
            }
          } else if (data.type === WebSocketMessageType.Context) {
            try {
              const context = JSON.parse(data.message);
              listenerApi.dispatch(actions.session.setContext(context));
            } catch (error) {
              console.error('Error parsing JSON context', error);
            }
          } else if (data.type === WebSocketMessageType.Processing) {
            listenerApi.dispatch(actions.session.setWaitingForServer());
          } else if (data.type === WebSocketMessageType.FAQ) {
            try {
              const faq = JSON.parse(data.message);
              listenerApi.dispatch(actions.session.setQuestions(faq));
            } catch (error) {
              console.error('Error parsing JSON faq', error);
            }
            listenerApi.dispatch(actions.session.fetch());
            listenerApi.dispatch(actions.session.ready());
            ws.close();
          } else if (data.type === WebSocketMessageType.StateChange) {
            listenerApi.dispatch(actions.session.updateState(data.message));
          } else if (data.type === WebSocketMessageType.Ping) {
            // replying to PINGs
            const pong: WebSocketMessage = {
              from: 'system',
              message: '',
              type: WebSocketMessageType.Pong,
            };
            ws.send(JSON.stringify(pong));
            return;
          } else if (data.type === WebSocketMessageType.Pong) {
            // ignoring PONGs
            return;
          } else {
            listenerApi.dispatch(actions.session.addMessage(data));
          }

          const innerState = listenerApi.getState() as RootState;
          const isSendingMessage =
            selectors.websocket.isSendingMessage()(innerState);
          if (isSendingMessage) {
            listenerApi.dispatch(actions.websocket.sendMessageSuccess());
          }
        } catch (error) {
          console.error('Error parsing JSON data', error);
        }
      };

      initWebsocket({
        url,
        token,
        onError,
        onOpen,
        keepAlive: true,
        onMessage,
      });
    } else {
      listenerApi.dispatch(
        actions.websocket.connectError('WebSocket is already open')
      );
    }
  },
});

// listening to send message to websocket
listenerMiddleware.startListening({
  actionCreator: actions.websocket.sendMessage,
  effect: async (action, listenerApi) => {
    const dispatchAction = () => {
      action.payload.errorCount = undefined;
      ws.send(JSON.stringify(action.payload));
      listenerApi.dispatch(
        actions.session.addMessage(
          action.payload as unknown as WebSocketMessage
        )
      );
      if (!serverResponseTimer) {
        serverResponseTimer = setTimeout(() => {
          listenerApi.dispatch(
            actions.websocket.sendMessageError(
              'Failed to get a response from server'
            )
          );
          serverResponseTimer = null;
        }, SERVER_DELAY_TIME);
      }
    };
    const state = listenerApi.getState() as RootState;
    const isWsConnected = selectors.websocket.isSetUp()(state);
    // Run whatever additional side-effect-y logic you want here
    if (!ws || ws.readyState !== ws.OPEN || !isWsConnected) {
      if (
        (!action.payload.errorCount ||
          action.payload.errorCount < MAX_ERROR_COUNT) &&
        !botDetected
      ) {
        if (listeningTimer) {
          clearTimeout(listeningTimer);
        }
        listeningTimer = setTimeout(() => {
          listenerApi.dispatch(
            actions.websocket.sendMessage({
              ...action.payload,
              errorCount: action.payload.errorCount
                ? action.payload.errorCount + 1
                : 1,
            })
          );
          listeningTimer = null;
        }, 1000 * (action.payload.errorCount ?? 1));
      } else {
        listenerApi.dispatch(
          actions.websocket.sendMessageError('Failed to send message')
        );
      }
    } else {
      dispatchAction();
    }
  },
});

// we want to delete the session and disconnect the websocket
listenerMiddleware.startListening({
  actionCreator: actions.websocket.deleteSession,
  effect: async (action, listenerApi) => {
    if (ws && ws.readyState === ws.OPEN) {
      ws.onclose = () => {
        pingTimer && clearInterval(pingTimer);
        listenerApi.dispatch(actions.websocket.deleteSessionSuccess());
      };
      ws.close();
    } else {
      listenerApi.dispatch(actions.websocket.deleteSessionSuccess());
    }
  },
});

export default listenerMiddleware;
