import React, { FC, useCallback, useRef } from 'react';
import MinusSvg from '../../assets/icons/minus.svg?react';
import style from './style.module.less';
import { useTranslation } from 'react-i18next';
const TIMEOUT = 1300;

interface Props {
  deleteQuestion: () => void;
  isPending: boolean;
  setIsPending: (value: boolean) => void;
}

const DeleteQuestionComponent: FC<Props> = (props) => {
  const { deleteQuestion, isPending, setIsPending } = props;
  const { t } = useTranslation();

  const timer = useRef<NodeJS.Timeout | null>(null);

  const pendingDeleteQuestion = useCallback(() => {
    setIsPending(true);
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      setIsPending(false);
      timer.current = null;
    }, TIMEOUT);
  }, [setIsPending]);

  const confirmDeleteQuestion = useCallback(() => {
    if (isPending) {
      deleteQuestion();
    }
  }, [isPending, deleteQuestion]);

  return (
    <div className={style.deleteWrapper}>
      <button onClick={pendingDeleteQuestion} className={style.iconContainer}>
        <MinusSvg className={style.icon} />
      </button>
      <button
        onClick={confirmDeleteQuestion}
        className={`${style.deleteConfirm} ${isPending ? style.show : ''}`}
      >
        {t('confirm.delete')}
      </button>
    </div>
  );
};

export default DeleteQuestionComponent;
