import React, {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import style from './style.module.less';
import LoadingIcon from '../../assets/icons/loading.svg?react';
import TickCircleSvg from '../../assets/icons/tick-circle.svg?react';
import WarningCircleIcon from '../../assets/icons/warning-circle.svg?react';
import { useTranslation } from 'react-i18next';

interface Props {
  buttonClassName?: string;
  className?: string;
  disabled?: boolean;
  error?: string;
  errorClassName?: string;
  inputClassName?: string;
  pending: boolean;
  question: string;
  show: boolean;
  language?: string;

  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  submit: () => void;
}

export const FAQAddQuestion: FC<Props> = (props) => {
  const {
    buttonClassName,
    className,
    disabled,
    error,
    errorClassName,
    inputClassName,
    pending,
    question,
    show,
    language,
    onChange,
    submit,
  } = props;

  const { t } = useTranslation(undefined, { lng: language ?? 'en' });
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (show) {
      inputRef.current?.focus();
    }
  }, [show]);

  const onKeyDownEnter: KeyboardEventHandler<HTMLTextAreaElement> = useCallback(
    (e) => {
      if (e.key === 'Enter' && e.shiftKey === false) {
        submit();
      }
    },
    [submit]
  );

  return (
    show && (
      <div
        className={`${style.container} ${pending ? style.disabled : ''} ${
          className ?? ''
        }`}
      >
        {pending && (
          <div className={style.loadingContainer}>
            <LoadingIcon className={style.loadingIcon} />
          </div>
        )}
        {/* <input
          ref={inputRef}
          type='text'
          value={question}
          onChange={onChange}
          className={`${style.addQuestionInput} ${inputClassName ?? ''}`}
          placeholder={t('chat.add-question-placeholder')}
          onKeyDown={onKeyDownEnter}
          disabled={pending}
        /> */}
        <textarea
          ref={inputRef}
          value={question}
          onChange={onChange}
          className={`${style.addQuestionInput} ${inputClassName ?? ''}`}
          placeholder={t('chat.add-question-placeholder')}
          onKeyDown={onKeyDownEnter}
          disabled={pending}
        />
        <button
          disabled={pending || !question.length}
          onClick={submit}
          className={`${style.iconContainer} ${buttonClassName}`}
        >
          <TickCircleSvg className={style.icon} />
        </button>
        {error && !disabled ? (
          <div className={`${style.errorContainer} ${errorClassName ?? ''}`}>
            <WarningCircleIcon className={style.errorIcon} />
            <div className={style.errorText}>{error}</div>
          </div>
        ) : null}
      </div>
    )
  );
};
