import { useEffect } from 'react';

const useStopScroll = (stopScroll: boolean) => {
  useEffect(() => {
    // stop scrolling when modal is open
    if (stopScroll) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [stopScroll]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
};

export default useStopScroll;
