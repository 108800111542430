import { createSlice } from '@reduxjs/toolkit';
import { actions } from '.';
import { AboutStats } from '../../types/about';

interface AboutState {
  pending: {
    fetch?: boolean;
  };
  errors: {
    fetch?: string | null;
  };
  data: AboutStats;
}

const aboutState: AboutState = {
  pending: {},
  errors: {},
  data: null,
};

const aboutSlice = createSlice({
  name: 'about',
  initialState: aboutState,
  // you cant have a reducer name match the thunk action
  reducers: {},
  extraReducers: (builder) => {
    // fetch
    builder.addCase(actions.about.fetchStats.pending, (state) => {
      state.pending.fetch = true;
      state.errors.fetch = null;
    });
    builder.addCase(actions.about.fetchStats.fulfilled, (state, action) => {
      state.pending.fetch = false;
      state.errors.fetch = null;
      state.data = action.payload;
    });
    builder.addCase(actions.about.fetchStats.rejected, (state, action) => {
      state.pending.fetch = false;
      state.errors.fetch = action.error.message;
    });
  },
});

// im not sure if this is good practice but it mimics what we used to do with dispatching actions
export const aboutActions = aboutSlice.actions;
export const aboutReducer = aboutSlice.reducer;
