import React, { useCallback, useEffect, useRef, useState } from 'react';
import style from './style.module.less';

import UserIcon from '../../assets/icons/user.svg?react';
import BriefcaseIcon from '../../assets/icons/briefcase.svg?react';
import ChatTickIcon from '../../assets/icons/chat-tick.svg?react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../redux/hooks';
import selectors from '../../redux/selectors';
import { useAppSelector } from '../../redux/hooks';
import { actions } from '../../redux/slices';

const ANIMATION_DELAY = 500;

export const AboutStatsContainer = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const isFetchingStats = useAppSelector(selectors.about.isFetchingStats());
  const stats = useAppSelector(selectors.about.getStats());
  const fetchStatsError = useAppSelector(selectors.about.getFetchStatsError());

  useEffect(() => {
    if (!isFetchingStats && !stats && !fetchStatsError) {
      dispatch(actions.about.fetchStats());
    }
  }, [dispatch, isFetchingStats, stats, fetchStatsError]);

  return (
    <div className={style.statsContainer}>
      <div className={style.statsWrapper}>
        <div className={style.statsCard}>
          <UserIcon className={style.statsIcon} />
          <div className={style.statsTitle}>
            {stats?.uniqueUserAccounts ? (
              <StepCounter target={stats.uniqueUserAccounts} />
            ) : (
              0
            )}
          </div>
          <div className={style.statsDescription}>
            {t('about.unqiue-user-accounts')}
          </div>
        </div>
        <div className={style.statsCard}>
          <BriefcaseIcon className={style.statsIcon} />
          <div className={style.statsTitle}>
            {stats?.casesCreated ? (
              <StepCounter
                target={stats.casesCreated}
                delay={ANIMATION_DELAY}
              />
            ) : (
              0
            )}
          </div>
          <div className={style.statsDescription}>
            {t('about.cases-created')}
          </div>
        </div>
        <div className={style.statsCard}>
          <ChatTickIcon className={style.statsIcon} />
          <div className={style.statsTitle}>
            {stats?.providedAnswers ? (
              <StepCounter
                target={stats.providedAnswers}
                delay={ANIMATION_DELAY * 2}
              />
            ) : (
              0
            )}
          </div>
          <div className={style.statsDescription}>
            {t('about.answers-verified')}
          </div>
        </div>
      </div>
    </div>
  );
};

const ANIMATION_DURATION = 1500;

// we assume that target is always provided
const StepCounter = (props: { target: number; delay?: number }) => {
  const { target, delay } = props;
  const [count, setCount] = useState(0);
  const [isInView, setIsInView] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const timer = useRef<NodeJS.Timeout | null>(null);

  // we also only want to start animation once div is in view
  const setUpTimer = useCallback(() => {
    const intervalTime = 16; // ~60 frames per second
    const totalSteps = ANIMATION_DURATION / intervalTime;
    let currentStep = 0;

    timer.current = setInterval(() => {
      currentStep++;
      // Normalize time between 0 and 1
      const t = currentStep / totalSteps;
      // Cubic ease-in-out formula
      const easedT = t * t * (3 - 2 * t);
      setCount(target * easedT);
      if (currentStep >= totalSteps) {
        setCount(target); // Ensure we end exactly at target
        clearInterval(timer.current);
      }
    }, intervalTime);
  }, [target]);

  useEffect(() => {
    const onceInView = () => {
      if (
        !isInView &&
        ref.current &&
        ref.current.getBoundingClientRect().top <= window.innerHeight * 0.8
      ) {
        setIsInView(true);
        if (delay) {
          setTimeout(() => {
            setUpTimer();
          }, delay);
        } else {
          setUpTimer();
        }
      }
    };
    onceInView();
    window.addEventListener('scroll', onceInView);
    return () => window.removeEventListener('scroll', onceInView);
  }, [isInView, setUpTimer, target, delay]);

  return <div ref={ref}>{Math.round(count)}</div>;
};
