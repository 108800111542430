import React from 'react';
import style from './style.module.less';

interface Props {
  title: string;
  children?: React.ReactNode;
  className?: string;
}

export const ContentTitle: React.FC<Props> = (props) => {
  const { title, children, className } = props;
  return (
    <div className={`${style.wrapper} ${className ? className : ''}`}>
      <div className={style.title}>{title}</div>
      <div className={style.actions}>{children}</div>
    </div>
  );
};

export const NoPaddingContentTitle: React.FC<Props> = (props) => {
  const { title, children, className } = props;
  return (
    <div
      className={`${style.wrapper} ${style.noPadding} ${
        className ? className : ''
      }`}
    >
      <div className={style.title}>{title}</div>
      <div className={style.actions}>{children}</div>
    </div>
  );
};
