import React, {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import style from './style.module.less';
import { LibraryCategory } from '../../../types/library';
import { Link } from 'react-router-dom';
import { convertToKebabCase } from '../../../utils/case-conversion';
import {
  getLibraryPathWithCountry,
} from '../../../utils/get-library-path-with-country';

interface Classnames {
  classNames?: {
    containerClassName?: string;
    cardClassName?: string;
    titleClassName?: string;
    descriptionClassName?: string;
    imageClassName?: string;
    iconClassName?: string;
    iconContainerClassName?: string;
  };
}

interface CardsProps extends Classnames {
  categories: LibraryCategory[];
}

export const CategoryCards: FC<CardsProps> = ({ categories, classNames }) => {
  const [titleHeight, setTitleHeight] = useState(0);

  const setTitleHeightWrapper = useCallback(
    (newHeight: number) => {
      if (newHeight > titleHeight) {
        setTitleHeight(newHeight);
      }
    },
    [titleHeight]
  );

  return (
    <div
      className={`${style.container} ${classNames?.containerClassName ?? ''}`}
    >
      {categories.map((category) => (
        <CategoryCard
          key={category.name}
          category={category}
          classNames={classNames}
          titleHeight={titleHeight}
          setTitleHeightWrapper={setTitleHeightWrapper}
        />
      ))}
    </div>
  );
};

interface CardProps extends Classnames {
  category: LibraryCategory;
  titleHeight?: number;
  setTitleHeightWrapper?: (newHeight: number) => void;
}

export const CategoryCard: FC<CardProps> = (props) => {
  const { category, classNames, titleHeight, setTitleHeightWrapper } = props;
  const imageRef = useRef<HTMLDivElement>(null);
  const [imageHeight, setImageHeight] = useState(0);
  const titleRef = useRef<HTMLDivElement>(null);

  const handleResize = useCallback(() => {
    // we want to set the height of the image container to 16:9 ratio of the width
    const width = imageRef.current?.clientWidth;
    setImageHeight(width ? (width * 9) / 16 : 0);
    if (titleRef.current && setTitleHeightWrapper) {
      setTitleHeightWrapper(titleRef.current.clientHeight);
    }
  }, [setTitleHeightWrapper]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return (
    <Link
      to={getLibraryPathWithCountry(`/${convertToKebabCase(category.name)}`)}
      className={`${style.categoryCard} ${classNames?.cardClassName ?? ''}`}
    >
      <div
        className={`${style.categoryTitle} ${classNames?.titleClassName ?? ''}`}
        style={titleHeight ? { minHeight: titleHeight } : {}}
        ref={titleRef}
      >
        {category.name}
      </div>
      <div className={style.contentContainer}>
        <div
          className={`${style.categoryImageContainer} ${
            classNames?.imageClassName ?? ''
          }`}
          ref={imageRef}
          style={{ height: imageHeight }}
        >
          <img src={category.previewImage} className={style.categoryImage} />
          <div
            className={`${style.categoryIconContainer} ${
              classNames?.iconContainerClassName ?? ''
            }`}
          >
            <div className={style.categoryIconHover}>
              <category.Icon
                className={`${style.icon} ${classNames?.iconClassName ?? ''}`}
              />
            </div>
            <div className={style.categoryIcon}>
              <category.Icon
                className={`${style.icon} ${classNames?.iconClassName ?? ''}`}
              />
            </div>
          </div>
        </div>
        <div
          className={`${style.categoryDescription} ${
            classNames?.descriptionClassName ?? ''
          }`}
        >
          {category.shortDescription}
        </div>
      </div>
    </Link>
  );
};
