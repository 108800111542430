import React, { ReactNode } from 'react';
import style from './style.module.less';

interface Props {
  items: ReactNode[];
  className?: string;
}

export const TableRow: React.FC<Props> = (props) => {
  const { className, items } = props;
  return (
    <div className={`${style.row} ${className ?? ''}`}>
      {items.map((item, index) => (
        <div key={index} className={style.cell}>
          {item}
        </div>
      ))}
    </div>
  );
};
