import React, { FC } from 'react';
import style from './style.module.less';
import FileIcon from '../../assets/icons/file.svg?react';
import CrossCircleIcon from '../../assets/icons/cross-circle.svg?react';
import LoadingSvg from '../../assets/icons/loading.svg?react';
import { File } from '@law-connect/types';

interface Props {
  files: globalThis.File[];
  addedFiles?: File[];
  className?: string;
  removeFile: (index: number) => void;
  isFileUploading?: (fileName: string) => boolean;
}

export const FileChatList: FC<Props> = (props) => {
  const { className, files, addedFiles, removeFile, isFileUploading } = props;

  return (
    <div className={`${style.container} ${className ?? ''}`}>
      {files.map((file, index) => {
        const disabled = isFileUploading ? isFileUploading(file.name) : null;
        return (
          <button
            key={index}
            className={`${style.fileItem} ${
              disabled === false ? style.uploaded : ''
            }`}
            onClick={() => removeFile(index)}
            disabled={disabled}
          >
            <div className={style.folderIconContainer}>
              <FileIcon className={style.folderIcon} />
            </div>
            <div className={style.fileName}>{file.name}</div>
            <CrossCircleIcon className={style.crossCircleIcon} />
            {disabled && (
              <div className={style.loading}>
                <LoadingSvg className={style.loadingIcon} />
              </div>
            )}
          </button>
        );
      })}
      {addedFiles &&
        addedFiles.map((file, index) => {
          return (
            <button
              key={index}
              className={`${style.fileItem} ${style.uploaded}`}
            >
              <div className={style.folderIconContainer}>
                <FileIcon className={style.folderIcon} />
              </div>
              <div className={style.fileName}>{file.name}</div>
            </button>
          );
        })}
    </div>
  );
};
