import { LibraryCategory } from '../../../types/library';
import criminalLaw from './categories/criminal_law';
import employmentLaw from './categories/employment_law';
import familyLaw from './categories/family_law';
import estatesAndProbates from './categories/estates_and_probates';
import realEstate from './categories/real_estate';

export const libraryAssets: LibraryCategory[] = [
  criminalLaw,
  employmentLaw,
  estatesAndProbates,
  familyLaw,
  realEstate,
];
