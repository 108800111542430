import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';

const STORE = 'about';
const aboutThunk = {
  fetchStats: createAsyncThunk(
    `${STORE}/fetchStats`,
    async (_args, { rejectWithValue }) => {
      try {
        const response = await api.about.fetchStats();
        return response;
      } catch (error) {
        console.error('failed to fetch stats', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
};

export default aboutThunk;
