import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TickCircle from '../../assets/icons/tick-circle.svg?react';
import WarningCircle from '../../assets/icons/warning-circle.svg?react';
import style from './style.module.less';
import { ToastIcon } from 'react-toastify/dist/types';
import { COOKIE_EVENT, COOKIE_ID } from '../cookie-consent';

export const ToastWrapper = () => {
  // we need the cookie height to bump the toast up by the cookie height
  const [cookieHeight, setCookieHeight] = useState<number>(0);

  const TickIcon: ToastIcon = (props) => {
    if(props.type === 'success') {
      return <TickCircle className={style.tickIcon} />;
    } else if(props.type === 'error') {
      return <WarningCircle className={style.tickIcon} />;
    } 
  };

  useEffect(() => {
    const cookieConsent = document.getElementById(COOKIE_ID);
    const cookieHeight = cookieConsent?.getBoundingClientRect()?.height ?? 0;
    if (cookieHeight) {
      setCookieHeight(cookieHeight);
    }
    // listen for event COOKIE_EVENT
    const handleEvent = () => {
      setCookieHeight(0);
    };
    document.addEventListener(COOKIE_EVENT, handleEvent);
    return () => {
      document.removeEventListener(COOKIE_EVENT, handleEvent);
    };
  }, []);
  
  return (
    <ToastContainer
      position='bottom-center'
      autoClose={4000}
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick={true}
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
      icon={TickIcon}
      closeButton={false}
      toastClassName={style.toast}
      bodyClassName={style.body}
      className={style.container}
      limit={3}
      style={{ '--cookie-height': cookieHeight } as React.CSSProperties}
    />
  );
};
