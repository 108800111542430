import React, { FC, InputHTMLAttributes } from 'react';
import style from './style.module.less';
import CrossCircleIcon from '../../assets/icons/cross-circle.svg?react';

interface Props extends InputHTMLAttributes<HTMLInputElement>{
  clear?: () => void;
}

export const SingularTextInput: FC<Props> = (props) => {
  const { value, placeholder, clear } = props;
  return (
    <div className={style.singularTextInput}>
      <input
        type='text'
        {...props}
        value={value}
        placeholder={placeholder}
      />
      {clear && (
        <CrossCircleIcon className={style.crossCircle} onClick={clear} />
      )}
    </div>
  );
};
