import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import { Wrapper } from '../../components/wrapper';
import { useNavigate } from 'react-router-dom';
import { CONTACT_US_LINK } from '../../constants';
import { Button } from '../../components/button';
import { ReportCard } from '../../components/report-card';
import { getAiLawyerImage, getRandomAiLawyer } from '../../utils/ai-lawyer';
import NotFound404 from '../../assets/icons/404.svg?react';

export const NotFoundComponent: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [lawyerPic, setLawyerPic] = useState<string | null>(null);

  const goHome = useCallback(() => navigate('/'), [navigate]);

  useEffect(() => {
    const randomPic = getAiLawyerImage({
      aiLawyer: getRandomAiLawyer(),
      angled: true,
    });
    setLawyerPic(randomPic);
  }, []);

  return (
    <>
      <Header className={style.headerContainer}/>
      <div className={style.notFoundContainer}>
        <NotFound404 className={style.notFoundSvg}  />
      </div>
      <Wrapper
        className={style.offsetContainer}
      >
        <ReportCard
          id={'notFound'}
          title={t('pages.not-found.header')}
          image={<img src={lawyerPic} alt='AI Lawyer' />}
          className={style.notFoundCard}
        >
          <div className={style.body}>
            <div className={style.textWrapper}>
              <div>{t('pages.not-found.title')}</div>
              <div className={style.lineBreak}>
                
              </div>
              <div>
                {t('pages.not-found.urlText')}{' '}
                {t('pages.not-found.text1')}{' '}
                <a href={CONTACT_US_LINK}>
                  {t('pages.contact-us').toLocaleLowerCase()}
                </a>
                {t('pages.not-found.text2')}
              </div>
            </div>
          </div>
        </ReportCard>
        <div className={style.homeButtonWrapper}>
          <Button
            label={t('pages.home')}
            labelClassName={style.homeButtonLabel}
            iconClassName={style.homeButtonIcon}
            onClick={goHome}
            className={style.homeButton}
          />
        </div>
      </Wrapper>
      <Footer />
    </>
  );
};
