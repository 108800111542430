import { LegalCaseState, Prematter } from '@law-connect/types';
import { TFunction } from 'i18next';
import { isPrematterComplete } from '../../utils/is-prematter-complete';

export const getTitle = (
  t: TFunction<'translation', undefined>,
  prematter: Prematter
) => {
  if (prematter.state === LegalCaseState.PreparingReport) {
    return `${prematter.name} (${t('prematter.is-generating')})`;
  } else if (isPrematterComplete(prematter.state, true)) {
    return prematter.name;
  } else {
    return `${prematter.name || t('prematter.draft')} (${t(
      'prematter.in-progress'
    )})`;
  }
};
