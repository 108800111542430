import React from 'react';
import style from './style.module.less';
import EditIcon from '../../assets/icons/edit.svg?react';
import { useTranslation } from 'react-i18next';
import DefaultUserImage from '../../assets/profile-no-img.png';

interface Props {
  profilePicture?: string;
  account: string;
  children?: React.ReactNode;
  headerClassName?: string;
}

export const AccountHeader: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { profilePicture = null, account, children, headerClassName } = props;
  return (
    <div className={style.accountHeader}>
      <div className={`${style.background} ${headerClassName || ''}`} />
      <div className={style.content}>
        <div className={style.profilePicture}>

          <img src={profilePicture ?? DefaultUserImage} alt='Profile Pic' 
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = DefaultUserImage;
            }}
          />
          {/* <div className={style.edit}>
            <EditIcon />
          </div> */}
        </div>
        <div className={style.name}>
          {t('account.hello')}{account?.length ? `, ${account}` : ''}
        </div>
      </div>
      {children}
    </div>
  );
};
