import React, {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import style from './style.module.less';
import ArrowUpIcon from '../../../assets/icons/arrow-up.svg?react';
import ArrowDownIcon from '../../../assets/icons/arrow-down.svg?react';
import { Steps } from './use-carousel-steps';
import { usePrevious } from '../../../hooks/use-previous';

const DURATION = 6000;

interface Props {
  carouselSteps: Steps[];
  activeIndex: number;
  progressRate: number;
  GetStartedComponent: JSX.Element;
  changeIndex: (index: number) => void;
  incrementProgressRate: (rate: number) => void;
  clearProgressRate: () => void;
}

const PROGRESS_RATE_UPDATE_INTERVAL = 60;
const STEP_INTERVAL = (100 * PROGRESS_RATE_UPDATE_INTERVAL) / DURATION;

export const MobileStepsCarousel: FC<Props> = (props) => {
  const {
    carouselSteps,
    activeIndex,
    changeIndex,
    progressRate,
    GetStartedComponent,
    incrementProgressRate,
    clearProgressRate,
  } = props;

  const timer = useRef<NodeJS.Timeout | null>(null);

  const setUpTimer = useCallback(() => {
    if (timer.current) {
      clearInterval(timer.current);
    }
    timer.current = setInterval(() => {
      incrementProgressRate(STEP_INTERVAL);
    }, PROGRESS_RATE_UPDATE_INTERVAL);
  }, [incrementProgressRate]);

  useEffect(() => {
    if (progressRate === 100) {
      incrementProgressRate(STEP_INTERVAL);
      setTimeout(() => {
        changeIndex((activeIndex + 1) % carouselSteps.length);
      }, 100);
    }
  }, [
    activeIndex,
    carouselSteps.length,
    changeIndex,
    incrementProgressRate,
    progressRate,
  ]);

  useEffect(() => {
    return () => clearInterval(timer.current);
  }, []);

  useEffect(() => {
    clearInterval(timer.current);
    clearProgressRate();
    setUpTimer();
  }, [activeIndex, clearProgressRate, setUpTimer]);

  return (
    <div className={style.mobileContainer}>
      <div className={style.carouselContainer}>
        {carouselSteps.map((step, index) => {
          const isActive = activeIndex === index;
          return (
            <div
              key={index}
              className={`${style.carouselItem} ${
                isActive ? style.isActive : ''
              }`}
            >
              <div
                className={style.carouselHeader}
                onClick={() => changeIndex(index)}
              >
                <step.Icon className={style.carouselItemIcon} />
                <div className={style.carouselItemTitle}>{step.name}</div>
                {isActive ? (
                  <div className={style.arrowContainer}>
                    <ArrowUpIcon className={style.arrowIcon} />
                  </div>
                ) : (
                  <div className={style.arrowContainer}>
                    <ArrowDownIcon className={style.arrowIcon} />
                  </div>
                )}
                <div
                  className={`${style.progressBarWrapper} ${
                    isActive ? style.active : ''
                  }`}
                >
                  <div
                    className={style.progressBar}
                    style={
                      {
                        '--progress-bar-width': `${progressRate}%`,
                      } as CSSProperties
                    }
                  />
                </div>
              </div>
              <div
                className={`${style.imageContainer} ${
                  isActive ? style.isImageActive : ''
                }`}
              >
                <img src={step.image} className={style.carouselImage} />
                {/* {getVideo(index)} */}
                <div className={style.carouselTextContainer}>
                  <div className={style.carouselDescriptionTitleWrapper}>
                    <step.Icon className={style.carouselItemIcon} />
                    <div className={style.carouselItemTitle}>{step.title}</div>
                  </div>
                  <div
                    className={style.carouselItemDescription}
                    dangerouslySetInnerHTML={{ __html: step.description }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {GetStartedComponent}
    </div>
  );
};
