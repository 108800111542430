import React from 'react';
import { Header } from '../../../components/header';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../redux/hooks';
import selectors from '../../../redux/selectors';
import { PrematterHeader } from '../../../components/prematter-header';
import { NoPaddingContentTitle } from '../../../components/content-title';
import style from './style.module.less';
import { Footer } from '../../../components/footer';
import { VerificationBanner } from '../verification-banner';
import { LawyerMessages } from '../../../components/lawyer-messages';
import { ContentWrapper } from '../../../components/content-wrapper';

interface Props {}
export const ConnectLawyers: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();

  const { t } = useTranslation();
  const prematter = useAppSelector(selectors.prematter.getById(id));
  const connections = useAppSelector(
    selectors.lawyerConnection.getConnectionsByPrematterId(id)
  );

  if (!prematter) {
    return null;
  }

  return (
    <>
      <Header />
      <PrematterHeader prematter={prematter} />
      <div className={style.wrapper}>
        <ContentWrapper className={style.contentBackground}>
          <VerificationBanner className={style.verificationBanner} 
            prematter={prematter} />
          <NoPaddingContentTitle
            title={t('connect.messages')}
            className={style.title}
          />
          <div className={style.description}>
            {t('connect.legal-partners-description')}
          </div>
          <div className={style.messageContainer}>
            <LawyerMessages
              connections={connections}
              prematters={[prematter]}
              usePrematterNameDescription={false}
            />
          </div>

          {/* <NoPaddingContentTitle
            title={t('connect.actions')}
            className={style.title}
          />
          <div className={style.description}>
            {t('connect.actions-description')}
          </div>
          <div className={style.messageContainer}>
            <LawyerActions
              actions={[
                {
                  actionType: 'Questionnaire',
                  lawyerName: 'John Doe',
                  timestamp: Date.now(),
                  isNew: true,
                  action: () => {},
                },
              ]}
            />
          </div> */}

          <div
            className={style.disclaimer}
            dangerouslySetInnerHTML={{ __html: t('connect.disclaimer') }}
          />
        </ContentWrapper>
      </div>
      <Footer />
    </>
  );
};
