import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ShieldTickIcon from '../../../assets/icons/shield-tick.svg?react';
import style from './style.module.less';
import { VerifyModalComponent } from '../../../components/verify-modal';
import { LegalCaseState, Prematter } from '@law-connect/types';
import { isVerifyDisabled } from '../../../utils/is-verify-disabled';

interface Props {
  show?: boolean;
  verified?: boolean;
  prematter: Prematter;
  className?: string;
}

export const VerificationBanner: FC<Props> = (props) => {
  const { prematter } = props;
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [showModal, setShowModal] = useState(false);

  const show = isVerifyDisabled(prematter?.state);


  const verified = useMemo(() => {
    return prematter.state === LegalCaseState.Verified;
  }, [prematter]);

  const onClickHandler = () => {
    // open the modal here
    setShowModal(true);
  };

  useEffect(() => {
    const divElement = ref.current;
    if (divElement) {
      const button = divElement.querySelector('button');
      if (button) {
        button.classList.add(style.whatsThisButton);
        button.onclick = onClickHandler;
      }
    }
  }, []);

  if (!show || !prematter) {
    return null;
  }

  return (
    <>
      <div className={`${style.bannerContainer} ${props.className ?? ''}`}>
        <ShieldTickIcon className={style.shieldIcon} />
        <div
          ref={ref}
          className={style.bodyText}
          dangerouslySetInnerHTML={{
            __html: t(verified ? 'verify.verified' : 'verify.pending-header'),
          }}
        />
      </div>
      <VerifyModalComponent
        isOpen={showModal}
        close={() => setShowModal(false)}
      />
    </>
  );
};
