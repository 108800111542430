let getAccessToken: () => Promise<string> = () => null;
let isAuthenticated: boolean | null = null;

export const security = {
  getAccessToken: () =>
    isAuthenticated || isAuthenticated === null ? getAccessToken() : null,
  setGetAccessToken: async (func: () => Promise<string>) =>
    (getAccessToken = func),
  setIsAuthenticated: (value: boolean) => (isAuthenticated = value),
};
