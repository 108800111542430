import React, { useMemo, useState } from 'react';
import style from './style.module.less';
import { Wrapper } from '../../components/wrapper';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import { useTranslation } from 'react-i18next';
import { HomeHero } from './hero';
import { Helmet } from 'react-helmet';
import { lawyerImages, LawyerImage } from './lawyer-images';
import { EmailIssuesComponent } from './email-issues';
import { MediaComponent } from './media';
import { NeedHelpCard } from '../../components/need-help';
import { HomeCaseStudies } from './case-studies';
import { HomeSecurityComponent } from './security';
import { LawyerBenefitsComponent } from './benefits';
import { StepsCarouselComponent } from './steps-carousel';

const generateLawyerImages = (): LawyerImage[] => {
  // find random image from the list
  const leftFacing =
    lawyerImages[Math.floor(Math.random() * lawyerImages.length)];
  const rightFacingImages = lawyerImages
    .filter((lawyer) => lawyer.female !== leftFacing.female);
  const rightFacing =
    rightFacingImages[Math.floor(Math.random() * rightFacingImages.length)];

  return [leftFacing, rightFacing];
};

interface Props {}
export const Home: React.FC<Props> = () => {
  const { t } = useTranslation();
  const [lawyerImages] = useState(generateLawyerImages);
  const lawyerLeft = useMemo(() => lawyerImages[0], [lawyerImages]);
  const lawyerRight = useMemo(() => lawyerImages[1], [lawyerImages]);

  return (
    <>
      <Helmet>
        <title>{t('pages.home')}</title>
      </Helmet>
      {/* <OldLawConnectBanner /> */}
      <Header />
      <Wrapper className={style.wrapper} contentClassName={style.content}>
        <HomeHero lawyerLeft={lawyerLeft} lawyerRight={lawyerRight} />

        <div className={style.marginWrapper}>
          <StepsCarouselComponent />

          <EmailIssuesComponent lawyerImage={lawyerLeft} />
          <LawyerBenefitsComponent lawyerImage={lawyerLeft} />
          <HomeSecurityComponent />

          <NeedHelpCard
          />
          <HomeCaseStudies 
            lawyerImage={lawyerRight}
          />

          <MediaComponent />
          <div className={style.disclaimerWrapper}>
            <div className={style.disclaimerTextWrapper}>
              <div
                className={style.disclaimer}
                dangerouslySetInnerHTML={{ __html: t('auth.disclaimer') }}
              />
              <div
                className={style.disclaimer}
                dangerouslySetInnerHTML={{
                  __html: t('auth.disclaimer-lawyer'),
                }}
              />
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer className={style.noMargin} />
    </>
  );
};
