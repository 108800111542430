import { Question } from '@law-connect/types';

export const formToSettifyValues = (data: Question[]) => {
  if (!data) {
    return;
  }
  const marriedQuestion = data.find(
    (q) =>
      q.question.toLowerCase() === 'Are you currently married?'.toLowerCase()
  );
  const currentSituationQuestion = data.find(
    (q) =>
      q.question.toLowerCase() ===
      'What best describes your situation?'.toLowerCase()
  );
  const hasChildrenQuestion = data.find(
    (q) =>
      q.question.toLowerCase() ===
      'Do you have children with your spouse?'.toLowerCase()
  );
  const financialAssetsQuestion = data.find(
    (q) =>
      q.question.toLowerCase() ===
      'What best describes your financial situation?'.toLowerCase()
  );

  let marriageStatus,
    currentSituation,
    hasChildren,
    financialAssets = null;

  switch (marriedQuestion?.answer) {
    case 'Yes':
      marriageStatus = 'married';
      break;
    case 'No':
      marriageStatus = 'unmarried';
      break;
    case 'We\'ve already divorced':
      marriageStatus = 'divorced';
      break;
    case 'Not sure':
      marriageStatus = 'notsure';
      break;
  }

  switch (currentSituationQuestion?.answer) {
    case 'In a relationship but thinking about separation':
      currentSituation = 'thinking-about-separation';
      break;
    case 'I think we\'re separating at the moment':
      currentSituation = 'separating';
      break;
    case 'We\'ve separated, and I\'m not sure what\'s next':
      currentSituation = 'separated-not-sure';
      break;
    case 'We separated a while ago and I want to get things sorted':
      currentSituation = 'separated-getting-things-sorted';
      break;
  }

  switch (hasChildrenQuestion?.answer) {
    case 'Yes':
      hasChildren = 'yes';
      break;
    case 'No':
      hasChildren = 'no';
      break;
    // eslint-disable-next-line @stylistic/js/max-len
    case 'I have children, but I\'m not looking for help with parenting at this stage':
      hasChildren = 'not-looking-for-help';
      break;
    case 'It\'s actually a bit complicated':
      hasChildren = 'complicated';
      break;
  }

  switch (financialAssetsQuestion?.answer) {
    case 'We have a fair amount of assets':
      financialAssets = 'assetrich';
      break;
    case 'We have some assets, but not a lot':
      financialAssets = 'assetmedium';
      break;
    case 'We don\'t really have any assets of value':
      financialAssets = 'assetpoor';
      break;
    case 'I don\'t know':
      financialAssets = 'dontknow';
      break;
    case 'I only want help with parenting':
      financialAssets = 'parentingonly';
      break;
  }

  return {
    'marriage-status': marriageStatus,
    'current-situation': currentSituation,
    'has-children': hasChildren,
    'financial-assets': financialAssets,
  };
};
