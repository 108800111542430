import React, { FC, useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import style from './style.module.less';
import LogoImage from '../../assets/logo.png';

// we want the height to shrink on large desktop on scroll to a min of 100px
interface Props {
  isMenuOpen?: boolean;
  menuRef?: React.RefObject<HTMLDivElement>;
}

export const LogoImageComponent: FC<Props> = (props) => {
  const { isMenuOpen, menuRef } = props;
  // we want to shrink the logo on scroll on large desktop
  const imageRef = useRef<HTMLImageElement>();

  const checkScrollPosition = useCallback(() => {
    if (window.scrollY > 80) {
      imageRef.current.classList.add(style.shrink);
      menuRef?.current?.classList.add(style.shrink);
    } else {
      imageRef.current.classList.remove(style.shrink);
      menuRef?.current?.classList.remove(style.shrink);
    }
  }, [menuRef]);

  useEffect(() => {
    // Initial check
    checkScrollPosition();

    // Event listener for scroll
    window.addEventListener('scroll', checkScrollPosition);
    window.addEventListener('resize', checkScrollPosition);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('scroll', checkScrollPosition);
      window.removeEventListener('resize', checkScrollPosition);
    };
  }, [checkScrollPosition]);

  return (
    <Link to='/' className={`${style.logo} ${isMenuOpen ? style.hide : ''}`}>
      <img
        src={LogoImage}
        alt='AskMaxwell'
        className={style.logoImage}
        ref={imageRef}
      />
    </Link>
  );
};
