import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NextStepCards } from '../../../components/next-step-card';
import { useMobile } from '../../../hooks/use-is-mobile';
import CopyLinkIcon from '../../../assets/icons/copy-link.svg?react';
import ShieldTickIcon from '../../../assets/icons/shield-tick.svg?react';
import {
  LawyerConnection,
  LegalCaseState,
  Prematter,
} from '@law-connect/types';
import { VerifyModalComponent } from '../../../components/verify-modal';
import { useAppDispatch } from '../../../redux/hooks';
import { actions } from '../../../redux/slices';
import style from './style.module.less';
import { NarrowWrapper } from '../../../components/narrow-wrapper';
import { useNavigate } from 'react-router-dom';
import { nextStepsAnchor } from './anchor';

interface Props {
  prematter: Prematter;
  connections: LawyerConnection[];
}

export const NextStepsList: FC<Props> = (props) => {
  const { prematter, connections } = props;
  const { t } = useTranslation();
  const isMobile = useMobile();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const hasReplied = connections?.some(
    (c) => c.messages?.filter((m) => m.from === 'client').length > 0
  );

  const verifiedLabel = useMemo(() => {
    if (prematter.state === LegalCaseState.ReportReady) {
      return t('verify.verify-now');
    } else if (prematter.state === LegalCaseState.VerificationPending) {
      return t('verify.pending');
    } else if (prematter.state === LegalCaseState.Verified) {
      return t('prematter.complete');
    } else {
      return null;
    }
  }, [prematter?.state, t]);

  const verifyAction = useCallback(() => {
    dispatch(actions.prematter.sendVerify({ id: prematter.id }));
    setShowModal(false);
  }, [dispatch, prematter?.id]);

  const cards = [
    {
      image: {
        src: `/next-steps/report${isMobile ? '-mobile' : ''}.jpg`,
        icon: <CopyLinkIcon width={24} height={24} />,
        label: t('prematter.report'),
      },
      title: t('prematter.report'),
      features: [
        t('prematter.report-card-description.0'),
        t('prematter.report-card-description.1'),
        t('prematter.report-card-description.2'),
      ],
      complete: true,
      button: {
        label: t('prematter.complete'),
        onClick: () => {},
      },
      rounded: true,
    },
    {
      image: {
        src: `/next-steps/verify${isMobile ? '-mobile' : ''}.jpg`,
        icon: <ShieldTickIcon width={24} height={24} />,
        label: t('verify.verify'),
      },
      title: t('verify.verify-case'),
      features: [
        t('verify.verify-case-description.0'),
        t('verify.verify-case-description.1'),
        t('verify.verify-case-description.2'),
      ],
      complete: prematter.state !== LegalCaseState.ReportReady,
      button: {
        label: verifiedLabel,
        onClick: () => setShowModal(true),
      },
      rounded: true,
    },
  ];

  if (connections.length > 0) {
    cards.push({
      image: {
        src: `/next-steps/connect${isMobile ? '-mobile' : ''}.jpg`,
        icon: <ShieldTickIcon width={24} height={24} />,
        label: t('connect.title'),
      },
      title: t('connect.get-help'),
      features: [
        t('connect.get-help-description.0'),
        t('connect.get-help-description.1'),
        t('connect.get-help-description.2'),
      ],
      complete: hasReplied,
      button: {
        label: !hasReplied ? t('connect.title') : t('prematter.complete'),
        onClick: () => navigate(`/case/${prematter.id}/connect`),
      },
      rounded: true,
    });
  }

  return (
    <div className={style.nextStepsWrapper} id={nextStepsAnchor}>
      <NarrowWrapper contentClassName={style.contentWrapper}>
        <div className={style.title}>{t('prematter.next-steps')}</div>
        <NextStepCards cards={cards} />
        <VerifyModalComponent
          isOpen={showModal}
          close={() => setShowModal(false)}
          toVerify={true}
          verifyAction={verifyAction}
        />
      </NarrowWrapper>
    </div>
  );
};
