import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { actions } from '..';
import {
  FormEntry,
  LegalCaseQuestion,
  WebSocketMessageType,
} from '@law-connect/types';

const STORE = 'websocket';
// these actions are to send a specific type of message to the websocket
const websocketActions = {
  reconnect: createAsyncThunk(`${STORE}/reconnect`, async (_, { dispatch }) => {
    dispatch(actions.websocket.connect({ reconnect: true }));
  }),
  sendConfirmation: createAsyncThunk(
    `${STORE}/sendConfirmation`,
    async (args: { result: boolean }, { dispatch }) => {
      // we are pushing the faq update to the websocket
      dispatch(
        actions.websocket.sendMessage({
          id: uuid(),
          type: WebSocketMessageType.Confirmation,
          timestamp: Date.now(),
          from: 'user',
          message: args.result ? 'yes' : 'no',
        })
      );
      return;
    }
  ),
  // todo: should become an api call instead
  updateFAQ: createAsyncThunk(
    `${STORE}/updateFAQ`,
    async (args: { faq: LegalCaseQuestion[] }, { dispatch }) => {
      // we are pushing the faq update to the websocket
      dispatch(
        actions.websocket.sendMessage({
          id: uuid(),
          type: WebSocketMessageType.FAQ,
          timestamp: Date.now(),
          from: 'user',
          message: args.faq,
        })
      );
      return;
    }
  ),

  sendForm: createAsyncThunk(
    `${STORE}/sendForm`,
    async (args: { formData: FormEntry[] }, { dispatch }) => {
      // we are pushing the faq update to the websocket
      dispatch(
        actions.websocket.sendMessage({
          id: uuid(),
          type: WebSocketMessageType.Form,
          timestamp: Date.now(),
          from: 'user',
          message: args.formData,
        })
      );
      return;
    }
  ),
};

export default websocketActions;
