import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';
import LocationSvg from '../../assets/icons/location.svg?react';
import { Message } from '@law-connect/types';
import { useSelector } from 'react-redux';
import selectors from '../../redux/selectors';

interface Props {
  chat: Message;
  chatClassName: string;
  openLocation: () => void;
  showAllow: boolean;
  toggleMobileTimestamp: () => void;
  DateTime: JSX.Element;
}

const LocationMessageComponent: FC<Props> = (props) => {
  const {
    chat,
    chatClassName,
    showAllow,
    openLocation,
    toggleMobileTimestamp,
    DateTime,
  } = props;

  const { t } = useTranslation();
  const isLocationPending = useSelector(selectors.session.isLocationPending());

  return (
    <div className={style.outerWrapper}>
      <div className={chatClassName} onClick={toggleMobileTimestamp}>
        <div className={style.locationMessage}>
          {chat.content?.toString().length ? chat.content.toString() : t('chat.geoloc-explain')}
        </div>
        {DateTime}
      </div>
      {/* we only want to show the allow location button if location is last action */}
      {showAllow ? (
        <button
          className={style.locationButton}
          onClick={openLocation}
          disabled={isLocationPending}
        >
          {t('chat.geoloc-allow')}
          <LocationSvg className={style.locationIcon} />
        </button>
      ) : null}
    </div>
  );
};

export default LocationMessageComponent;
