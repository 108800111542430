import React, { useEffect } from 'react';
import { Header } from '../../../components/header';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import selectors from '../../../redux/selectors';
import { PrematterHeader } from '../../../components/prematter-header';
import {
  NarrowWrapper,
  WrapperOffsetSide,
} from '../../../components/narrow-wrapper';
import { NoPaddingContentTitle } from '../../../components/content-title';
import styles from './style.module.less';
import { Footer } from '../../../components/footer';
import { Settify, SettifyRef } from '../../../components/settify';
import { Button } from '../../../components/button';
import { actions } from '../../../redux/slices';
import useExternalHash from '../../../hooks/use-external-hash';
import { useMobile } from '../../../hooks/use-is-mobile';
import { formToSettifyValues } from '../../../utils/form-to-settify';

interface Props {}
export const PrematterReportPlus: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();

  const settifyRef = React.useRef<SettifyRef>(null);
  const prematter = useAppSelector(selectors.prematter.getById(id));
  const [data, setData] = React.useState<any>(null);
  const hash = useExternalHash();
  const isMobile = useMobile();

  // Calculate the sum of all values
  const completedItems = Object.values(
    data?.attributes['page-completed-percent'] || {}
  ).reduce((acc: number, curr: number) => acc + curr, 0) as number;
  const isComplete = completedItems >= 12;

  useEffect(() => {
    if (settifyRef.current) {
      const upatedData = settifyRef.current?.getData();

      if (!data || data?.id === upatedData?.id) {
        setData(upatedData);
      }

      if (
        data &&
        JSON.stringify(data) !== JSON.stringify(upatedData) &&
        data?.id === upatedData?.id
      ) {
        dispatch(
          actions.prematter.updateExtendedForm({
            id: prematter.id,
            extendedForm: {
              complete: isComplete,
              data: upatedData,
            },
          })
        );
      }
    }
  }, [hash]);

  const onSubmit = () => {
    dispatch(
      actions.prematter.updateExtendedForm({
        id: prematter.id,
        extendedForm: {
          complete: isComplete,
          data,
        },
        generate: true,
      })
    );
  };

  if (!prematter) {
    return null;
  }

  return (
    <>
      <Header />
      <PrematterHeader prematter={prematter} />
      <div className={styles.wrapper}>
        <NarrowWrapper
          offset={WrapperOffsetSide.LEFT}
          offsetClassName={styles.tabletWidth}
        >
          {hash.endsWith('induction-roadmap') ? (
            <>
              <NoPaddingContentTitle
                title={'Questionnaire'}
                className={styles.title}
              />
            </>
          ) : null}
          <Settify
            ref={settifyRef}
            rootUrl={`/case/${prematter.id}/questionnaire/`}
            style={{ marginTop: '20px', padding: isMobile ? undefined : 0 }}
            data={prematter?.context.extendedForm?.data}
            prefill={formToSettifyValues(prematter?.context.form.questions)}
          />
          {hash?.endsWith('induction-roadmap') && (
            <Button
              className={`${styles.button} ${
                !isComplete ? styles.disabled : ''
              }`}
              label={'Complete & regenerate report'}
              onClick={onSubmit}
              disabled={!isComplete}
            />
          )}
        </NarrowWrapper>
      </div>
      <Footer />
    </>
  );
};
