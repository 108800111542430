import { useEffect, useState } from 'react';
export const MOBILE_WIDTH = 769;
export const TABLET = 1184;
const LARGE_DESKTOP = 1600;

export const enum WidthType {
  Mobile = 'mobile',
  Tablet = 'tablet',
  SmallDesktop = 'smallDesktop',
  LargeDesktop = 'largeDesktop',
}

const getType = (width: number) => {
  if(width > LARGE_DESKTOP) {
    return WidthType.LargeDesktop;
  } else if(width > TABLET) {
    return WidthType.SmallDesktop;
  } else if(width > MOBILE_WIDTH) {
    return WidthType.Tablet;
  } else {
    return WidthType.Mobile;
  }
};

export const useScreenSize = (): WidthType => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const [screenType, setScreenType] = useState<WidthType>(
    getType(window.innerWidth)
  );

  // Store current value in ref
  useEffect(() => {
    const handleWindowResize = () => {
      setScreenType(getType(window.innerWidth));
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // Return previous value (happens before update in useEffect above)
  return screenType;
};

export const useMobile = (): boolean => {
  return useScreenSize() === WidthType.Mobile;
};


{
  /*
  if the device is touch enabled and also small screen we assume it is a tablet or mobile device
*/
}

export const isTouchDevice = () => {
  const hasTouch =
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0;

  return hasTouch && window.innerWidth < TABLET;
    

};
