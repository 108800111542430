import { LegalCaseState } from '@law-connect/types';

export const isPrematterComplete = (
  state: LegalCaseState,
  notPending?: boolean
) => {
  return (
    (state === LegalCaseState.PreparingReport && !notPending) ||
    state === LegalCaseState.ReportReady ||
    state === LegalCaseState.VerificationPending ||
    state === LegalCaseState.Verified ||
    state === ('complete' as LegalCaseState)
  );
  // complete is a legacy state that needs to be removed eventually
};
