import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';
import { LawyerImage } from '../lawyer-images';
import { StackedCardComponent } from '../../../components/stacked-card';

interface Props {
  lawyerImage: LawyerImage;
}

export const LawyerBenefitsComponent: FC<Props> = (props) => {
  const { lawyerImage } = props;

  const { t } = useTranslation();

  const descriptionComponent = useMemo(
    () => (
      <>
        <div className={style.descriptionContainer}>
          <div className={style.benefitTitle}>
            {t('home.benefits.accessible.title')}
          </div>
          <div className={style.benefitDescription}>
            {t('home.benefits.accessible.description')}
          </div>
        </div>
        <div className={style.descriptionContainer}>
          <div className={style.benefitTitle}>
            {t('home.benefits.customised.title')}
          </div>
          <div className={style.benefitDescription}>
            {t('home.benefits.customised.description')}
          </div>
        </div>
        <div className={style.descriptionContainer}>
          <div className={style.benefitTitle}>
            {t('home.benefits.empowered.title')}
          </div>
          <div className={style.benefitDescription}>
            {t('home.benefits.empowered.description')}
          </div>
        </div>
      </>
    ),
    [t]
  );

  return (
    <StackedCardComponent
      cardImage={lawyerImage.fullLawyerImage}
      RightCard={descriptionComponent}
    />
  );
};
