import React, { FC, SVGProps } from 'react';
import style from './style.module.less';

interface Item {
  Icon: FC<SVGProps<SVGSVGElement>>;
  name: string;
  description: string;
  iconClassName?: string;
}

interface Props {
  items: Item[];
}

export const TriHighlightedCardStack: FC<Props> = (props) => {
  const { items } = props;

  return (
    <div className={style.cardStackContainer}>
      {items.map((item) => (
        <div className={style.itemCardContainer} key={item.name}>
          <item.Icon
            className={`${style.itemCardIcon} ${item.iconClassName ?? ''}`}
          />
          <div className={style.itemCardTitle}>{item.name}</div>
          <div className={style.itemCardDescription}>{item.description}</div>
        </div>
      ))}
    </div>
  );
};
