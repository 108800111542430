import { useTranslation } from 'react-i18next';
import ChatIcon from '../../../assets/icons/chat.svg?react';
import ShieldTickIcon from '../../../assets/icons/shield-tick.svg?react';
import RandomIcon from '../../../assets/icons/random.svg?react';
import step1Image from '../../../assets/home/home-feature-1.jpg';
import step2Image from '../../../assets/home/home-feature-2.jpg';
import step3Image from '../../../assets/home/home-feature-3.jpg';
import step1Video from '../../../assets/home/home-video-1.mp4';
import step2Video from '../../../assets/home/home-video-2.mp4';
import step3Video from '../../../assets/home/home-video-3.mp4';
import { FC, SVGProps, useMemo } from 'react';

export interface Steps {
  name: string;
  Icon: FC<SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
  image: string;
  video: string;
}

export const useCarouselSteps = (): Steps[] => {
  const { t } = useTranslation();

  const memo = useMemo(() =>  [
    {
      name: t('home.how-it-works.step1.subtitle'),
      Icon: ChatIcon,
      title: t('home.how-it-works.step1.title'),
      description: t('home.how-it-works.step1.description'),
      image: step1Image,
      video: step1Video,
    },
    {
      name: t('home.how-it-works.step2.subtitle'),
      Icon: ShieldTickIcon,
      title: t('home.how-it-works.step2.title'),
      description: t('home.how-it-works.step2.description'),
      image: step2Image,
      video: step2Video,
    },
    {
      name: t('home.how-it-works.step3.subtitle'),
      Icon: RandomIcon,
      title: t('home.how-it-works.step3.title'),
      description: t('home.how-it-works.step3.description'),
      image: step3Image,
      video: step3Video,
    },
  ], [t]);

  return memo;
};
