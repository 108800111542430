import thunkActions from './thunk';
import { aboutActions, aboutReducer } from './about';
import { sessionActions, sessionReducer } from './session';
import { websocketActions, websocketReducer } from './websocket';
import { prematterActions, prematterReducer } from './prematter';
import { fileActions, fileReducer } from './file';
import { userActions, userReducer } from './user';
import {
  lawyerConnectionActions,
  lawyerConnectionReducer,
} from './lawyer-connection';

// we are merging the thunks with the slice actions so that we can dispatch the combination
// you cant have a reducer name match the thunk
const actions = {
  about: {
    ...aboutActions,
    ...thunkActions.aboutThunk,
  },
  session: {
    ...sessionActions,
    ...thunkActions.sessionThunk,
  },
  websocket: {
    ...websocketActions,
    ...thunkActions.websocketThunk,
  },
  prematter: {
    ...prematterActions,
    ...thunkActions.prematterThunk,
  },
  file: {
    ...fileActions,
    ...thunkActions.fileThunk,
  },
  lawyerConnection: {
    ...lawyerConnectionActions,
    ...thunkActions.lawyerConnectionThunk,
  },
  user: {
    ...userActions,
    ...thunkActions.userThunk,
  },
};

const reducers = {
  session: sessionReducer,
  websocket: websocketReducer,
  prematter: prematterReducer,
  file: fileReducer,
  user: userReducer,
  lawyerConnection: lawyerConnectionReducer,
  about: aboutReducer,
};

export { actions, reducers };
