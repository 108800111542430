import React from 'react';
import style from './style.module.less';

interface Props {
  title: string;
  image?: React.ReactNode;
  action?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  titleClassName?: string;
  id?: string;
}

export const ReportCard: React.FC<Props> = (props) => {
  const { id, title, image, action, children, className, titleClassName }
     = props;
  return (
    <div
      id={id}
      // eslint-disable-next-line @stylistic/js/max-len
      className={`${style.reportCard} ${image ? style.hasImage : {}} ${className || ''}`}
    >
      <div className={`${style.titleWrapper} ${titleClassName || ''}`}>
        <div className={style.title}>{title}</div>
        {action ? <div className={style.action}>{action}</div> : null}
      </div>
      <div className={style.image}>{image}</div>
      <div className={style.content}>{children}</div>
    </div>
  );
};
