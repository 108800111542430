import React from 'react';
import styles from './style.module.less';

interface Props {
  children: React.ReactNode;
  backgroundClassName?: string;
  offsetClassName?: string;
  contentClassName?: string;
  className?: string;
}

export const ContentWrapper: React.FC<Props> = (props) => {
  return (
    <div className={`${styles.contentWrapper} ${props.className ?? ''}`}>
      <div className={`${styles.content} ${props.contentClassName}`}>
        {props.children}
      </div>
    </div>
  );
};
