import i18n from 'i18next';
import { toast } from 'react-toastify';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
const MAX_NAME_LENGTH = 20;

const STORE = 'file';
const prematterThunk = {
  fetchByPrematterId: createAsyncThunk(
    `${STORE}/fetchByPrematterId`,
    async (args: { prematterId: string }, { rejectWithValue }) => {
      try {
        const { prematterId } = args;
        const response = await api.file.fetchByPrematterId({ prematterId });
        return response;
      } catch (error) {
        console.error('fetch prematter files', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  upload: createAsyncThunk(
    `${STORE}/upload`,
    async (
      args: { prematterId?: string; file: File; attachedToMessage?: boolean },
      { rejectWithValue }
    ) => {
      try {
        const { prematterId, file, attachedToMessage } = args;
        const response = await api.file.upload({
          prematterId,
          file,
          attachedToMessage,
        });
        toast.success(i18n.t('toast.file-upload-success'));
        return response;
      } catch (error) {
        console.error('upload file', error);
        const replaceName =
          args.file.name.length > MAX_NAME_LENGTH
            ? args.file.name.substring(0, MAX_NAME_LENGTH - 3) + '...'
            : args.file.name;

        toast.error(
          i18n.t('toast.file-upload-error').replace('FILE_NAME', replaceName)
        );

        return rejectWithValue(error.message as string);
      }
    }
  ),
  download: createAsyncThunk(
    `${STORE}/download`,
    async (args: { id: string }, { rejectWithValue }) => {
      try {
        const { id } = args;
        const response = await api.file.download({ id });
        return response;
      } catch (error) {
        console.error('download file', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  delete: createAsyncThunk(
    `${STORE}/delete`,
    async (
      args: { id: string; prematterId: string; fileName?: string },
      { rejectWithValue }
    ) => {
      try {
        const response = await api.file.delete(args);
        toast.success(i18n.t('toast.file-delete-success'));
        return response;
      } catch (error) {
        console.error('delete file', error);
        const replaceName = !args.fileName
          ? ''
          : args.fileName?.length > MAX_NAME_LENGTH
            ? args.fileName.substring(0, MAX_NAME_LENGTH - 3) + '...'
            : args.fileName;

        toast.error(
          i18n.t('toast.file-delete-error').replace('FILE_NAME', replaceName)
        );

        return rejectWithValue(error.message as string);
      }
    }
  ),
};

export default prematterThunk;
