/* eslint-disable @stylistic/js/max-len */
import React, { FC, createRef, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import style from './style.module.less';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import CloseIcon from '../../assets/icons/cross-circle.svg?react';
import ShieldIcon from '../../assets/icons/shield-tick.svg?react';
import BannerLeft from '../../assets/verify/banner-left.svg?react';
import BannerRight from '../../assets/verify/banner-right.svg?react';

export interface LoginModalRef {
  closeModal: () => void;
  openModal: () => void;
}

interface Props {
  isOpen?: boolean;
  toVerify?: boolean;
  verifyAction?: () => void;
  close: () => void;
}

export const VerifyModalComponent: FC<Props> = (props) => {
  const { close, isOpen, toVerify, verifyAction } = props;
  const { t } = useTranslation();

  const modalRef = createRef<HTMLDivElement>();

  useOnClickOutside(modalRef, close);

  useEffect(() => {
    // stop scrolling when modal is open
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  const verifyActionWrapper = useCallback(() => {
    verifyAction && verifyAction();
  }, [verifyAction]);

  const body = useMemo(() => {
    let footer = <></>;

    if (toVerify) {
      footer = (
        <>
          <div className={style.section}>
            <div className={style.buttonWrapper}>
              <button className={style.verifyButton} onClick={verifyActionWrapper}>
                {t('verify.verify-now')}
              </button>
              <button className={style.button} onClick={close}>
                {t('confirm.cancel')}
              </button>
            </div>
          </div>
        </>
      );
    } else {
      footer = (
        <>
          <div className={style.section}>
            <div className={style.pendingBody}>
              <div className={style.bodyText}>{t('verify.pending-text')}</div>
              <ShieldIcon className={style.shieldIcon} />
            </div>
          </div>

          <div className={style.section}>
            <div className={style.buttonWrapper}>
              <button className={style.button} onClick={close}>
                {t('confirm.close')}
              </button>
            </div>
          </div>
        </>
      );
    }

    return (
      <div className={style.modalWrapper}>
        <div className={style.modalBody} ref={modalRef}>
          <div className={style.headerWrapper}>
            <img
              src={'/standard-bg.jpg'}
              className={style.imageBg}
            />
            <div className={style.closeIconContainer} onClick={close}>
              <CloseIcon className={style.closeIcon}/>
            </div>
            <div className={style.imageBodyContainer}>
              <div className={style.shieldBubbleContainer}>
                <ShieldIcon className={style.shieldBubble} />
              </div>
              <div className={style.imageBodyText}>{t('verify.verify')}</div>
              {toVerify ? (
                <div className={style.verificationExplanation}>
                  {t('verify.verification-explanation')}
                </div>
              ) : null}
            </div>
            <div className={style.bannerContainer}>
              <div className={style.bannerWrapper}>
                <div className={style.bannerText}>
                  {t('verify.free-service')}
                </div>
                <BannerLeft className={style.bannerLeft} />
                <BannerRight className={style.bannerRight} />
              </div>
            </div>
          </div>
          <div className={`${style.section} ${style.firstSection}`}>
            <div className={style.descriptionContainer}>
              <div className={style.description}>
                <span className={style.tick}>✓</span>
                {t('verify.description1')}
              </div>
              <div className={style.description}>
                <span className={style.tick}>✓</span>
                {t('verify.description2')}
              </div>
              <div className={style.description}>
                <span className={style.tick}>✓</span>
                {t('verify.description3')}
              </div>
              <div className={style.description}>
                <span className={style.tick}>✓</span>
                {t('verify.description4')}
              </div>
            </div>
          </div>

          {footer}
        </div>
      </div>
    );
  }, [close, modalRef, t, toVerify, verifyActionWrapper]);

  return createPortal(isOpen ? body : <></>, document.body);
};
