import React, {
  createRef,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';

import EyeIcon from '../../../assets/icons/eye.svg?react';
import DataIcon from '../../../assets/icons/data.svg?react';
import PadlockIcon from '../../../assets/icons/padlock-closed.svg?react';
import ShieldIcon from '../../../assets/icons/shield-tick.svg?react';
import {
  isTouchDevice,
  useScreenSize,
  WidthType,
} from '../../../hooks/use-is-mobile';
import { Carousel } from '@law-connect/react-components';

const enum WidthSizes {
  LargeDesktop = 720,
  SmallDesktop = 676,
  Tablet = 680,
  Mobile = 210,
}

const CARDS_DATA = [
  {
    Icon: EyeIcon,
    title: 'home.security-list.privacy.title',
    description: 'home.security-list.privacy.description',
  },
  {
    Icon: DataIcon,
    title: 'home.security-list.safeguard.title',
    description: 'home.security-list.safeguard.description',
  },
  {
    Icon: PadlockIcon,
    title: 'home.security-list.login.title',
    description: 'home.security-list.login.description',
  },
  {
    Icon: ShieldIcon,
    title: 'home.security-list.advanced.title',
    description: 'home.security-list.advanced.description',
  },
];

export const HomeSecurityComponent: FC = () => {
  const { t } = useTranslation();
  const screenType = useScreenSize();
  const [activeIndex, setActiveIndex] = useState(0);
  const [newActive, setNewActive] = useState<number | null>(null);
  const ref = createRef<HTMLDivElement>();
  const [isInDiv, setIsInDiv] = useState(false);
  const isTabletOrMobile = isTouchDevice();

  useEffect(() => {
    if (ref.current && newActive !== null) {
      // find child element by index
      const firstElement = ref.current.children[newActive];
      if (firstElement) {
        ref.current.scrollTo({
          left: newActive * (firstElement.clientWidth + 40),
          behavior: 'smooth',
        });
      }
      setActiveIndex(newActive);
      setNewActive(null);
    }
  }, [newActive, activeIndex, ref]);

  const getCardWidth = useCallback(() => {
    if (screenType === WidthType.LargeDesktop) {
      return WidthSizes.LargeDesktop;
    } else if (screenType === WidthType.SmallDesktop) {
      return WidthSizes.SmallDesktop;
    } else if (screenType === WidthType.Tablet) {
      return WidthSizes.Tablet;
    } else {
      return WidthSizes.Mobile;
    }
  }, [screenType]);

  const getCarouselItems = useCallback(() => {
    return CARDS_DATA.map((card, index) => {
      return (
        <div className={style.carouselCard} key={index}>
          <div className={style.iconContainer}>
            <card.Icon className={style.carouselIcon} />
            {/* bit of a hack but this space is added so secure logins drops to a new line */}
            <div className={style.carouselTitle}>{t(card.title)}&nbsp;</div>
          </div>
          <div className={style.carouselDescription}>{t(card.description)}</div>
        </div>
      );
    });
  }, [t]);

  const onMouseEnter = useCallback(() => setIsInDiv(true), []);
  const onMouseLeave = useCallback(() => setIsInDiv(false), []);

  return (
    <div
      className={style.homeSecurityContainer}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={style.titleContainer}>
        <div className={style.title}>{t('home.security-title')}</div>
        <div
          className={style.titleDescription}
          dangerouslySetInnerHTML={{ __html: t('home.security-description') }}
        />
      </div>
      <Carousel
        cardWidth={getCardWidth()}
        items={getCarouselItems()}
        showProgressBar={true}
        classNames={{
          container: style.securityCarousel,
          cardsContainer: style.securityCardsContainer,
          actionBar: `${style.carouselDesktopControls} ${
            isTabletOrMobile ? '' : style.noTouch
          } ${isInDiv ? '' : style.outsideDiv}`,
          arrowButtonContainer: `${style.carouselButton} ${
            isTabletOrMobile ? style.noControl : ''}`,
        }}
      />
    </div>
  );
};
