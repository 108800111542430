export const useCookieHandler = () => {
  const getCookie = (name: string):string => {
    const cookieString = document.cookie;
    const cookies = cookieString.split(';');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName.trim() === name) {
        return cookieValue;
      }
    }
    return null;
  };

  // Function to delete a cookie by name
  const deleteCookie = (name: string) => {
    // eslint-disable-next-line @stylistic/js/max-len
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };

  // Function to set a cookie with a given name and value
  const setCookie = (
    name: string,
    value: string,
    daysToExpire: number = 365
  ) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + daysToExpire);
    // eslint-disable-next-line @stylistic/js/max-len
    const cookieString = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/;`;
    document.cookie = cookieString;
  };

  return {
    getCookie,
    deleteCookie,
    setCookie,
  };
};
