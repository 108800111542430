import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';
import ArrowRightIcon from '../../../assets/icons/arrow-right.svg?react';

interface Props {
  getStarted: () => void;
}

export const GetStartedComponent: FC<Props> = (props) => {
  const {getStarted} = props;
  const { t } = useTranslation();

  return (
    <div className={style.getStartedContainer}>
      <div className={style.getStartedTitle}>
        {t('home.how-it-works.get-started-title')}
      </div>
      <div className={style.getStartedButtonContainer}>
        <button className={style.getStartedButton} onClick={getStarted}>
          <div className={style.getStartedButtonLabel}>
            {t('home.how-it-works.get-started-button')}
          </div>

          <div className={style.iconContainer}>
            <div className={style.getStartedIconContainerHover}>
              <ArrowRightIcon className={style.getStartedIcon} />
            </div>
            <div className={style.getStartedIconContainer}>
              <ArrowRightIcon className={style.getStartedIcon} />
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};
