import React from 'react';
import style from './style.module.less';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

export const ContentParagraphs: React.FC<Props> = (props) => {
  const { children, className } = props;
  return (
    <div className={`${style.contentParagraphs} ${className ? className : ''}`}>
      {children}
    </div>
  );
};
