import React from 'react';
import style from './style.module.less';

interface Props {}

export const HorizontalRule: React.FC<Props> = (props) => {
  return (
    <div className={style.horizontalRule} />
  );
};
