import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import styles from './style.module.less'; // reskin styles
import useExternalPathname from './use-external-pathname';
import useExternalHash from '../../hooks/use-external-hash';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';

export interface SettifyRef {
  getData: () => Record<string, any> | null;
}
interface Props {
  rootUrl: string;
  continuous?: boolean;
  setProgress?: (progress: number, items: number) => void;
  onComplete?: (data: Record<string, any>) => void;
  style?: React.CSSProperties;
  data?: Record<string, any>;
  prefill?: Record<string, any>;
}
export const Settify = forwardRef<SettifyRef, Props>((props, ref) => {
  const { rootUrl, continuous, setProgress, onComplete, style, prefill } =
    props;

  const [data, setData] = React.useState<any>(props.data || null);
  const settifyAppRef = React.useRef<HTMLDivElement>(null);
  const hash = useExternalHash();
  const pathname = useExternalPathname();
  const [calledOnComplete, setCalledOnComplete] = React.useState(false);
  const [cookies, setCookie] = useCookies(['clientID', 'deviceFingerprint']);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    if (prefill) {
      if (!prefill['marriage-status']) {
        prefill['marriage-status'] = 'married';
      }
      if (!prefill['current-situation']) {
        prefill['current-situation'] = 'thinkingaboutseparation';
      }

      window.localStorage.setItem(
        'settify-prefill',
        JSON.stringify({
          referrer: 'askmaxwell',
          attributes: btoa(JSON.stringify(prefill)),
        })
      );
    }

    // Update progress
    if (setProgress) {
      const p = calculateProgress();
      setProgress(p.percentComplete, p.totalSections);
    }
  }, [prefill]);

  // on location change, read local storage
  useEffect(() => {
    const clientId = window.localStorage.getItem('client:active');
    const clientData = window.localStorage.getItem(`client:record:${clientId}`);
    if (clientData) {
      const parsed = JSON.parse(clientData || '{}');
      setData(parsed);
    } else {
      setData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash, pathname]);

  const calculateProgress = () => {
    const totalSections = 12;
    // eslint-disable-next-line @stylistic/js/max-len
    const progress = Object.values(
      data?.attributes['page-completed-percent'] || {}
    ).reduce((acc: number, v: string) => {
      return acc + v;
    }, 0) as number;

    const percentComplete = (progress / totalSections) * 100;

    return {
      percentComplete,
      totalSections,
    };
  };

  useEffect(() => {
    if (data) {
      document.title = 'AskMaxwell';
      const { percentComplete, totalSections } = calculateProgress();

      // console.log(`Completed: ${percentComplete}%`);

      setProgress ? setProgress(percentComplete, totalSections) : null;

      let hasFinishedFutureNeeds = false;
      if (
        data.attributes['page-completed-percent'] &&
        data.attributes['page-completed-percent']['g-futureneeds']
      ) {
        if (data.attributes['page-completed-percent']['g-futureneeds'] === 1) {
          hasFinishedFutureNeeds = true;
        }
      }

      if (
        (percentComplete >= 100 || hasFinishedFutureNeeds) &&
        !calledOnComplete
      ) {
        if (onComplete) {
          onComplete(data);
          setCalledOnComplete(true);
        }
      }
    }
  }, [calledOnComplete, data, onComplete, setProgress]);

  // Use the useImperativeHandle hook to expose a function to the parent
  useImperativeHandle(
    ref,
    () => ({
      getData() {
        return data;
      },
    }),
    [data]
  );

  useEffect(() => {
    if (settifyAppRef.current && props.data) {
      setCookie('clientID', props.data.id, { path: '/' });
      setCookie('deviceFingerprint', props.data.deviceFingerprint, {
        path: '/',
      });
      window.localStorage.setItem('client:active', props.data.id);
      window.localStorage.setItem(
        `client:record:${props.data.id}`,
        JSON.stringify(props.data)
      );
      window.localStorage.setItem(
        `client:fingerprint:${props.data.id}`,
        props.data.deviceFingerprint
      );
      window.localStorage.setItem(
        `client:previousLastModified:${props.data.id}`,
        props.data.previousLastModified
      );
    }
  }, []);

  useEffect(() => {
    if (!settifyAppRef.current) return;

    // We set this so internally settify to handle us as a referrer
    window.localStorage.setItem('settify-storage:referrer', 'askmaxwell');

    // Additionally, set it as embedded
    window.localStorage.setItem('settify-storage:embedded', 'true');

    // Function to manually start the Ember app
    const startEmberApp = () => {
      setIsLoading(false);
      // Ensure you have the global namespace defined by Ember
      if (window.require) {
        // Import the Ember application from the global namespace
        const App = window.require('settify-mvp/app')['default'];
        const config = window.require('settify-mvp/config/environment')[
          'default'
        ];
        config.rootURL = rootUrl;
        config.basePath = '/settify';
        config.locationType = 'hash'; // we use hash as to not screw with react-router
        config.environment = 'askmaxwell';
        config.APP = {
          ...config.APP,
          disabledComponents: {
            'settify-footer': true,
            'induction-roadmap': true,
            'induction-roadmap-title': true,
            'page-title': continuous,
            contact: true,
            'skip-section': false,
            'initial-previous': continuous,
          },
          enabledComponents: {
            'custom-progress-bar': true,
          },
          pageTitle: 'AskMaxwell',
          // doNotChangePageTitle: true,
          doNotResetScrollPosition: true,
          previousButtonText: 'Back',
          nextButtonText: 'Next',
          continuous,
          disableAnimations: true,
        };

        // Set the rootElement where Ember will mount and disable routing
        (window as any)._settifyApp = App.create({
          autoboot: true,
          rootElement: '#settify-app',
        });
        (window as any)._settifyApp.boot();
      }
    };

    // Function to append a link element to the head
    const appendMeta = (name: string, content: string) => {
      const meta = document.createElement('meta');
      meta.name = name;
      meta.content = content;
      // Add to start of head
      document.head.insertBefore(meta, document.head.firstChild);
    };

    // Function to append a link element to the head
    const appendLink = (href: string, integrity = '', rel = 'stylesheet') => {
      const link = document.createElement('link');
      link.href = href;
      link.rel = rel;
      if (integrity) {
        link.integrity = integrity;
      }
      link.crossOrigin = 'anonymous';
      document.head.insertBefore(link, document.head.firstChild);
    };

    // Function to append a script element to the head
    const appendScript = (
      src: string,
      integrity = '',
      crossOrigin = 'anonymous'
    ) => {
      const script = document.createElement('script');
      script.src = src;
      if (integrity) {
        script.integrity = integrity;
      }
      script.crossOrigin = crossOrigin;
      document.head.appendChild(script);
    };

    // Append necessary stylesheets and scripts to the head
    appendMeta(
      'settify-mvp/config/environment',
      // eslint-disable-next-line @stylistic/js/max-len
      '%7B%22modulePrefix%22%3A%22settify-mvp%22%2C%22environment%22%3A%22development%22%2C%22rootURL%22%3A%22/%22%2C%22locationType%22%3A%22auto%22%2C%22EmberENV%22%3A%7B%22FEATURES%22%3A%7B%7D%2C%22EXTEND_PROTOTYPES%22%3A%7B%22Date%22%3Afalse%7D%7D%2C%22APP%22%3A%7B%22name%22%3A%22settify-mvp%22%2C%22version%22%3A%220.0.0%22%7D%2C%22contentSecurityPolicy%22%3A%7B%22default-src%22%3A%22%27none%27%22%2C%22font-src%22%3A%5B%22%27self%27%22%2C%22https%3A//fonts.gstatic.com%22%5D%2C%22connect-src%22%3A%5B%22%27self%27%22%2C%22https%3A//n1hfc7d9md.execute-api.us-west-2.amazonaws.com%22%5D%2C%22img-src%22%3A%5B%22%27self%27%22%5D%2C%22style-src%22%3A%5B%22%27self%27%22%2C%22https%3A//fonts.googleapis.com%22%5D%2C%22media-src%22%3Anull%7D%2C%22exportApplicationGlobal%22%3Atrue%2C%22ember-load%22%3A%7B%22loadingIndicatorClass%22%3A%22ember-load-indicator%22%7D%2C%22emberOffline%22%3A%7B%22themes%22%3A%7B%22theme%22%3A%22default%22%2C%22indicator%22%3Afalse%2C%22language%22%3A%22english%22%7D%7D%7D'
    );
    appendLink('/settify/assets/vendor.css', '');
    appendLink('/assets/settify-reskin.css', '');
    appendLink('/settify/assets/settify-mvp.css', '');
    appendLink(
      'https://fonts.googleapis.com/css?family=Lato:300,400,900',
      null,
      'stylesheet'
    );
    appendScript('https://kit.fontawesome.com/ba16d22322.js', null);

    if (
      document.querySelectorAll('script[src*="/settify/assets"]').length === 0
    ) {
      // Append Ember's vendor script
      const vendorScript = document.createElement('script');
      vendorScript.src = '/settify/assets/vendor.js';
      vendorScript.onload = () => {
        // Append Ember's app script once vendor script is loaded
        const appScript = document.createElement('script');
        appScript.src = '/settify/assets/settify-mvp.js';
        appScript.onload = startEmberApp;
        document.body.appendChild(appScript);
      };

      const qrcodeScript = document.createElement('script');
      qrcodeScript.src = '/settify/assets/js/qrcode.min.js';
      document.body.appendChild(qrcodeScript);

      document.body.appendChild(vendorScript);
    } else {
      startEmberApp();
    }

    return () => {
      // Reset the Ember app
      if ((window as any)._settifyApp) {
        // console.log('already exists, remove it');
        // (window as any)._settifyApp.reset();
      }
      document
        .querySelectorAll('meta')
        .forEach((meta) =>
          meta.name === 'settify-mvp/config/environment' ? meta.remove() : null
        );
      // NOTE: We do not remove the scripts as that causes issues re-mounting the ember application
      // document
      //   .querySelectorAll('script[src*="/settify/assets"]')
      //   .forEach((script) => script.remove());
      document
        .querySelectorAll(
          // eslint-disable-next-line @stylistic/js/max-len
          'link[href*="/settify/assets/vendor.css"], link[href*="/settify/assets/settify-mvp.css"]'
        )
        .forEach((link) => link.remove());

      // Remove google font
      document
        .querySelectorAll(
          'link[href*="https://fonts.googleapis.com/css?family=Lato:300,400,900"]'
        )
        .forEach((link) => link.remove());

      // Remove font awesome
      document
        .querySelectorAll(
          'script[src*="https://kit.fontawesome.com/ba16d22322.js"]'
        )
        .forEach((script) => script.remove());

      // Remove all <style id="fa-*"> elements
      document
        .querySelectorAll('style[id^="fa-"]')
        .forEach((style) => style.remove());

      // Clear all local storage for settify*
      Object.keys(window.localStorage).forEach((key) => {
        if (key.startsWith('settify') || key.startsWith('client:')) {
          window.localStorage.removeItem(key);
        }
      });

      // Remove all cookies for settify
      const cookies = document.cookie.split('; ');
      for (const cookie of cookies) {
        if (
          cookie.startsWith('fingerprint-') ||
          cookie.startsWith('lastModified-') ||
          cookie.startsWith('clientID') ||
          cookie.startsWith('deviceFingerprint')
        ) {
          const cookieName = cookie.split('=')[0];
          // eslint-disable-next-line @stylistic/js/max-len
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [continuous, rootUrl]);

  return (
    <div ref={settifyAppRef} id='settify-app' style={style}>
      <Helmet><title>AskMaxwell</title></Helmet>
      {isLoading ? (
        <div className={styles.spinner}>
          <svg viewBox='0 0 50 50' fill='none'>
            <circle cx='25' cy='25' r='20' strokeWidth='5'></circle>
          </svg>
        </div>
      ) : null}
    </div>
  );
});
