import React, { useEffect } from 'react';
import { Header } from '../../../components/header';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import selectors from '../../../redux/selectors';
import { PrematterHeader } from '../../../components/prematter-header';
import { useTranslation } from 'react-i18next';
import { NoPaddingContentTitle } from '../../../components/content-title';
import { ChatHistory } from '../../../components/chat-history';
import style from './style.module.less';
import { Footer } from '../../../components/footer';
import { ContentWrapper } from '../../../components/content-wrapper';
import { VerificationBanner } from '../verification-banner';
import { ChatElement } from '../../maxwell/chat-element';
import { MessageType, Question } from '@law-connect/types';
import { actions } from '../../../redux/slices';

interface Props {}
export const PrematterHistory: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const prematter = useAppSelector(selectors.prematter.getById(id));
  const files = useAppSelector(selectors.file.getPrematterFiles(id));

  useEffect(() => {
    // fetch files
    dispatch(actions.file.fetchByPrematterId({ prematterId: id }));
  }, [dispatch, id]);

  useEffect(() => {
    // scroll to top on load
    window.scrollTo(0, 0);
  }, []);

  const onComplete = () => {
    navigate(`/case/${id}`);
  };

  if (!prematter) {
    return null;
  }

  return (
    <>
      <Header />
      <div className={style.wrapper}>
        <PrematterHeader prematter={prematter} />
        <ContentWrapper className={style.contentBackground}>
          <VerificationBanner
            className={style.verificationBanner}
            prematter={prematter}
          />
          <NoPaddingContentTitle
            title={t('prematter.case-history')}
            className={style.title}
          />

          <ChatElement
            prematter={prematter}
            messages={prematter.messages}
            disabled={true}
            readOnly={true}
            onSendMessage={() => {}}
            isLoading={false}
            isFileUploading={false}
            addQuestion={() => {}}
            addQuestionPending={false}
            onFormFileUpload={() => {}}
            onFormChange={() => {}}
            onFormCompletion={() => {}}
            onComplete={onComplete}
            files={files}
          />

          {/* <ChatHistory prematter={prematter} /> */}
        </ContentWrapper>
      </div>
      <Footer />
    </>
  );
};
