import React, { useEffect, useState } from 'react';
import style from './style.module.less';
import { LegalCaseQuestion, LegalCaseState } from '@law-connect/types';

import { usePrevious } from '../../hooks/use-previous';
import { QuestionCard } from './question-card';

export const QuestionCards: React.FC<{
  questions: LegalCaseQuestion[];
  prematterState: LegalCaseState;
}> = (props) => {
  const { questions, prematterState } = props;

  const prevQuestions = usePrevious(questions);

  const [newQuestionIds, setNewQuestionId] = useState<string[] | null>([]);

  useEffect(() => {
    if (questions?.length > prevQuestions?.length) {
      setNewQuestionId((q) => [...q, questions[questions.length - 1].id]);
    }
  }, [questions, prevQuestions]);

  return (
    <div className={style.questionCards}>
      {questions.map((question, index) => (
        <QuestionCard
          key={index}
          question={question}
          show={index === 0 || newQuestionIds.includes(question.id)}
          prematterState={prematterState}
        />
      ))}
    </div>
  );
};
