import React, { FC, useCallback, useState } from 'react';
import style from './style.module.less';
import WarningIcon from '../../assets/icons/warning-circle.svg?react';
import { useTranslation } from 'react-i18next';
import { CantVerifyModalComponent } from '../cant-verify-modal';

export const NoVerifyCard: FC = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => setShowModal(true), []);
  const closeModal = useCallback(() => setShowModal(false), []);

  return (
    <>
      <div className={style.noAnswer}>
        <WarningIcon className={style.warningIcon} />
        <div className={style.noAnswerTextContainer}>
          <div className={style.noAnswerText}>
            {t('verify.could-not-verify')}&nbsp;
          </div>
          <div className={style.noAnswerLink} onClick={openModal}>
            {t('verify.more-info')}
          </div>
        </div>
      </div>
      <CantVerifyModalComponent
        isOpen={showModal}
        close={closeModal}
      />
    </>
  );
};
