import React from 'react';
import { Wrapper } from '../../components/wrapper';
import { Header } from '../../components/header';

interface Props {}
export const HowItWorks: React.FC<Props> = () => {
  return (
    <>
      <Header />
      <Wrapper>
        Welcome to the HowItWorks page
      </Wrapper>
    </>
  );
};
