import React, { FC, useEffect } from 'react';
import DownloadIcon from '../../../../assets/icons/download.svg?react';
import style from './style.module.less';
import { security } from '../../../../redux/api/security';

interface Props {
  prematterId: string
}

export const PrintReportIcon: FC<Props> = (props) => {

  const [token, setToken] = React.useState(null);

  useEffect(() => {
    async function getJWT() {
      const jwt = await security.getAccessToken();
      setToken(jwt);
    }
    getJWT();
  }, []);
  
  return (
    <div className={style.printWrapper}>
      <a
        // eslint-disable-next-line @stylistic/js/max-len
        href={`/api/prematter/${props.prematterId}/report/download?jwt=${token}`}
        target='_blank'
        rel='noreferrer'
      >
        <div className={style.printButton}>
          <DownloadIcon className={style.printIcon} />
        </div>
      </a>
    </div>
  );
};
