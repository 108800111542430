/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import bubbleImage from '../images/real-estate-bubble.png';
import realEstateImage from '../images/real-estate.jpg';
import realEstateImageFull from '../images/real-estate-full.jpg';
import Icon from '../images/real-estate.svg?react';

const TITLE = 'Real estate and property law';

const realEstate: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Real estate law in Australia',
  image: realEstateImage,
  previewImage: realEstateImageFull,
  shortDescription:
    'Proactive measures to prevent disputes or prepare for action.',
  description:
    'Real estate law governs the rights and obligations of property owners, buyers, and tenants, ensuring fair dealings and protecting interests in real property. It encompasses a wide range of legal issues, from property transfers and leasing agreements to land use regulations and zoning laws.',
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Property law act',
      body: 'This legislation governs property ownership, transfer of property rights, and the legal processes involved in buying, selling, or leasing real estate.'
    },
    {
      header: 'Land titles and ownership',
      body: 'Real estate law establishes the framework for land titles, defining property ownership rights, including freehold, leasehold, and joint ownership structures.'
    },
    {
      header: 'Contracts for sale and purchase',
      body: 'The law mandates formal agreements for real estate transactions, detailing obligations, disclosures, and rights of both buyers and sellers to ensure fair dealing.'
    },
    {
      header: 'Leasing and tenancy laws',
      body: 'These laws set out the rights and responsibilities of landlords and tenants, including rules around rent, maintenance, and termination of leases.'
    },
    {
      header: 'Zoning and land use regulations',
      body: 'Local zoning laws and land use regulations control property development, specifying permitted uses for different areas, building standards, and environmental protections.'
    }
  ],
  bubbleColour1: '#F3E5D7',
  bubbleColour2: '#CDD5CD',
};

export default realEstate;
