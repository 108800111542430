import React, {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import style from './style.module.less';

interface Props {
  cardImage: string;
  RightCard?: React.ReactNode;
  classNames?: {
    textContainer?: string;
  };
  animationOnScroll?: boolean;
}

const ANIMATION_DELAY = 400;

export const StackedCardComponent: FC<Props> = (props) => {
  const { animationOnScroll, cardImage, RightCard, classNames } = props;

  const textRef = useRef<HTMLDivElement>(null);
  // used for scroll animation
  const textContainerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [textHeight, setTextHeight] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [hasPassed, setHasPassed] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);
  const startAnimationTimer = useRef<NodeJS.Timeout | null>(null);

  const onResize = useCallback(() => {
    if (textRef.current) {
      setTextHeight(textRef.current.clientHeight);
    }
    if (imageRef.current) {
      setImageHeight(imageRef.current.clientHeight);
    }
  }, []);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  // on scroll we want to transform the image and text by up to 80px
  // on scroll up we want to add transform Y positive to left
  useEffect(() => {
    const onScroll = () => {
      const bounding = imageRef.current?.getBoundingClientRect();
      const topOfImage = bounding?.top;

      if (window.innerHeight * 0.8 > topOfImage) {
        setHasPassed(true);
      } else {
        setHasPassed(false);
      }
    };
    onScroll();
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (hasPassed) {
      clearTimeout(startAnimationTimer.current!);
      startAnimationTimer.current = setTimeout(
        () => setStartAnimation(true),
        ANIMATION_DELAY
      );
    } 
    return () => clearTimeout(startAnimationTimer.current!);
  }, [hasPassed]);

  const imageShowAnimation = useMemo(() => {
    if (!animationOnScroll) return '';
    return `${style.useAnimation} ${
      startAnimation ? style.startAnimation : ''
    }`;
  }, [animationOnScroll, startAnimation]);

  return (
    <div
      className={style.container}
      style={
        {
          '--text-height': `${textHeight}px`,
          '--card-image-height': `${imageHeight}px`,
        } as CSSProperties
      }
    >
      <img
        ref={imageRef}
        src={cardImage}
        alt='Lawyer'
        className={`${style.lawyerImage} ${imageShowAnimation}`}
        onLoad={onResize}
      />
      <div
        className={`${style.textPostiion} ${imageShowAnimation}`}
        ref={textContainerRef}
      >
        <div
          className={`${style.textContainer} ${
            classNames?.textContainer ?? ''
          }`}
          ref={textRef}
        >
          {RightCard}
        </div>
      </div>
    </div>
  );
};
