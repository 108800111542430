import React, {
  CSSProperties,
  FC,
  LegacyRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import style from './style.module.less';

import ArrowRightIcon from '../../../assets/icons/arrow-right.svg?react';
import ArrowUpIcon from '../../../assets/icons/arrow-up.svg?react';
import ArrowDownIcon from '../../../assets/icons/arrow-down.svg?react';

import criminalLawData from '../../library/assets/categories/criminal_law';
import employmentLawData from '../../library/assets/categories/employment_law';
import estatesDate from '../../library/assets/categories/estates_and_probates';
import familyLawData from '../../library/assets/categories/family_law';
import realEstateData from '../../library/assets/categories/real_estate';
import { Link } from 'react-router-dom';
import { convertToKebabCase } from '../../../utils/case-conversion';
import { useTranslation } from 'react-i18next';
import { LawyerImage } from '../lawyer-images';
import { useScreenSize, WidthType } from '../../../hooks/use-is-mobile';
import {
  getLibraryPathWithCountry,
} from '../../../utils/get-library-path-with-country';

// time to take for the progress to reach 100%
const PROGRESS_TIME = 5000;

const CONTENT = [
  criminalLawData,
  employmentLawData,
  estatesDate,
  familyLawData,
  realEstateData,
];

const ANIMATION_DELAY = 500;

interface Props {
  lawyerImage: LawyerImage;
}

export const HomeCaseStudies: FC<Props> = (props) => {
  const { lawyerImage } = props;

  const screenType = useScreenSize();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [nextIndex, setNextIndex] = useState<number>(1);
  const [progressRate, setProgressRate] = useState<number>(0); // the percentage of the progress bar
  const [fadeOut, setFadeOut] = useState(false);
  const imageContainerRef = useRef<HTMLDivElement | null>(null);
  const mobileRef = useRef<HTMLDivElement | null>(null);
  const [descriptionHeight, setDescriptionHeight] = useState<number>(0);
  const { i18n } = useTranslation();
  const language = i18n.language;
  // is an interval that we want to run every
  const timer = useRef<number | null>(null);

  const activeCategory = useMemo(() => CONTENT[activeIndex], [activeIndex]);
  const nextCategory = useMemo(() => CONTENT[nextIndex], [nextIndex]);

  const clearTimer = useCallback(() => {
    if (timer?.current) clearInterval(timer.current);
  }, []);
  const setUpTimer = useCallback(() => {
    clearTimer();
    timer.current = window.setInterval(() => {
      setProgressRate((p) => p + 1);
    }, PROGRESS_TIME / 100);
  }, [clearTimer]);

  const onImageResize = useCallback(() => {
    if (imageContainerRef.current?.clientHeight) {
      setDescriptionHeight(imageContainerRef.current.clientHeight);
    } else if (mobileRef.current?.clientHeight) {
      setDescriptionHeight(mobileRef.current.clientHeight);
    }
  }, []);

  useEffect(() => {
    onImageResize();
    window.addEventListener('resize', onImageResize);
    return () => window.removeEventListener('resize', onImageResize);
  }, [onImageResize]);

  useEffect(() => {
    // when the index changes, we want to set up the timer and progress bar
    setUpTimer();
    return () => clearInterval(timer.current!);
  }, [activeIndex, setUpTimer]);

  const changeIndex = useCallback(
    (newIndex: number) => {
      setProgressRate(0);
      if (newIndex === activeIndex) return;
      if (screenType === WidthType.Mobile) {
        setActiveIndex(newIndex);
      } else {
        clearTimer();
        setFadeOut(true); // we want to start fading out here before the image changes
        setNextIndex(newIndex);
        setTimeout(() => {
          setActiveIndex(newIndex);
          setFadeOut(false); // Start fade-in
        }, ANIMATION_DELAY);
      }
    },
    [activeIndex, clearTimer, screenType]
  );

  useEffect(() => {
    if (progressRate === 100) {
      clearTimer();
      changeIndex((activeIndex + 1) % CONTENT.length);
    }
  }, [changeIndex, clearTimer, activeIndex, progressRate]);

  const getImageBody = useCallback(
    (imgRef: LegacyRef<HTMLDivElement>) => (
      <>
        <img
          src={activeCategory?.previewImage}
          alt='case study'
          className={style.image}
          style={{
            transitionDuration: `${ANIMATION_DELAY}ms`,
          }}
        />
        <img
          src={nextCategory?.previewImage}
          alt='case study'
          className={`${style.nextImage} ${fadeOut ? style.show : ''}`}
        />
        <div className={style.imageDescription} ref={imgRef}>
          <span className={style.imageDescriptionText}>
            {activeCategory.shortDescription}
          </span>
          <Link
            className={style.imageDescriptionLink}
            to={getLibraryPathWithCountry(
              `/${convertToKebabCase(activeCategory.name)}`
            )}
          >
            {activeCategory.name}
            <div className={style.linkArrowContainer}>
              <ArrowRightIcon className={style.linkArrow} />
            </div>
          </Link>
        </div>
      </>
    ),
    [activeCategory, fadeOut, nextCategory]
  );

  {
    /* for now we only support english since we dont have translation for other languages */
  }
  if (!language.includes('en')) return null;

  return (
    <div className={style.caseStudiesContainer}>
      <div className={style.backgroundWrapper}>
        <div
          className={style.backgroundContainer}
          style={{ background: lawyerImage.homeColours.backgroundColor }}
        />
        <div className={style.lawyerIconContainer}>
          <img
            src={lawyerImage.homeColours.icon}
            alt='lawyer'
            className={style.lawyerIcon}
          />
        </div>
      </div>

      <div className={style.title}>Explore our legal knowledge base</div>
      <div className={style.contentContainer}>
        <div className={style.sideMenuContainer}>
          <div className={style.sideMenuWrapper}>
            {CONTENT.map((item, index) => {
              const isActive = activeIndex === index;
              const ifPrevIndexActive = index - 1 === activeIndex;
              return (
                <div key={index} className={style.sideMenuItemContainer}>
                  <div
                    className={`${style.sideMenuItem} ${
                      isActive ? style.activeItem : ''
                    } ${ifPrevIndexActive ? style.prevActiveItem : ''}`}
                    onClick={() => changeIndex(index)}
                  >
                    <item.Icon className={style.sideMenuItemIcon} />
                    <div className={style.sideMenuItemTitle}>{item.name}</div>
                    <div className={style.sideMenuArrowContainer}>
                      <ArrowRightIcon className={style.sideMenuArrow} />
                      {/* mobile uses up down on active */}
                      {isActive ? (
                        <ArrowUpIcon
                          className={`${style.sideMenuArrow} ${
                            style.forMobile
                          }`}
                        />
                      ) : (
                        <ArrowDownIcon
                          className={`${style.sideMenuArrow} ${
                            style.forMobile
                          }`}
                        />
                      )}
                    </div>
                    <div
                      className={`${style.progressBarContainer}  ${
                        isActive ? style.active : ''
                      }`}
                    >
                      <div
                        className={style.progressBar}
                        style={
                          {
                            backgroundColor:
                              lawyerImage.homeColours.primaryColor,
                            '--progress-bar-width': `${progressRate}%`,
                          } as CSSProperties
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={`${style.imageContainerMobile} ${
                      isActive ? style.activeImage : ''
                    }`}
                    style={
                      {
                        '--image-height': `${descriptionHeight}px`,
                      } as CSSProperties
                    }
                  >
                    {getImageBody(isActive ? mobileRef : null)}
                  </div>
                </div>
              );
            })}
          </div>
          <Link
            className={`${style.browseContainer} ${style.desktop}`}
            to={getLibraryPathWithCountry()}
          >
            <div className={style.browseTitle}>Browse the library</div>
            <div className={style.arrowContainer}>
              <ArrowRightIcon className={style.arrowIcon} />
            </div>
          </Link>
        </div>
        <div
          className={style.imageContainer}
          style={
            {
              '--image-height': `${descriptionHeight}px`,
            } as CSSProperties
          }
        >
          {getImageBody(imageContainerRef)}
        </div>
        <Link
          className={`${style.browseContainer} ${style.other}`}
          to={getLibraryPathWithCountry()}
        >
          <div className={style.browseTitle}>Browse the library</div>
          <div className={style.arrowContainer}>
            <ArrowRightIcon className={style.arrowIcon} />
          </div>
        </Link>
      </div>
    </div>
  );
};
