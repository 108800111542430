import React, { FC } from 'react';
import style from './style.module.less';
import { Steps } from './use-carousel-steps';

interface Props {
  carouselSteps: Steps[];
  activeIndex: number;
  changeIndex: (index: number) => void;
}

// this is used by tablet and small desktop view only
export const CarouselHeader: FC<Props> = (props) => {
  const { carouselSteps, activeIndex, changeIndex } = props;

  return (
    <div className={style.headerContainer}>
      {carouselSteps.map((step, index) => {
        return (
          <div
            className={`${style.headerStep} ${
              activeIndex === index ? style.active : ''
            }`}
            key={index}
            onClick={() => changeIndex(index)}
          >
            <step.Icon className={style.headerStepIcon} />
            <div className={style.headerStepText}>
              {step.name}
            </div>
          </div>
        );
      })}
    </div>
  );
};
