import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Header } from '../../../components/header';
import { Footer } from '../../../components/footer';
import { LibraryHero } from '../hero';
import { libraryAssets } from '../assets';
import style from './style.module.less';
import { CategoryCard, CategoryCards } from '../category-card';
import { NeedHelpCard } from '../../../components/need-help';

export const LibraryHome = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('pages.library')}</title>
      </Helmet>
      <Header />
      <div className={style.menuDivider} />
      <LibraryHero
        breadcrumbs={[
          {
            name: 'Library',
          },
        ]}
        description={'Explore our legal knowledge base'}
      />
      <div className={style.container}>
        <div className={style.descriptionContainer}>
          {t('library.home.description')}
        </div>
        <CategoryCards
          categories={libraryAssets}
          classNames={{ containerClassName: style.categoriesContainer }}
        />
        <NeedHelpCard />
      </div>
      <Footer className={style.noFooterMargin} />
    </>
  );
};
